<template>
    <div>
        <Panel :title="titleView"  style="margin:0px 1px; ">
            <div class="datagrid-toolbar">
                <LinkButton :plain="true" :iconCls="iconUtil.iconRefresh" @click="onRefresh()">{{ btnText.refresh }}</LinkButton>
                <LinkButton v-if="action.add" :plain="true" :iconCls="iconUtil.iconAdd" @click="onAdd()">{{ btnText.add }}</LinkButton>
                <LinkButton v-if="action.edit" :plain="true" :iconCls="iconUtil.iconEdit" @click="onEdit()">{{ btnText.edit }}</LinkButton>
                <LinkButton v-if="action.delete" :plain="true" :iconCls="iconUtil.iconDelete" @click="onDelete()">{{ btnText.delete }}</LinkButton>
                <LinkButton v-if="action.add" :plain="true" :iconCls="iconUtil.iconUpload" @click="onImport()">{{ btnText.import }}</LinkButton>
                <span class="button-sep"></span> {{ search.text }} : 
                <SearchBox style="width:300px"
                    :placeholder="search.placeholder" 
                    v-model="dataGrid.search"
                    @search="onSearch()">
                    <Addon>
                        <span v-if="dataGrid.search" class="textbox-icon icon-clear" title="Clear value" @click="dataGrid.search=null;onSearch();"></span>
                    </Addon>
                </SearchBox>
            </div>
            <DataGrid
                    ref="dataGrid"
                    :columnResizing="true"
                    :border="false"
                    :lazy="true"
                    :loading="dataGrid.loading"                                    
                    :data="dataGrid.rows"
                    :pagination="true"
                    :selectionMode="dataGrid.selectionMode"
                    :total="dataGrid.total"
                    :pageSize="dataGrid.pageSize"
                    :pageNumber="dataGrid.pageNumber"
                    :pageLayout="dataGrid.pageLayout" 
                    @pageChange="onPageChange($event)"
                    @sortChange="onSortChange($event)"
                    @rowDblClick="onRowDblClick()"
                    >
                
                <GridColumn align="center" cellCss="datagrid-td-rownumber" width="30">
                    <template slot="body" slot-scope="scope">
                        {{scope.rowIndex + 1}}
                    </template>
                </GridColumn>
                <GridColumn field="customer_id" title="Customer ID" :sortable="true"></GridColumn>
                <GridColumn field="name" title="Name" :sortable="true"></GridColumn>
                <GridColumn field="address1" title="Address 1" :sortable="true"></GridColumn>
                <GridColumn field="address2" title="Address 2" :sortable="true"></GridColumn>
                <GridColumn field="type_desc" title="Type" :sortable="true"></GridColumn>
                <GridColumn field="city" title="City" :sortable="true"></GridColumn>
                <GridColumn field="position" title="Position" :sortable="true"></GridColumn>
                <GridColumn field="area_desc" title="Area" :sortable="true"></GridColumn>
                <GridColumn field="postal_code" title="Postal Code" :sortable="true"></GridColumn>
                <GridColumn field="phone" title="Phone" :sortable="true"></GridColumn>
                <GridColumn field="pic" title="PIC" :sortable="true"></GridColumn>
                <GridColumn field="email" title="Email" :sortable="true"></GridColumn>
                <GridColumn field="additional_information" title="Additional Information" :sortable="true"></GridColumn>
            </DataGrid>
        </Panel>

        <!-- Form -->
        <Dialog ref="dlg" :iconCls="icon" bodyCls="f-column" :title="title" :draggable="true" :modal="true" closed :dialogStyle="{width:'786px', height:'515px'}">
            <div class="f-full" style="overflow:auto">
                <Form ref="form" :model="model" :rules="rules" @validate="errors=$event">
                    <Tabs>
                        <TabPanel :title="'Customer Details'">
                            <table width="100%" cellspacing="1" cellpadding="5" border="0">
                                <tbody>
                                    <tr>
                                        <td class="bg_subtitle">
                                            Customer ID <font color="red">*</font>
                                        </td>
                                        <td class="bg_grid2"> 
                                            <TextBox inputId="customer_id" name="customer_id" class="f-field-text" v-model="model.customer_id" :disabled="disabled"></TextBox>
                                            <div class="error">{{textError.customer_id}}</div>
                                        </td>
                                        <td class="bg_subtitle">Phone</td>
                                        <td class="bg_grid2">
                                            <TextBox inputId="phone" name="phone" class="f-field-text" v-model="model.phone"></TextBox>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="bg_subtitle">Name <font color="red">*</font></td>
                                        <td class="bg_grid2">
                                            <TextBox inputId="name" name="name" class="f-field-text" v-model="model.name"></TextBox>
                                            <div class="error">{{textError.name}}</div>
                                        </td>
                                        <td class="bg_subtitle">Email</td>
                                        <td class="bg_grid2">
                                            <TextBox inputId="email" name="email" class="f-field-text" v-model="model.email"></TextBox>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="bg_subtitle">Address 1 <font color="red">*</font></td>
                                        <td class="bg_grid2">
                                            <TextBox inputId="address1" name="address1" :multiline="true" class="f-field-text f-field-text-area" v-model="model.address1"></TextBox>
                                            <div class="error">{{textError.address1}}</div>
                                        </td>
                                        <td class="bg_subtitle">Address 2</td>
                                        <td class="bg_grid2">
                                            <TextBox inputId="address2" name="address2" :multiline="true" class="f-field-text f-field-text-area" v-model="model.address2"></TextBox>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="bg_subtitle">PIC</td>
                                        <td class="bg_grid2">
                                            <TextBox inputId="pic" name="pic" class="f-field-text" v-model="model.pic"></TextBox>
                                        </td>
                                        <td class="bg_subtitle">Type <font color="red">*</font></td>
                                        <td class="bg_grid2">
                                            <ComboBox  :data="customerTypes" inputId="type" name="type" class="f-field-text" v-model="model.type"></ComboBox>
                                            <div class="error">{{textError.type}}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="bg_subtitle">Additional Information</td>
                                        <td class="bg_grid2">
                                            <TextBox inputId="additional_information" name="additional_information" class="f-field-text" v-model="model.additional_information"></TextBox>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table width="100%" cellspacing="1" cellpadding="5" border="0">
                                <tbody>
                                    <tr>
                                        <td>Inputkan nama Kelurahan / Kecamatan / Kota / Porvinsi <font color="red">*</font></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <ComboBox :data="postalCodes" :lazy="true" @filterChange="optionPostalCode($event)" @selectionChange="onSelectionPostalCode($event)" inputId="postal_code_id" name="postal_code_id" class="f-field-text" v-model="model.postal_code_id"></ComboBox>
                                            <div class="error">{{textError.postal_code_id}}</div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table width="100%" cellspacing="1" cellpadding="5" border="0">
                                <tbody>
                                    <tr>
                                        <td class="bg_subtitle">Urban Village</td>
                                        <td class="bg_grid2">
                                            <TextBox ref="urban_village" inputId="urban_village" name="urban_village" v-model="model.urban_village" class="f-field-text" :disabled="true"></TextBox>
                                        </td>
                                        <td class="bg_subtitle">Sub District</td>
                                        <td class="bg_grid2">
                                            <TextBox ref="sub_district" inputId="sub_district" name="sub_district" v-model="model.sub_district" class="f-field-text" :disabled="true"></TextBox>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="bg_subtitle">City</td>
                                        <td class="bg_grid2">
                                            <TextBox inputId="city" name="city" class="f-field-text" v-model="model.city" :disabled="true"></TextBox>
                                        </td>
                                        <td class="bg_subtitle">Province</td>
                                        <td class="bg_grid2">
                                            <TextBox ref="province" inputId="province" name="province" v-model="model.province" class="f-field-text" :disabled="true"></TextBox>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="bg_subtitle">Area <font color="red">*</font></td>
                                        <td class="bg_grid2">
                                            <ComboBox :data="areas" :lazy="true" @filterChange="optionArea($event)" inputId="area_id" name="area_id" class="f-field-text" v-model="model.area_id"></ComboBox>
                                            <div class="error">{{textError.area_id}}</div>
                                        </td>
                                        <td class="bg_subtitle">Postal Code</td>
                                        <td class="bg_grid2">
                                            <TextBox inputId="postal_code" name="postal_code" class="f-field-text" v-model="model.postal_code" :disabled="true"></TextBox>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </TabPanel>
                        <TabPanel :title="'Geofence'">
                            <table width="100%" cellspacing="1" cellpadding="5" border="0">
                                <tbody>
                                    <tr>
                                        <td class="bg_subtitle">Latitude</td>
                                        <td class="bg_grid2">
                                            <TextBox inputId="latitude" name="latitude" class="f-field-text" v-model="model.latitude"></TextBox>
                                        </td>
                                        <td class="bg_subtitle">Longitude</td>
                                        <td class="bg_grid2">
                                            <TextBox inputId="longitude" name="longitude" class="f-field-text" v-model="model.longitude"></TextBox>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table width="100%" cellspacing="1" cellpadding="5" border="0">
                                <tbody>
                                    <tr>
                                        <td style="width: 11%;" class="bg_subtitle">Radius</td>
                                        <td style="width: 38%;" class="bg_grid2">
                                            <TextBox inputId="radius" name="radius" class="f-field-text" v-model="model.radius"></TextBox>
                                        </td>
                                        <td>&nbsp;</td>
                                        <td>
                                            &nbsp;
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table width="100%" cellspacing="1" cellpadding="5" border="0">
                                <tbody>
                                    <tr>
                                        <td class="bg_grid2">
                                            <GoogleMapsComponent ref="googleMapsComponent"
                                                :centerMaps="{lat: parseFloat(model.latitude), lng: parseFloat(model.longitude)}"
                                                :zoomMaps="parseInt(model.radius)"
                                                :geofenceData="model.geofence_area"     
                                                @onChangeLocation="onChangeLocation" 
                                                @onDragMarker="onDragMarker" 
                                                @setRadius="onSetRadius"
                                                @setEncode="onSetGeofence"/>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </TabPanel>
                    </Tabs>                    
                </Form>
            </div>
            <div class="dialog-button">
                <LinkButton :iconCls="iconUtil.iconSave" v-if="action.add || action.edit" @click="onSave()" :disabled="disableBtnSave" style="width:89px">{{ btnText.save }}</LinkButton>
                <LinkButton :iconCls="iconUtil.iconCancel" @click="$refs.dlg.close()" style="width:89px">{{ btnText.cancel }}</LinkButton>
            </div>
        </Dialog>

        <Dialog ref="dlgImport" :iconCls="iconUtil.iconUpload" bodyCls="f-column" :title="informationUtil.importText" :draggable="true" :modal="true" closed :dialogStyle="{width:'480px', height:'auto'}">
            <div class="f-full" style="overflow:auto">
                <Form ref="formFile">
                    <table width="100%" cellspacing="1" cellpadding="5" border="0">
                        <tbody>
                            <tr>
                                <td> 
                                    <FileButton style="width:100px" @select="onSelectedFile($event)">Choose File</FileButton> &nbsp;&nbsp;{{fileName}}
                                </td>
                                <td> 
                                    <LinkButton :plain="true" :iconCls="iconUtil.iconDownload" @click="onDownloadFile()">{{ btnText.downloadTemplate }}</LinkButton>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <b>(Total Max Upload file size 5MB, allowed file type .xls)</b>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Form>
            </div>
            <div class="dialog-button">
                <LinkButton :iconCls="iconUtil.iconSave" @click="onSaveImport()" style="width:89px">{{ informationUtil.saveText }}</LinkButton>
                <LinkButton :iconCls="iconUtil.iconCancel" @click="onCancelImport()" style="width:89px">{{ informationUtil.cancelText }}</LinkButton>
            </div>
        </Dialog>

        <NotificationComponent ref="notification"></NotificationComponent>
    </div>
    
</template>

<script>
import NotificationComponent from '@/components/NotificationComponent.vue';
import Customer from '@/models/Customer';
import MasterService from '@/services/MasterService';
import ModelUtil from '@/utils/ModelUtil';
import InformationUtil from '@/utils/InformationUtil';
import OptionService from '@/services/OptionService';
import AccessUtil from '@/utils/AccessUtil';
import IconUtil from '@/utils/IconUtil';
import GlobalUtil from '@/utils/GlobalUtil';

import GoogleMapsComponent from '@/components/GoogleMapsComponent.vue';

export default {
    name: 'CustomerView',
    components : {
        NotificationComponent,
        GoogleMapsComponent
    },
    data() {
        return {
            titleView : InformationUtil.customer + "s",
            titleMessage : InformationUtil.customer,
            iconUtil : IconUtil,
            informationUtil : InformationUtil,

            titleForm :{
                add : InformationUtil.addText,
                edit : InformationUtil.editText
            },

            iconForm :{
                add : IconUtil.iconAdd,
                edit : IconUtil.iconEdit
            },

            action : {
                view : false,
                add : false,
                edit : false,
                delete : false
            },

            btnText :{
                refresh : InformationUtil.refreshText,
                add : InformationUtil.addText,
                edit : InformationUtil.editText,
                delete : InformationUtil.removeText,
                save : InformationUtil.saveText,
                cancel : InformationUtil.cancelText,
                search : InformationUtil.searchText,
                import : InformationUtil.importText,
                upload : InformationUtil.uploadText,
                downloadTemplate : InformationUtil.downloadTemplateText
            },

            search : {
                text : InformationUtil.searching,
                placeholder : InformationUtil.searchHolder
            },

            dataGrid : {
                loading: false,
                rows : [],
                total: 0,
                start: 0,
                pageNumber: 1,
                pageSize: 10,
                selectionMode : 'single',
                pageLayout: ['list','sep','first','prev','next','last','sep','refresh','info'],

                // filter
                search : "",
                sortField : "",
                sortOrder : ""
            },

            dataSettingDefault : {
                lat : "",
                lng : "",
                radius : "",
            },

            // form
            title: '',
            icon: '',
            model: Customer,
            disabled : false,
            disableBtnSave : false,

            // validation
            rules: {
                customer_id: 'required',
                area_id: 'required',
                postal_code_id: 'required',
                name: 'required',
                type: 'required',
                address1: 'required',
            },
            errors: {},
            textError :{
                customer_id : "",
                area_id : "",
                postal_code_id : "",
                name : "",
                type : "",
                address1 : ""
            },

            // file
            file : '',
            fileName : '',

            // combo box
            customerTypes: [
                { value: 1, text: "MODERN" },
                { value: 2, text: "TRADITIONAL" }
            ],

            areas: [],
            postalCodes: [],
        }
    },
    methods:{ 
        getError(name) {
            return this.errors[name] && this.errors[name].length ? this.errors[name][0] : null;
        },
        onAdd(){
            ModelUtil.resetModel(this.textError);
            // method for add data
            this.disabled = false;
            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);
            this.model = ModelUtil.resetModel(this.model);
            
            // set default
            this.model.type = 1;
            this.optionArea();
            this.model.latitude = this.dataSettingDefault.lat;
            this.model.longitude = this.dataSettingDefault.lng;
            this.model.radius = this.dataSettingDefault.radius;

            this.title = this.titleForm.add;
            this.icon = this.iconForm.add;
            this.$refs.dlg.open();
        },
        onEdit(){
            ModelUtil.resetModel(this.textError);
            // method for get data to edit
            if(this.$refs.dataGrid.selectedRows.length > 0){
                this.disabled = true;
                this.title = this.titleForm.edit;
                this.icon = this.iconForm.edit;

                // additional for combo box
                this.areas = [{value: this.$refs.dataGrid.selectedRows[0].area_id, text: this.$refs.dataGrid.selectedRows[0].area_desc +" - "+ this.$refs.dataGrid.selectedRows[0].area_description}]
                this.postalCodes = [{
                                value: this.$refs.dataGrid.selectedRows[0].postal_code_id, 
                                text : this.$refs.dataGrid.selectedRows[0].province + ", " + this.$refs.dataGrid.selectedRows[0].district + ", " + this.$refs.dataGrid.selectedRows[0].sub_district + ", " + this.$refs.dataGrid.selectedRows[0].urban_village,
                                urban_village : this.$refs.dataGrid.selectedRows[0].urban_village,
                                sub_district : this.$refs.dataGrid.selectedRows[0].sub_district,
                                district : this.$refs.dataGrid.selectedRows[0].district,
                                province : this.$refs.dataGrid.selectedRows[0].province,
                                area_id : this.$refs.dataGrid.selectedRows[0].area_id,
                                area_id_desc : this.$refs.dataGrid.selectedRows[0].area_desc,
                                area_description : this.$refs.dataGrid.selectedRows[0].area_description,
                                postal_code : this.$refs.dataGrid.selectedRows[0].postal_code
                            }];

                this.model = ModelUtil.resetModel(this.model);
                this.model = ModelUtil.mapModel(this.model, this.$refs.dataGrid.selectedRows[0])

                if(this.model.latitude == null || this.model.latitude == ""){
                    this.model.latitude = this.dataSettingDefault.lat;
                }

                if(this.model.longitude == null || this.model.longitude == ""){
                    this.model.longitude = this.dataSettingDefault.lng;
                }

                if(this.model.radius == null || this.model.radius == ""){
                    this.model.radius = this.dataSettingDefault.radius;
                }

                this.$refs.dlg.open();
            }else{
                this.$refs.notification.centerTop(InformationUtil.selectRow);
            }
        },
        onDelete(){
            if(this.$refs.dataGrid.selectedRows.length > 0){

                this.$messager.confirm({
                    title: 'Confirm',
                    msg: InformationUtil.confirmDelete,
                    result: (r) => {
                        if (r){
                            this.dataGrid.loading = true;
                            MasterService.deleteData("/customer", this.$refs.dataGrid.selectedRows[0].id)
                                .then(() => {
                                    this.dataGrid.loading = false;
                                    this.$refs.notification.success(this.titleMessage + " " + InformationUtil.delete);
                                    this.loadData();
                                    this.$refs.dlg.close();
                                }).catch((err) => {
                                    this.$refs.notification.error(err.data.message);
                                });
                            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]) 
                        }
                    }
                })
                
            }else{
                this.$refs.notification.centerTop(InformationUtil.selectRow);
            }
            
        },
        onSave(){
            ModelUtil.resetModel(this.textError);
            this.$refs.form.validate((valid) => {
                if(!valid){
                    this.disableBtnSave = true; 
                    if(this.model.id == null){
                        // action add data
                        MasterService.addData("/customer", this.model)
                            .then((res) => {
                                this.disableBtnSave = false;
                                if(res.code == 200){
                                    this.$refs.notification.success(this.titleMessage + " " + InformationUtil.add);
                                    this.loadData();
                                    this.$refs.dlg.close();
                                } else {
                                    this.$messager.alert({
                                        title: "Error",
                                        icon: "error",
                                        msg: res.message
                                    });
                                }                               
                            }).catch((error) => {
                                this.disableBtnSave = false;
                                this.$refs.notification.error(error);
                            });

                    }else{
                        // action update data
                        MasterService.updateData("/customer", this.model.id, this.model)
                            .then((res) => {
                                this.disableBtnSave = false;
                                if(res.code == 200){
                                    this.$refs.notification.success(this.titleMessage + " " + InformationUtil.update);
                                    this.loadData();
                                    this.$refs.dlg.close();
                                } else {
                                    this.$messager.alert({
                                        title: "Error",
                                        icon: "error",
                                        msg: res.message
                                    });
                                }
                            }).catch((error) => {
                                this.disableBtnSave = false;
                                this.$refs.notification.error(error);
                            });
                    }
                    this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0])
                } else {
                    for(let key in valid){
                        this.textError[key] = this.getError(key);
                    }
                }
            });
            
        },

        // import
        onDownloadFile(){
            MasterService.downloadFile("/customer/download")
                .then((res) => {
                    if(res.type === 'application/vnd.ms-excel' && res != null){
                        let blob = new Blob([res], { type: "application/vnd.ms-excel" })
                        let objectUrl = window.URL.createObjectURL(blob)
                        const elem = window.document.createElement('a')
                        elem.href = objectUrl;
                        elem.download = "customer_template.xls"
                        document.body.appendChild(elem)
                        elem.click()
                        document.body.removeChild(elem)
                        window.URL.revokeObjectURL(blob);
                        window.URL.revokeObjectURL(objectUrl);
                    } else {
                        let message = new Blob([res], { type: 'application/json' })
                        message.text()
                            .then(value => {
                                let valMsg = JSON.parse(value);
                                this.$messager.alert({
                                    title: "Error",
                                    icon: "error",
                                    msg: valMsg.message
                                });
                            })
                            .catch(error => {
                                console.log("Something went wrong" + error);
                            });
                    }
                }).catch((error) => {
                    this.$refs.notification.error(error);
                });
        },

        onImport(){
            this.$refs.dlgImport.open();
            this.file = null;
            this.fileName = "";
        },
        onSelectedFile(event){
            this.file = event[0];
            this.fileName = this.file.name;
        },

        onSaveImport(){
            if(this.file != null && this.file != ''){
                let formData = new FormData();
                formData.append('file', this.file);
                MasterService.importFile("/customer/import", formData)
                    .then((res) => {
                        if(res != null){
                            let blob = new Blob([res], { type: "application/vnd.ms-excel" })
                            let objectUrl = window.URL.createObjectURL(blob)
                            const elem = window.document.createElement('a')
                            elem.href = objectUrl;
                            elem.download = "customer_validation.xls"
                            document.body.appendChild(elem)
                            elem.click()
                            document.body.removeChild(elem)
                            window.URL.revokeObjectURL(blob);
                            window.URL.revokeObjectURL(objectUrl);
                            
                            this.loadData();
                            this.$refs.notification.success(InformationUtil.refresh);

                            this.$refs.dlgImport.close();
                        } else {
                            this.$messager.alert({
                                        title: "Error",
                                        icon: "error",
                                        msg: res.message
                                    });
                        }
                    }).catch((error) => {
                        this.$refs.notification.error(error);
                    });
            } else {
                this.$messager.alert({
                    title: "Info",
                    icon: "info",
                    msg: InformationUtil.infoSelectFile
                });
            }
        },

        onCancelImport(){
            this.$refs.dlgImport.close();
        },
        
        // handle data grid
        onRowDblClick(){
            if(this.action.edit){
                this.onEdit();
            }
        },
        async onRefresh(){
            this.dataGrid.pageNumber = 1;
            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);
            await this.loadData();
            this.$refs.notification.success(InformationUtil.refresh);
        },
        onSearch(){
            this.loadData();
        },
        onSortChange(event){
            if(event.length > 0){
                this.dataGrid.pageNumber = 1;
                this.dataGrid.sortField = event[0].field;
                this.dataGrid.sortOrder = event[0].order;
                this.loadData();
            }
        },
        onPageChange(event) {
            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);
            this.dataGrid.pageNumber = event.pageNumber;
            this.dataGrid.pageSize = event.pageSize;
            if(event.refresh) this.dataGrid.pageNumber = 1;
            this.loadData();
            if(event.refresh){
                this.$refs.notification.success(InformationUtil.refresh);
            }
        },

        // handle maps
        onChangeLocation(event){
            this.model.latitude = event.lat().toString();
            this.model.longitude = event.lng().toString();
        },
        onDragMarker(event){
            this.model.latitude = event.lat.toString();
            this.model.longitude = event.lng.toString();
        },
        onSetRadius(event){
            this.model.radius = event.toString();
        },
        onSetGeofence(event){
            this.model.geofence_area = event;
        },

        onSelectionPostalCode(row){
            this.areas = [{value: row.value, text: row.area_id_desc +" - "+ row.area_description}];
            this.model.area_id = row.area_id;

            this.model.postal_code = row.postal_code;
            this.model.province = row.province;
            this.model.city = row.district;
            this.model.sub_district = row.sub_district;
            this.model.urban_village = row.urban_village;
        },

        async optionArea(event){
            let search = event == null ? "" : event.filterValue;
            return await OptionService.getOption("/area", search)
                .then((response) => {
                    this.areas = response.data;
                }).catch((error) => {
                    this.$refs.notification.error(error);
                });
        },

        async loadData(){
            this.dataGrid.loading = true;
            return MasterService.getData('/customer', this.dataGrid)
                .then((response) => {
                    if(response.data !== "" && response.data !== null){
                        let data = response.data; 
                        this.dataGrid.total = data.total;
                        this.dataGrid.rows = data.rows;
                    }
                    this.dataGrid.loading = false;
                });
        },

        async onGetDefaultLat(){
            let search = "default_lat";
            return await OptionService.getOption("/settingOption", search)
                .then((response) => {
                    this.dataSettingDefault.lat = response.data;
                }).catch((error) => {
                    this.$refs.notification.error(error);
                });
        },
        async onGetDefaultLng(){
            let search = "default_lng";
            return await OptionService.getOption("/settingOption", search)
                .then((response) => {
                    this.dataSettingDefault.lng = response.data;
                }).catch((error) => {
                    this.$refs.notification.error(error);
                });
        },
        async onGetDefaultZoom(){
            let search = "default_zoom";
            return await OptionService.getOption("/settingOption", search)
                .then((response) => {
                    this.dataSettingDefault.radius = response.data;
                }).catch((error) => {
                    this.$refs.notification.error(error);
                });
        },
        async optionPostalCode(event){
            let search = event == null ? "" : event.filterValue;
            return await OptionService.getOption("/postal-code", search)
                .then((response) => {
                    this.postalCodes = response.data;
                }).catch((error) => {
                    this.$refs.notification.error(error);
                });
        },
        onLoadSettingDefault(){
            this.onGetDefaultLat();
            this.onGetDefaultLng();
            this.onGetDefaultZoom();
            this.optionPostalCode();
        }

    },
    async mounted(){
        this.action = AccessUtil.getAccessAction('customer');
        await this.loadData();
        this.$refs.notification.success(InformationUtil.loadGrid + " " + this.dataGrid.total + " " + InformationUtil.successLoadGrid);
        this.onLoadSettingDefault();
    },
    created(){
        GlobalUtil.removeWindow();
    }
};
</script>