export default{
    // id : null,
    code : null,
    pending_code: "",
    pod_time : "",
    submit_time : "",
    doc_reference : "",
    receivetime : "",
    receiver : "",
    remark : ""
}