<template>
    <div>
        <Panel :title="titleView"  style="margin:0px 1px; ">
            <div class="datagrid-toolbar">
                <LinkButton :plain="true" :iconCls="iconUtil.iconRefresh" @click="onRefresh()">{{ btnText.refresh }}</LinkButton>
                <span class="button-sep"></span> Status : 
                <ComboBox  :data="statusData" inputId="searchStatusData" name="searchStatusData" class="f-field-text" style="width:75px; margin-left: 5px; margin-right: 5px;" v-model="searchStatusData" @selectionChange="onSelectionStatusData($event)"></ComboBox>
                <span class="button-sep"></span> {{ search.text }} : 
                <SearchBox style="width:300px"
                    :placeholder="search.placeholder" 
                    v-model="dataGrid.search"
                    @search="onSearch()">
                    <Addon>
                        <span v-if="dataGrid.search" class="textbox-icon icon-clear" title="Clear value" @click="dataGrid.search=null;onSearch();"></span>
                    </Addon>
                </SearchBox>
            </div>
            <DataGrid
                    ref="dataGrid"
                    :columnResizing="true"
                    :border="false"
                    :lazy="true"
                    :loading="dataGrid.loading"                                    
                    :data="dataGrid.rows"
                    :pagination="true"
                    :selectionMode="dataGrid.selectionMode"
                    :total="dataGrid.total"
                    :pageSize="dataGrid.pageSize"
                    :pageNumber="dataGrid.pageNumber"
                    :pageLayout="dataGrid.pageLayout" 
                    @pageChange="onPageChange($event)"
                    @rowDblClick="onRowDblClick()"
                    groupField="manifest_number"
                    :pageList="dataGrid.pageList" 
                    >
                    <GridColumn width="30" align="center">
                        <template slot="body" slot-scope="scope">
                            <div class="dot" :class="{ 'dot-start' : scope.row.tm_status == 0, 'dot-middle' : scope.row.tm_status == 1, 'dot-middle-2' : scope.row.tm_status == 2, 'dot-finish' : scope.row.tm_status == 3   }"></div>
                        </template>
                    </GridColumn>
                    <GridColumn field="customer_id" title="Point" width="200"></GridColumn>
                    <GridColumn field="customer_name" title="Name" width="300"></GridColumn>
                    <GridColumn field="customer_address" title="Address" width="500"></GridColumn>
                    <GridColumn field="tm_state" title="Tm State" width="100"></GridColumn>
                    <GridColumn field="ETA" title="ETA" width="100"></GridColumn>
                    <GridColumn field="ATA" title="ATA" width="100"></GridColumn>
                    <GridColumn field="notes" title="Last Check Point Notes" width="500"></GridColumn>
            </DataGrid>
        </Panel>

        <NotificationComponent ref="notification"></NotificationComponent>
        <FormManifestComponent ref="formManifest" actionData="traffic_monitoring" @onSaveData="onUpdateManifest()" @onSaveTimeWindow="onUpdateManifest()"></FormManifestComponent>
    </div>
    
</template>

<script>
import NotificationComponent from '@/components/NotificationComponent.vue';
import TransportService from '@/services/TransportService';
import InformationUtil from '@/utils/InformationUtil';
import AccessUtil from '@/utils/AccessUtil';
import IconUtil from '@/utils/IconUtil';
import GlobalUtil from '@/utils/GlobalUtil';

import FormManifestComponent from '@/components/forms/FormManifestComponent.vue';

export default {
    name: 'TrafficMonitoringView',
    components : {
        NotificationComponent,
        FormManifestComponent
    },
    data() {
        return {
            titleView : InformationUtil.trafficMonitoring + "s",
            iconUtil : IconUtil,         
            action : {
                view : false,
                add : false,
                edit : false,
                delete : false,
            },

            btnText :{
                refresh : InformationUtil.refreshText,
                add : InformationUtil.addText,
                edit : InformationUtil.editText,
                delete : InformationUtil.removeText,
                save : InformationUtil.saveText,
                cancel : InformationUtil.cancelText,
                search : InformationUtil.searchText
            },

            search : {
                text : InformationUtil.searching,
                placeholder : InformationUtil.searchHolder
            },
            searchStatusData : 0,

            dataGrid : {
                loading: false,
                rows : [],
                total: 0,
                start: 0,
                pageNumber: 1,
                pageSize: 50,
                selectionMode : 'single',
                pageLayout: ['list','sep','first','prev','next','last','sep','refresh','info'],
                pageList: [50,100,200,500,1000],

                // filter
                search : null,
                sortField : "",
                sortOrder : ""
            },

            filter : {
                viewStatusMessage : false,
            },


            // combobox
            statusData : [
                { value: 0 , text: 'Open' },
                { value: 3 , text: 'Closed' },
                { value: 'all' , text: 'All' },
            ],
        }
    },
    methods:{
        // handle data grid
        async onUpdateManifest(){
            this.dataGrid.pageNumber = 1;       
            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);
            await this.loadData();
            this.$refs.notification.success(InformationUtil.refresh);
        },
        async onSelectionStatusData(){
            this.dataGrid.pageNumber = 1;
            await this.loadData();
            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);
            if(this.filter.viewStatusMessage){
                this.$refs.notification.success(InformationUtil.refresh);
            }
            this.filter.viewStatusMessage = true;
        },
        onRowDblClick(){
            if(this.action.edit){
                if(this.$refs.dataGrid.selectedRows.length > 0){
                    let data = this.$refs.dataGrid.selectedRows[0];
                    this.$refs.formManifest.onPageShow(data.manifest_id)
                }else{
                    this.$refs.notification.centerTop(InformationUtil.selectRow);
                }
            }
        },
        async onRefresh(){
            this.dataGrid.pageNumber = 1;       
            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);
            await this.loadData();
            this.$refs.notification.success(InformationUtil.refresh);
        },
        onSearch(){
            this.loadData();
        },
        onPageChange(event) {
            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);
            this.dataGrid.pageNumber = event.pageNumber;
            this.dataGrid.pageSize = event.pageSize;
            if(event.refresh) this.dataGrid.pageNumber = 1;
            this.loadData();
            if(event.refresh){
                this.$refs.notification.success(InformationUtil.refresh);
            }
        },

        async loadData(){
            this.dataGrid.loading = true;
            let param = {
                statusData : this.searchStatusData,
            }
            return TransportService.getData('/trafficMonitoring', this.dataGrid, param)
                .then((response) => {
                    if(response.data !== "" && response.data !== null){
                        let data = response.data; 
                        this.dataGrid.total = data.total;
                        this.dataGrid.rows = data.rows;
                    }
                    
                    this.dataGrid.loading = false;
                });
        }
    },
    async mounted(){
        this.action = AccessUtil.getAccessAction('traffic_monitoring');
        await this.loadData();
        this.$refs.notification.success(InformationUtil.loadGrid + " " + this.dataGrid.total + " " + InformationUtil.successLoadGrid);
    },
    created(){
        GlobalUtil.removeWindow();
    }
};
</script>