export default{
    id : null,
    hash_id : null,
    reference : null,
    invoice_date : null,
    payment_date : null,
    type : 1,
    from_date : null,
    to_date : null,
    transporter_id : null,
    client_id : null,
    vehicle_id : null,
    area_type : null,
    vat : null,
    remark : null,
    payment_term : null
}