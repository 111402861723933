<template>
    <div>
        <Panel :title="titleView"  style="margin:0px 1px; ">
            <div class="datagrid-toolbar">
                <LinkButton :plain="true" :iconCls="iconUtil.iconRefresh" @click="onRefresh()">{{ btnText.refresh }}</LinkButton>
                <LinkButton v-if="action.add" :plain="true" :iconCls="iconUtil.iconAdd" @click="onAdd()">{{ btnText.add }}</LinkButton>
                <LinkButton v-if="action.edit" :plain="true" :iconCls="iconUtil.iconEdit" @click="onEdit()">{{ btnText.edit }}</LinkButton>
                <LinkButton v-if="action.delete" :plain="true" :iconCls="iconUtil.iconDelete" @click="onDelete()">{{ btnText.delete }}</LinkButton>
                <span class="button-sep"></span> {{ search.text }} : 
                <SearchBox style="width:300px"
                    :placeholder="search.placeholder" 
                    v-model="dataGrid.search"
                    @search="onSearch()">
                    <Addon>
                        <span v-if="dataGrid.search" class="textbox-icon icon-clear" title="Clear value" @click="dataGrid.search=null;onSearch();"></span>
                    </Addon>
                </SearchBox>
            </div>
            <DataGrid
                    ref="dataGrid"
                    :columnResizing="true"
                    :border="false"
                    :lazy="true"
                    :loading="dataGrid.loading"                                    
                    :data="dataGrid.rows"
                    :pagination="true"
                    :selectionMode="dataGrid.selectionMode"
                    :total="dataGrid.total"
                    :pageSize="dataGrid.pageSize"
                    :pageNumber="dataGrid.pageNumber"
                    :pageLayout="dataGrid.pageLayout" 
                    @pageChange="onPageChange($event)"
                    @sortChange="onSortChange($event)"
                    @rowDblClick="onRowDblClick()"
                    >
                
                    <GridColumn align="center" cellCss="datagrid-td-rownumber" width="30">
                    <template slot="body" slot-scope="scope">
                        {{scope.rowIndex + 1}}
                    </template>
                </GridColumn>
                <GridColumn field="truck_accident_no" title="Accident Id" :sortable="true"></GridColumn>
                <GridColumn field="vehicle_desc" title="Vehicle Id" :sortable="true"></GridColumn>
                <GridColumn field="driver_desc" title="Driver" :sortable="true"></GridColumn>
                <GridColumn field="client_desc" title="Client Id" :sortable="true"></GridColumn>
                <GridColumn field="accident_date_desc" title="Accident Date" :sortable="true"></GridColumn>
                <GridColumn field="accident_type_desc" title="Accident Type" :sortable="true"></GridColumn>
                <GridColumn field="location" title="Location" :sortable="true"></GridColumn>
            </DataGrid>
        </Panel>

        <!-- Form -->
        <Dialog ref="dlg" :iconCls="icon" bodyCls="f-column" :title="title" :draggable="true" :modal="true" closed :dialogStyle="{width:'768px', height:'auto'}">
            <div class="f-full" style="overflow:auto">
                <Form ref="form" :model="model" :rules="rules" @validate="errors=$event">
                    <fieldset class="c-fieldset">
                        <legend class="c-legend"><b>Accident Information</b></legend>
                        <table width="100%" cellspacing="1" cellpadding="5" border="0">
                            <tbody>
                                <tr>
                                    <td>Accident Id</td>
                                    <td>
                                        <NumberBox inputId="truck_accident_no" name="truck_accident_no" class="f-field-text text-50" v-model="modelNotMapping.truck_accident_no" :disabled="true" :spinners="false"></NumberBox>
                                    </td> 
                                </tr>
                                <tr>
                                    <td>Vehicle ID <font color="red">*</font></td>
                                    <td>
                                        <ComboBox  :data="vehicles" inputId="vehicle_id" name="vehicle_id" @filterChange="optionVehicle($event)" class="f-field-text" v-model="model.vehicle_id">
                                            <template slot="item" slot-scope="scope">
                                                <span style="font-weight:bold">{{scope.row.text}}</span><br/>
                                                <span style="color:#888">{{scope.row.vehicleType}}</span><br/>
                                                <span style="color:#888">{{scope.row.transporter}} ({{scope.row.status}})</span><br/>
                                                <span style="color:#888">Max Weight : {{scope.row.max_weight}}  Max Volume : {{scope.row.max_volume}}</span><br/>
                                            </template>
                                        </ComboBox>
                                        <div class="error">{{textError.vehicle_id}}</div>
                                    </td>
                                    <td>
                                        Location <font color="red">*</font>
                                    </td>
                                    <td> 
                                        <TextBox inputId="location" name="location" class="f-field-text" v-model="model.location"></TextBox>
                                        <div class="error">{{textError.location}}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Driver <font color="red">*</font></td>
                                    <td>
                                        <ComboBox  :data="drivers" inputId="driver_id" name="driver_id" @filterChange="optionDriver($event)" class="f-field-text" v-model="model.driver_id">
                                            <template slot="item" slot-scope="scope">
                                                <span style="font-weight:bold">{{scope.row.text}}</span><br/>
                                                <span style="color:#888">{{scope.row.transporter}}</span><br/>
                                            </template>
                                        </ComboBox>
                                        <div class="error">{{textError.driver_id}}</div>
                                    </td>
                                    <td>Accident Type <font color="red">*</font></td>
                                    <td>
                                        <ComboBox  :data="accidentTypes" inputId="accident_type" name="accident_type" class="f-field-text" v-model="model.accident_type"></ComboBox>
                                        <div class="error">{{textError.accident_type}}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Client ID <font color="red">*</font>
                                    </td>
                                    <td> 
                                        <ComboBox  :data="clients" inputId="client_id" name="client_id" @filterChange="optionClient($event)" class="f-field-text" v-model="model.client_id">
                                            <template slot="item" slot-scope="scope">
                                                <span style="font-weight:bold">{{scope.row.text}}</span><br/>
                                                <span style="color:#888">{{scope.row.address}}</span><br/>
                                            </template>
                                        </ComboBox>
                                        <div class="error">{{textError.client_id}}</div>
                                    </td>
                                    <td>Accident Date  <font color="red">*</font></td>
                                    <td>
                                        <DateBox inputId="accident_date" name="accident_date" class="f-field-text" v-model="model.accident_date" format="dd/MM/yyyy"></DateBox>
                                        <div class="error">{{textError.accident_date}}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Amount Less</td>
                                    <td>
                                        <NumberBox  inputId="amount_less" name="amount_less" class="f-field-text text-right" v-model="model.amount_less" :spinners="false"></NumberBox>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </fieldset>

                    <fieldset class="c-fieldset">
                        <legend class="c-legend"><b>Details</b></legend>
                        <table width="100%" cellspacing="1" cellpadding="5" border="0">
                            <tbody>
                                <tr>
                                    <td>Chronology Accident</td>
                                    <td>
                                        <TextBox inputId="chronology_accident" name="chronology_accident" :multiline="true" class="f-field-text f-field-text-area-middle" v-model="model.chronology_accident"></TextBox>
                                    </td>
                                    <td>Vehicle Condition</td>
                                    <td>
                                        <TextBox inputId="vehicle_condition" name="vehicle_condition" :multiline="true" class="f-field-text f-field-text-area-middle" v-model="model.vehicle_condition"></TextBox>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Police Investigation Report</td>
                                    <td>
                                        <TextBox inputId="police_investigation_report" name="police_investigation_report" :multiline="true" class="f-field-text f-field-text-area-middle" v-model="model.police_investigation_report"></TextBox>
                                    </td>
                                    <td>Additional Information</td>
                                    <td>
                                        <TextBox inputId="additional_information" name="additional_information" :multiline="true" class="f-field-text f-field-text-area-middle" v-model="model.additional_information"></TextBox>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </fieldset>
                </Form>
            </div>
            <div class="dialog-button">
                <LinkButton :iconCls="iconUtil.iconSave" v-if="action.add || action.edit" @click="onSave()" :disabled="disableBtnSave" style="width:89px">{{ btnText.save }}</LinkButton>
                <LinkButton :iconCls="iconUtil.iconCancel" @click="$refs.dlg.close()" style="width:89px">{{ btnText.cancel }}</LinkButton>
            </div>
        </Dialog>

        <NotificationComponent ref="notification"></NotificationComponent>
    </div>
    
</template>

<script>
import NotificationComponent from '@/components/NotificationComponent.vue';
import TruckAccident from '@/models/TruckAccident';
import FleetService from '@/services/FleetService';
import ModelUtil from '@/utils/ModelUtil';
import InformationUtil from '@/utils/InformationUtil';
import AccessUtil from '@/utils/AccessUtil';
import IconUtil from '@/utils/IconUtil';
import GlobalUtil from '@/utils/GlobalUtil';
import OptionService from '@/services/OptionService';

export default {
    name: 'TruckAccidentView',
    components : {
        NotificationComponent
    },
    data() {
        return {
            titleView : InformationUtil.truckAccident + "s",
            titleMessage : InformationUtil.truckAccident,
            iconUtil : IconUtil,

            titleForm :{
                add : InformationUtil.addText,
                edit : InformationUtil.editText
            },

            iconForm :{
                add : IconUtil.iconAdd,
                edit : IconUtil.iconEdit
            },
            
            action : {
                view : false,
                add : false,
                edit : false,
                delete : false
            },

            btnText :{
                refresh : InformationUtil.refreshText,
                add : InformationUtil.addText,
                edit : InformationUtil.editText,
                delete : InformationUtil.removeText,
                save : InformationUtil.saveText,
                cancel : InformationUtil.cancelText,
                search : InformationUtil.searchText
            },

            search : {
                text : InformationUtil.searching,
                placeholder : InformationUtil.searchHolder
            },

            dataGrid : {
                loading: false,
                rows : [],
                total: 0,
                start: 0,
                pageNumber: 1,
                pageSize: 10,
                selectionMode : 'single',
                pageLayout: ['list','sep','first','prev','next','last','sep','refresh','info'],

                // filter
                search : null,
                sortField : "",
                sortOrder : ""
            },

            // form
            title: '',
            icon: IconUtil.iconAdd,
            model: TruckAccident,
            disabled : false,
            disableBtnSave : false,

            modelNotMapping : {
                truck_accident_no : "",
            },

            // validation
            rules: {
                client_id: 'required',
                driver_id: 'required',
                vehicle_id: 'required',
                location: 'required',
                accident_type: 'required',
                accident_date: 'required',
            },
            errors: {},
            textError :{
                client_id: '',
                driver_id: '',
                vehicle_id: '',
                location: '',
                accident_type : '',
                accident_date : '',
            },

            // combobox
            drivers : [],
            clients : [],
            vehicles : [],
            accidentTypes : [
                { value: 1, text : "REAR-END COLLISIONS" },
                { value: 2, text : "SIDE-IMPACT COLLISIONS" },
                { value: 3, text : "SIDESWIPE COLLISIONS" },
                { value: 4, text : "VEHICLE ROLLOVER" },
                { value: 5, text : "HEAD-ON COLLISIONS" },
                { value: 6, text : "SINGLE-CAR ACCIDENTS" },
                { value: 7, text : "MULTIPLE VEHICLE COLLISIONS" }
            ]
        }
    },
    methods:{
        getError(name) {
            return this.errors[name] && this.errors[name].length
            ? this.errors[name][0]
            : null;
        },
        onAdd(){
            ModelUtil.resetModel(this.textError);
            // method for add data
            this.disabled = false;
            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);
            this.model = ModelUtil.resetModel(this.model);
            this.modelNotMapping = ModelUtil.resetModel(this.modelNotMapping);
            this.onCallAllOption();
            
            // set default
            this.model.accident_type = 1;
            this.model.accident_date = new Date();
            this.model.amount_less = parseInt(this.model.amount_less);
            this.modelNotMapping.truck_accident_no = parseInt(this.modelNotMapping.truck_accident_no);

            this.title = this.titleForm.add;
            this.icon = this.iconForm.add;
            this.$refs.dlg.open();
        },
        onEdit(){
            ModelUtil.resetModel(this.textError);
            // method for get data to edit
            if(this.$refs.dataGrid.selectedRows.length > 0){
                this.disabled = true;
                this.title = this.titleForm.edit;
                this.icon = this.iconForm.edit;
                this.onCallAllOption();

                // mapping combobox
                this.drivers = [{value: this.$refs.dataGrid.selectedRows[0].driver, text: this.$refs.dataGrid.selectedRows[0].driver_desc}]
                this.clients = [{value: this.$refs.dataGrid.selectedRows[0].client_id, text: this.$refs.dataGrid.selectedRows[0].client_desc}]
                this.vehicles = [{value: this.$refs.dataGrid.selectedRows[0].vehicle_id, text: this.$refs.dataGrid.selectedRows[0].vehicle_desc}]

                this.model = ModelUtil.resetModel(this.model);
                this.modelNotMapping = ModelUtil.resetModel(this.modelNotMapping);
                this.model = ModelUtil.mapModel(this.model, this.$refs.dataGrid.selectedRows[0])
                this.modelNotMapping = ModelUtil.mapModel(this.modelNotMapping, this.$refs.dataGrid.selectedRows[0])

                // mapping data
                this.model.accident_date = new Date(this.model.accident_date);
                this.model.amount_less = parseInt(this.model.amount_less);

                this.$refs.dlg.open();
            }else{
                this.$refs.notification.centerTop(InformationUtil.selectRow);
            }
        },
        onDelete(){
            if(this.$refs.dataGrid.selectedRows.length > 0){

                this.$messager.confirm({
                    title: 'Confirm',
                    msg: InformationUtil.confirmDelete,
                    result: (r) => {
                        if (r){
                            this.dataGrid.loading = true;
                            FleetService.deleteData("/truckAccident", this.$refs.dataGrid.selectedRows[0].id)
                                .then(() => {
                                    this.dataGrid.loading = false;
                                    this.$refs.notification.success(this.titleMessage + " " + InformationUtil.delete);
                                    this.loadData();
                                    this.$refs.dlg.close();
                                }).catch((err) => {
                                    this.$refs.notification.error(err.data.message);
                                });
                            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]) 
                        }
                    }
                })
                
            }else{
                this.$refs.notification.centerTop(InformationUtil.selectRow);
            }
            
        },
        onSave(){
            ModelUtil.resetModel(this.textError);
            this.$refs.form.validate((valid) => {
                if(!valid){
                    this.disableBtnSave = true;
                    this.model.accident_date = new Date(this.$moment(this.model.accident_date).format('YYYY-MM-DD'));
                    if(this.model.id == null){
                        // action add data
                        FleetService.addData("/truckAccident", this.model)
                            .then((res) => {
                                this.disableBtnSave = false;
                                if(res.code == 200){
                                    this.$refs.notification.success(this.titleMessage + " " + InformationUtil.add);
                                    this.loadData();
                                    this.$refs.dlg.close();
                                } else {
                                    this.$messager.alert({
                                        title: "Error",
                                        icon: "error",
                                        msg: res.message
                                    });
                                }                               
                            }).catch((error) => {
                                this.disableBtnSave = false;
                                this.$refs.notification.error(error);
                            });

                    }else{
                        // action update data
                        FleetService.updateData("/truckAccident", this.model.id, this.model)
                            .then((res) => {
                                this.disableBtnSave = false;
                                if(res.code == 200){
                                    this.$refs.notification.success(this.titleMessage + " " + InformationUtil.update);
                                    this.loadData();
                                    this.$refs.dlg.close();
                                } else {
                                    this.$messager.alert({
                                        title: "Error",
                                        icon: "error",
                                        msg: res.message
                                    });
                                }
                            }).catch((error) => {
                                this.disableBtnSave = false;
                                this.$refs.notification.error(error);
                            });
                    }
                    this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0])
                } else {
                    for(let key in valid){
                        this.textError[key] = this.getError(key);
                    }
                }
            });
            
        },
        
        // handle data grid
        onRowDblClick(){
            if(this.action.edit){
                this.onEdit();
            }
        },
        async onRefresh(){
            this.dataGrid.pageNumber = 1;       
            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);
            await this.loadData();
            this.$refs.notification.success(InformationUtil.refresh);
        },
        onSearch(){
            this.loadData();
        },
        onSortChange(event){
            if(event.length > 0){
                this.dataGrid.pageNumber = 1;
                this.dataGrid.sortField = event[0].field;
                this.dataGrid.sortOrder = event[0].order;
                this.loadData();
            }
        },
        onPageChange(event) {
            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);
            this.dataGrid.pageNumber = event.pageNumber;
            this.dataGrid.pageSize = event.pageSize;
            if(event.refresh) this.dataGrid.pageNumber = 1;
            this.loadData();
            if(event.refresh){
                this.$refs.notification.success(InformationUtil.refresh);
            }
        },

        async loadData(){
            this.dataGrid.loading = true;
            return FleetService.getData('/truckAccident', this.dataGrid)
                .then((response) => {
                    if(response.data !== "" && response.data !== null){
                        let data = response.data; 
                        this.dataGrid.total = data.total;
                        this.dataGrid.rows = data.rows;
                    }
                    
                    this.dataGrid.loading = false;
                });
        },

        // combobox
        async optionClient(event){
            let search = event == null ? "" : event.filterValue;
            return await OptionService.getOption("/client", search)
                .then((response) => {
                    this.clients = response.data;
                }).catch((error) => {
                    this.$refs.notification.error(error);
                });
        },
        async optionDriver(event){
            let search = event == null ? "" : event.filterValue;
            return await OptionService.getOption("/driver", search)
                .then((response) => {
                    this.drivers = response.data;
                }).catch((error) => {
                    this.$refs.notification.error(error);
                });
        },

        async optionVehicle(event){
            let search = event == null ? "" : event.filterValue;
            return await OptionService.getOption("/vehicle", search)
                .then((response) => {
                    this.vehicles = response.data;
                }).catch((error) => {
                    this.$refs.notification.error(error);
                });
        },

        onCallAllOption(){
            this.optionClient();
            this.optionDriver();
            this.optionVehicle();
        },

    },
    async mounted(){
        this.action = AccessUtil.getAccessAction('truck_accident');
        await this.loadData();
        this.$refs.notification.success(InformationUtil.loadGrid + " " + this.dataGrid.total + " " + InformationUtil.successLoadGrid);
    },
    created(){
        GlobalUtil.removeWindow();
    }
};
</script>