export default{
    state: { 
        language: null,
    },
    mutations: { 
        set_language (state, data) {
            state.language = data
        }, 
        reset_language (state) {
            state.language = null
        }
    },
    // getters:{
    //     language (state, lang, code){
    //         console.log(lang, code)
    //         return state.language
    //     }
    // },
}