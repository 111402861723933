
import { S3 } from "@aws-sdk/client-s3";

const S3ClientUtil = new S3({
    forcePathStyle: true,
    endpoint: "https://s3-id-jkt-1.kilatstorage.id",
    region: "id-jkt-1",
    credentials: {
      accessKeyId: "00e04df37f9622009254",
      secretAccessKey: "E7uwVdYWk4wQWEd7CXu48hKEaL3OHaR4Sz6elY8o"
    }
});

export { S3ClientUtil };