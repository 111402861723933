export default {
    id: null,
    client_id : null,
    schedule_date : null,
    transport_mode : null,
    budget : null,
    pref_vehicle_type : null,
    tr_status : null,
    origin_id : null,
    dest_id : null,
    origin_area_id : null,
    dest_area_id : null,
    req_pickup_time : null,
    req_arrival_time : null
}