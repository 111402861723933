export default{
    iconRefresh : "icon-reload",
    iconAdd : "icon-add-blue",
    iconEdit : "icon-edit-blue",
    iconDelete : "icon-cancel",
    iconRemove : "icon-remove",
    iconSave : "icon-ok",
    iconCancel : "icon-cancel",
    iconDownload : "icon-download",
    iconPrint : "icon-print",
    iconOk : "icon-ok",
    iconNo : "icon-no",
    iconWarning : "icon-warning",
    iconInfo : "icon-info",
    iconClose : "icon-close",
    iconSearch : "icon-search",
    iconFilter : "icon-filter",
    iconUpload : "icon-upload",
    iconTip : "icon-tip",
    iconExport : "icon-excel",
    iconMultiSearch : "icon-search",
    iconCopy : "icon-copy",
}