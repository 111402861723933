<template>
    <div>
        <Panel :title="titleView"  style="margin:0px 1px; ">
            <div class="datagrid-toolbar">
                <LinkButton :plain="true" :iconCls="iconUtil.iconRefresh" @click="onRefresh()">{{ btnText.refresh }}</LinkButton>
                <LinkButton v-if="action.add" :plain="true" :iconCls="iconUtil.iconAdd" @click="onAdd()">{{ btnText.add }}</LinkButton>
                <LinkButton v-if="action.edit" :plain="true" :iconCls="iconUtil.iconEdit" @click="onEdit()">{{ btnText.edit }}</LinkButton>
                <LinkButton v-if="action.delete" :plain="true" :iconCls="iconUtil.iconDelete" @click="onDelete()">{{ btnText.delete }}</LinkButton>
                <span class="button-sep"></span> {{ search.text }} : 
                <SearchBox style="width:300px"
                    :placeholder="search.placeholder" 
                    v-model="dataGrid.search"
                    @search="onSearch()">
                    <Addon>
                        <span v-if="dataGrid.search" class="textbox-icon icon-clear" title="Clear value" @click="dataGrid.search=null;onSearch();"></span>
                    </Addon>
                </SearchBox>
            </div>
            <DataGrid
                    ref="dataGrid"
                    :columnResizing="true"
                    :border="false"
                    :lazy="true"
                    :loading="dataGrid.loading"                                    
                    :data="dataGrid.rows"
                    :pagination="true"
                    :selectionMode="dataGrid.selectionMode"
                    :total="dataGrid.total"
                    :pageSize="dataGrid.pageSize"
                    :pageNumber="dataGrid.pageNumber"
                    :pageLayout="dataGrid.pageLayout" 
                    @pageChange="onPageChange($event)"
                    @sortChange="onSortChange($event)"
                    @rowDblClick="onRowDblClick()"
                    >
                
                    <GridColumn align="center" cellCss="datagrid-td-rownumber" width="30">
                    <template slot="body" slot-scope="scope">
                        {{scope.rowIndex + 1}}
                    </template>
                </GridColumn>
                <GridColumn field="code" title="Role Code" :sortable="true"></GridColumn>
                <GridColumn field="role" title="Role Name" :sortable="true"></GridColumn>
                <GridColumn field="is_active_desc" title="Status" :sortable="true"></GridColumn>
            </DataGrid>
        </Panel>

        <!-- Form -->
        <Dialog ref="dlg" :iconCls="icon" bodyCls="f-column" :title="title" :draggable="true" :modal="true" closed :dialogStyle="{width:'750px', height:'600px'}">
            <div class="f-full" style="overflow:auto">
                <Form ref="form" :model="model" :rules="rules" @validate="errors=$event">
                    <table width="100%" cellspacing="1" cellpadding="5" border="0">
                        <tbody>
                            <tr>
                                <td style="width: 35%" class="bg_subtitle">
                                    Role Code <font color="red">*</font>
                                </td>
                                <td class="bg_grid2"> 
                                    <TextBox inputId="code" name="code" class="f-field-text" v-model="model.code" :disabled="disabled"></TextBox>
                                    <div class="error">{{textError.code}}</div>
                                </td>
                            </tr>
                            <tr>
                                <td style="width: 35%" class="bg_subtitle">
                                    Role Name <font color="red">*</font>
                                </td>
                                <td class="bg_grid2"> 
                                    <TextBox inputId="role" name="role" class="f-field-text" v-model="model.role"></TextBox>
                                    <div class="error">{{textError.role}}</div>
                                </td>
                            </tr>
                            <tr>
                                <td class="bg_subtitle">Is Active</td>
                                <td class="bg_grid2">
                                    <SwitchButton inputId="is_active" name="is_active" class="f-field-text" v-model="model.is_active"></SwitchButton>
                                </td>
                            </tr>
                            <tr>
                                <td class="bg_subtitle">Use Dashboard</td>
                                <td class="bg_grid2">
                                    <SwitchButton inputId="use_dashboard" name="use_dashboard" class="f-field-text" v-model="model.use_dashboard"></SwitchButton>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <fieldset class="c-fieldset">
                        <legend class="c-legend"><b>Access</b></legend>
                        <TreeGrid 
                            :loading="treeGrid.loading"
                            :selectionMode="'none'"
                            style="width:694px;height:480px" :data="treeGrid.data" idField="id" treeField="title">
                            <GridColumn field="title" title="Name" width="250"></GridColumn>
                            <GridColumn field="is_view" title="View">
                                <template slot="header">
                                    <div class="item">
                                        <div class="title center-text" >
                                            View
                                            <CheckBox v-model="checkBoxHeader.is_view" @checkedChange="onCheckAll($event, 'view')" style="width:20px;height:20px"></CheckBox>
                                        </div>
                                    </div>
                                </template>
                                <template slot="body" slot-scope="scope">
                                    <div class="item center-text">
                                        <CheckBox v-model="scope.row.is_view" style="width:20px;height:20px"></CheckBox>
                                    </div>
                                </template>
                            </GridColumn>
                            <GridColumn field="is_add" title="Add">
                                <template slot="header">
                                    <div class="item">
                                        <div class="title center-text" >
                                            Add
                                            <CheckBox v-model="checkBoxHeader.is_add" @checkedChange="onCheckAll($event, 'add')" style="width:20px;height:20px"></CheckBox>
                                        </div>
                                    </div>
                                </template>
                                <template slot="body" slot-scope="scope">
                                    <div class="item center-text">
                                        <CheckBox v-model="scope.row.is_add" style="width:20px;height:20px"></CheckBox>
                                    </div>
                                </template>
                            </GridColumn>
                            <GridColumn field="is_edit" title="Edit">
                                <template slot="header">
                                    <div class="item">
                                        <div class="title center-text" >
                                            Edit
                                            <CheckBox v-model="checkBoxHeader.is_edit" @checkedChange="onCheckAll($event, 'edit')" style="width:20px;height:20px"></CheckBox>
                                        </div>
                                    </div>
                                </template>
                                <template slot="body" slot-scope="scope">
                                    <div class="item center-text">
                                        <CheckBox v-model="scope.row.is_edit" style="width:20px;height:20px"></CheckBox>
                                    </div>
                                </template>
                            </GridColumn>
                            <GridColumn field="is_delete" title="Delete">
                                <template slot="header">
                                    <div class="item">
                                        <div class="title center-text" >
                                            Delete
                                            <CheckBox v-model="checkBoxHeader.is_delete" @checkedChange="onCheckAll($event, 'delete')" style="width:20px;height:20px"></CheckBox>
                                        </div>
                                    </div>
                                </template>
                                <template slot="body" slot-scope="scope">
                                    <div class="item center-text">
                                        <CheckBox v-model="scope.row.is_delete" style="width:20px;height:20px"></CheckBox>
                                    </div>
                                </template>
                            </GridColumn>
                            <GridColumn field="is_print" title="Print">
                                <template slot="header">
                                    <div class="item">
                                        <div class="title center-text" >
                                            Print
                                            <CheckBox v-model="checkBoxHeader.is_print" @checkedChange="onCheckAll($event, 'print')" style="width:20px;height:20px"></CheckBox>
                                        </div>
                                    </div>
                                </template>
                                <template slot="body" slot-scope="scope">
                                    <div class="item center-text">
                                        <CheckBox v-model="scope.row.is_print" style="width:20px;height:20px"></CheckBox>
                                    </div>
                                </template>
                            </GridColumn>
                        </TreeGrid>
                    </fieldset>
                </Form>
            </div>
            <div class="dialog-button">
                <LinkButton :iconCls="iconUtil.iconSave" v-if="action.add || action.edit" @click="onSave()" :disabled="disableBtnSave" style="width:89px">{{ btnText.save }}</LinkButton>
                <LinkButton :iconCls="iconUtil.iconCancel" @click="$refs.dlg.close()" style="width:89px">{{ btnText.cancel }}</LinkButton>
            </div>
        </Dialog>

        <NotificationComponent ref="notification"></NotificationComponent>
    </div>
    
</template>

<script>
import NotificationComponent from '@/components/NotificationComponent.vue';
import Role from '@/models/Role';
import UserManagementService from '@/services/UserManagementService';
import ModelUtil from '@/utils/ModelUtil';
import InformationUtil from '@/utils/InformationUtil';
import AccessUtil from '@/utils/AccessUtil';
import IconUtil from '@/utils/IconUtil';
import GlobalUtil from '@/utils/GlobalUtil';

export default {
    name: 'RoleView',
    components : {
        NotificationComponent
    },
    data() {
        return {
            titleView : InformationUtil.role + "s",
            titleMessage : InformationUtil.role,
            iconUtil : IconUtil,

            titleForm :{
                add : InformationUtil.addText,
                edit : InformationUtil.editText
            },

            iconForm :{
                add : IconUtil.iconAdd,
                edit : IconUtil.iconEdit
            },
            
            action : {
                view : false,
                add : false,
                edit : false,
                delete : false,
            },

            btnText :{
                refresh : InformationUtil.refreshText,
                add : InformationUtil.addText,
                edit : InformationUtil.editText,
                delete : InformationUtil.removeText,
                save : InformationUtil.saveText,
                cancel : InformationUtil.cancelText,
                search : InformationUtil.searchText
            },

            search : {
                text : InformationUtil.searching,
                placeholder : InformationUtil.searchHolder
            },

            dataGrid : {
                loading: false,
                rows : [],
                total: 0,
                start: 0,
                pageNumber: 1,
                pageSize: 10,
                selectionMode : 'single',
                pageLayout: ['list','sep','first','prev','next','last','sep','refresh','info'],

                // filter
                search : null,
                sortField : "",
                sortOrder : ""
            },

            treeGrid : {
                data : []
            },

            // form
            title: '',
            icon: '',
            model: Role,
            checkBoxHeader : {
                is_view : true,
                is_add : true,
                is_edit : true,
                is_delete : true,
                is_print : true,
            },
            disabled : false,
            disableBtnSave : false,

            // validation
            rules: {
                code: 'required',
                role: 'required',
            },
            errors: {},
            textError :{
                code : "",
                role : "",
            },
        }
    },
    methods:{
        getError(name) {
            return this.errors[name] && this.errors[name].length
            ? this.errors[name][0]
            : null;
        },
        onAdd(){
            ModelUtil.resetModel(this.textError);
            // method for add data
            this.disabled = false;
            this.onLoadMenuTree(0);
            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);
            this.model = ModelUtil.resetModel(this.model);
            
            // set default switch box
            this.model.is_active = true;
            this.model.use_dashboard = false;
            
            this.title = this.titleForm.add;
            this.icon = this.iconForm.add;
            this.$refs.dlg.open();
        },
        onEdit(){
            ModelUtil.resetModel(this.textError);
            // method for get data to edit
            if(this.$refs.dataGrid.selectedRows.length > 0){
                this.disabled = true;
                this.title = this.titleForm.edit;
                this.icon = this.iconForm.edit;
                this.model = ModelUtil.resetModel(this.model);
                this.model = ModelUtil.mapModel(this.model, this.$refs.dataGrid.selectedRows[0])

                this.checkBoxHeader = ModelUtil.resetModel(this.checkBoxHeader);
                this.checkBoxHeader = ModelUtil.mapModel(this.checkBoxHeader, this.$refs.dataGrid.selectedRows[0])

                this.onLoadMenuTree(this.model.id);

                this.model.is_active = this.model.is_active == 1 ? true : false;
                this.model.use_dashboard = this.model.use_dashboard == 1 ? true : false;
                
                this.$refs.dlg.open();
            }else{
                this.$refs.notification.centerTop(InformationUtil.selectRow);
            }
        },
        onDelete(){
            if(this.$refs.dataGrid.selectedRows.length > 0){

                this.$messager.confirm({
                    title: 'Confirm',
                    msg: InformationUtil.confirmDelete,
                    result: (r) => {
                        if (r){
                            this.dataGrid.loading = true;
                            UserManagementService.deleteData("/role", this.$refs.dataGrid.selectedRows[0].id)
                                .then(() => {
                                    this.dataGrid.loading = false;
                                    this.$refs.notification.success(this.titleMessage + " " + InformationUtil.delete);
                                    this.loadData();
                                    this.$refs.dlg.close();
                                }).catch((err) => {
                                    this.$refs.notification.error(err.data.message);
                                });
                            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]) 
                        }
                    }
                })
                
            }else{
                this.$refs.notification.centerTop(InformationUtil.selectRow);
            }
            
        },
        onSave(){
            ModelUtil.resetModel(this.textError);
            this.model.role_access = [];
            this.$refs.form.validate((valid) => {
                if(!valid){
                    this.disableBtnSave = true;
                    if(this.treeGrid.data.length > 0){
                        this.treeGrid.data.forEach((item) => {
                            let data = {
                                id : item.id,
                                is_view : item.is_view,
                                is_add : item.is_add,
                                is_edit : item.is_edit,
                                is_delete : item.is_delete,
                                is_print : item.is_print,
                                children : []
                            }
                            item.children.forEach((itemChild) => {
                                let dataChild = {
                                    id : itemChild.id,
                                    is_view : itemChild.is_view,
                                    is_add : itemChild.is_add,
                                    is_edit : itemChild.is_edit,
                                    is_delete : itemChild.is_delete,
                                    is_print : itemChild.is_print,
                                }
                                data.children.push(dataChild);
                            })
                            
                            this.model.role_access.push(data);
                        });
                    }
                    
                    if(this.model.id == null){
                        // action add data
                        UserManagementService.addData("/role", this.model)
                            .then((res) => {
                                this.disableBtnSave = false;
                                if(res.code == 200){
                                    this.$refs.notification.success(this.titleMessage + " " + InformationUtil.add);
                                    this.loadData();
                                    this.$refs.dlg.close();
                                } else {
                                    this.$messager.alert({
                                        title: "Error",
                                        icon: "error",
                                        msg: res.message
                                    });
                                }
                            }).catch((error) => {
                                this.disableBtnSave = false;
                                this.$refs.notification.error(error);
                            });

                    }else{
                        // action update data
                        UserManagementService.updateData("/role", this.model.id, this.model)
                            .then((res) => {
                                this.disableBtnSave = false;
                                if(res.code == 200){
                                    this.$refs.notification.success(this.titleMessage + " " + InformationUtil.update);
                                    this.loadData();
                                    this.$refs.dlg.close();
                                } else {
                                    this.$messager.alert({
                                        title: "Error",
                                        icon: "error",
                                        msg: res.message
                                    });
                                }
                            }).catch((error) => {
                                this.disableBtnSave = false;
                                this.$refs.notification.error(error);
                            });
                    }
                    this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0])
                } else {
                    for(let key in valid){
                        this.textError[key] = this.getError(key);
                    }
                }
            });
            
        },
        
        // handle data grid
        onRowDblClick(){
            if(this.action.edit){
                this.onEdit();
            }
        },
        async onRefresh(){
            this.dataGrid.pageNumber = 1;       
            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);
            await this.loadData();
            this.$refs.notification.success(InformationUtil.refresh);
        },
        onSearch(){
            this.loadData();
        },
        onSortChange(event){
            if(event.length > 0){
                this.dataGrid.pageNumber = 1;
                this.dataGrid.sortField = event[0].field;
                this.dataGrid.sortOrder = event[0].order;
                this.loadData();
            }
        },
        onPageChange(event) {
            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);
            this.dataGrid.pageNumber = event.pageNumber;
            this.dataGrid.pageSize = event.pageSize;
            if(event.refresh) this.dataGrid.pageNumber = 1;
            this.loadData();
            if(event.refresh){
                this.$refs.notification.success(InformationUtil.refresh);
            }
        },

        async loadData(){
            this.dataGrid.loading = true;
            return UserManagementService.getData('/role', this.dataGrid)
                .then((response) => {
                    if(response.data !== "" && response.data !== null){
                        let data = response.data; 
                        this.dataGrid.total = data.total;
                        this.dataGrid.rows = data.rows;
                    }
                    
                    this.dataGrid.loading = false;
                });
        },

        onLoadMenuTree(id){
            this.treeGrid.data = [];
            this.treeGrid.loading = true;
            let param = {
                id: id
            }
            UserManagementService.getWithParam('/role/menu', param)
                .then((response) => {
                    if(response.data !== "" && response.data !== null){
                        this.treeGrid.data = response.data;
                    }
                    
                    this.treeGrid.loading = false;
                });
        },

        onCheckAll(event, type){
            if(type == 'view'){
                this.treeGrid.data.forEach((item) => {
                    item.is_view = event;
                    item.children.forEach((itemChild) => {
                        itemChild.is_view = event;
                    })
                });
            }else if(type == 'add'){
                this.treeGrid.data.forEach((item) => {
                    item.is_add = event;
                    item.children.forEach((itemChild) => {
                        itemChild.is_add = event;
                    })
                });
            }else if(type == 'edit'){
                this.treeGrid.data.forEach((item) => {
                    item.is_edit = event;
                    item.children.forEach((itemChild) => {
                        itemChild.is_edit = event;
                    })
                });
            }else if(type == 'delete'){
                this.treeGrid.data.forEach((item) => {
                    item.is_delete = event;
                    item.children.forEach((itemChild) => {
                        itemChild.is_delete = event;
                    })
                });
            }else if(type == 'print'){
                this.treeGrid.data.forEach((item) => {
                    item.is_print = event;
                    item.children.forEach((itemChild) => {
                        itemChild.is_print = event;
                    })
                });
            }
        },
    },
    async mounted(){
        this.action = AccessUtil.getAccessAction('roles');
        await this.loadData();
        this.$refs.notification.success(InformationUtil.loadGrid + " " + this.dataGrid.total + " " + InformationUtil.successLoadGrid);
    },
    created(){
        GlobalUtil.removeWindow();
    }
};
</script>