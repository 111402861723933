import store from '@/store';

class AccessUtil{
    
    getAccessAction(page)
    {
        let accessAction = store.getters.menuAccess;
        if(accessAction.length > 0){
            for(let i=0;i<accessAction.length;i++)
            {
                if(accessAction[i][page])
                {
                    let action = {
                        add: Boolean(accessAction[i][page].is_add),
                        edit: Boolean(accessAction[i][page].is_edit),
                        delete: Boolean(accessAction[i][page].is_delete),
                        view: Boolean(accessAction[i][page].is_view),
                        print: Boolean(accessAction[i][page].is_print)
                    }
                    return action;
                }
            }
        }else{
            let action = {
                add: false,
                edit: false,
                delete: false,
                view: false,
                print: false
            }
            return action;
        }
        
    }

    getAccessActionByType(page, type)
    {
        let accessAction = store.getters.menuAccess;
        if(accessAction.length > 0){
            for(let i=0;i<accessAction.length;i++)
            {
                if(accessAction[i][page])
                {
                    switch(type){
                        case 'add':
                            return Boolean(accessAction[i][page].is_add);
                        case 'edit':
                            return Boolean(accessAction[i][page].is_edit);
                        case 'delete':
                            return Boolean(accessAction[i][page].is_delete);
                        case 'view':
                            return Boolean(accessAction[i][page].is_view);
                        case 'print':
                            return Boolean(accessAction[i][page].is_print);
                    }
                    return false;
                }
            }
        }else{
            let action = {
                add: false,
                edit: false,
                delete: false,
                view: false,
                print: false
            }
            return action;
        }
    }
}

export default new AccessUtil();