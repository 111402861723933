import Vue from 'vue'
import Vuex from 'vuex'

// mapping store modules
import AuthStore from './AuthStore'
import AccessStore from './AccessStore'
import LanguageStore from './LanguageStore' 

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    AuthStore,
    LanguageStore,
    AccessStore,
  }
})