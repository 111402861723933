
import Vue from 'vue'
import VueRouter from 'vue-router'
import AuthLogin from '../AuthLogin.vue';
import AppMainView  from '../views/AppMainView.vue';
import NotFoundComponent from '../components/error/NotFoundComponent.vue';
import store from '@/store';

 
Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        redirect: {
            name: "login"
        }
    },
    {
        path: '/',
        name: 'login',
        component: AuthLogin
    },
    {
        path: '/app',
        name: 'application',
        component: AppMainView,
        meta: {
            auth: true
        }
    },
    { path: '*', component: NotFoundComponent }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.auth)) {
      if (store.getters.isLoggedIn && store.getters.user) {
        next()
        return
      }
      next('/')
    }
  
    next()
})

  
  export default router