<template>
    <div class="main-app">
        <router-view/>
    </div>
  </template>
   
<script>
    export default {
        name: 'App',
        mounted() {
            document.title = 'Tranport Management System'
        }
    };
</script>