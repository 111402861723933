<template>
    <div>
        Not Found
    </div>
</template>
<script>
export default {
    name: 'NotFoundComponent',
    data() {
        return {
            //
        }
    },
    methods: {
        //
    }
}
</script>