export default{
    id : null,
    vehicle_id : null,
    schedule_date : null,
    trip : null,
    driver_id : null,
    co_driver_id : null,
    start : null,
    finish : null,
    mode : null,
    load_m3 : null,
    approved_by : null,
    order_case : null
}