export default{
    id : null,
    reference : null,
    vehicle_id : null,
    odometer : null,
    registered_date : null,
    registered_time : null,
    completion_date : null,
    completion_time : null,
    workshop_id : null,
    service_type : null,
    service_status : null,
    assigned_to : null,
    vat : null,
    task_entries : null,
    sub_total : null,
    total_vat : null,
    total_amount : null,
    remark : null,
}