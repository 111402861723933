import AppService from "./AppService";

class UserManagementService{

    URL = 'userManagement';
    
    constructor(){
        
    }

    /**
     * function for get data list from api
     * @param {*} endPoint
     * @param {*} data
     * @returns
     */
    getData(endPoint, data){
        return AppService.loadGrid(this.URL + endPoint, data);
    }

    /**
     * function for add data
     * @param {*} endPoint 
     * @param {*} data 
     * @returns 
     */
    addData(endPoint, data){
        return AppService.addData(this.URL + endPoint, data);
    }

    /**
     * function for update data
     * @param {*} endPoint
     * @param {*} id
     * @param {*} data
     * @returns
     */
    updateData(endPoint, id, data){
        return AppService.updateData(this.URL + endPoint, id, data);
    }

    /**
     * function for delete data
     * @param {*} endPoint
     * @param {*} id
     * @returns
     */
    deleteData(endPoint, id){
        return AppService.deleteData(this.URL + endPoint, id);
    }

    post(endPoint, data){
        return AppService.post(this.URL + endPoint, data);
    }

    getWithParam(endPoint, data){
        return AppService.getWithParam(this.URL + endPoint, data);
    }

    
}

export default new UserManagementService;