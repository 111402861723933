<template>
    <div>
        <Dialog ref="dlg" :iconCls="icon" bodyCls="f-column" :title="title" :draggable="true" :modal="true" closed :dialogStyle="{width:'750px', height:'600px'}">
            <div class="f-full" style="overflow:auto">
                <Form ref="form" :model="model">
                    <fieldset class="c-fieldset">
                        <legend class="c-legend"><b>{{ titleLegend }}</b></legend>
                        <table width="100%" cellspacing="1" cellpadding="5" border="0">
                            <tbody>
                                <tr>
                                    <td style="width:10%;">Point ID</td>
                                    <td style="width:65%;">
                                        {{modelNotMapping.customer_id}}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Name</td>
                                    <td>
                                        {{modelNotMapping.customer_name}}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Address</td>
                                    <td>
                                        {{modelNotMapping.customer_address}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </fieldset>
                    <fieldset class="c-fieldset">
                        <legend class="c-legend"><b>Details Information</b></legend>
                        <Tabs style="height:305px">
                            <TabPanel :title="'Time Stamp'">
                                <table width="100%" cellspacing="1" cellpadding="5" border="0">
                                    <tbody>
                                        <tr>
                                            <td rowspan="3" style="width:10%; vertical-align: top; padding-left: 25px; padding-top: 10px;">Arrival</td>
                                            <td style="width:10%;">Estimation</td>
                                            <td style="width:30%;">
                                                <DateBox inputId="arrival_eta" name="arrival_eta" class="f-field-text text-40" v-model="model.arrival_eta" format="dd/MM/yyyy">
                                                    <Addon>
                                                        <span v-if="model.arrival_eta" class="textbox-icon icon-clear" title="Clear value" @click="model.arrival_eta=null;"></span>
                                                    </Addon>
                                                </DateBox>
                                                <TimePicker inputId="arrival_etatime" name="arrival_etatime" class="f-field-text text-20" v-model="model.arrival_etatime" :hour24="true" :panelStyle="{width:'250px',height:'350px'}">
                                                    <Addon>
                                                        <span v-if="model.arrival_etatime" class="textbox-icon icon-clear" title="Clear value" @click="model.arrival_etatime=null;"></span>
                                                    </Addon>
                                                </TimePicker>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Actual</td>
                                            <td>
                                                <DateBox inputId="arrival_ata" name="arrival_ata" class="f-field-text text-40" v-model="model.arrival_ata" format="dd/MM/yyyy">
                                                    <Addon>
                                                        <span v-if="model.arrival_ata" class="textbox-icon icon-clear" title="Clear value" @click="model.arrival_ata=null;"></span>
                                                    </Addon>
                                                </DateBox>
                                                <TimePicker inputId="arrival_atatime" name="arrival_atatime" class="f-field-text text-20" v-model="model.arrival_atatime" :hour24="true" :panelStyle="{width:'250px',height:'350px'}">
                                                    <Addon>
                                                        <span v-if="model.arrival_atatime" class="textbox-icon icon-clear" title="Clear value" @click="model.arrival_atatime=null;"></span>
                                                    </Addon>
                                                </TimePicker>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>SPM Submit</td>
                                            <td>
                                                <DateBox inputId="spm_submit" name="spm_submit" class="f-field-text text-40" v-model="model.spm_submit" format="dd/MM/yyyy">
                                                    <Addon>
                                                        <span v-if="model.spm_submit" class="textbox-icon icon-clear" title="Clear value" @click="model.spm_submit=null;"></span>
                                                    </Addon>
                                                </DateBox>
                                                <TimePicker inputId="spm_submittime" name="spm_submittime" class="f-field-text text-20" v-model="model.spm_submittime" :hour24="true" :panelStyle="{width:'250px',height:'350px'}">
                                                    <Addon>
                                                        <span v-if="model.spm_submittime" class="textbox-icon icon-clear" title="Clear value" @click="model.spm_submittime=null;"></span>
                                                    </Addon>
                                                </TimePicker>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td rowspan="3" style="width:10%; vertical-align: top; padding-left: 25px; padding-top: 10px;">Loading</td>
                                            <td>Start</td>
                                            <td>
                                                <DateBox inputId="loading_start" name="loading_start" class="f-field-text text-40" v-model="model.loading_start" format="dd/MM/yyyy">
                                                    <Addon>
                                                        <span v-if="model.loading_start" class="textbox-icon icon-clear" title="Clear value" @click="model.loading_start=null;"></span>
                                                    </Addon>
                                                </DateBox>
                                                <TimePicker inputId="loading_starttime" name="loading_starttime" class="f-field-text text-20" v-model="model.loading_starttime" :hour24="true" :panelStyle="{width:'250px',height:'350px'}">
                                                    <Addon>
                                                        <span v-if="model.loading_starttime" class="textbox-icon icon-clear" title="Clear value" @click="model.loading_starttime=null;"></span>
                                                    </Addon>
                                                </TimePicker>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Finish</td>
                                            <td>
                                                <DateBox inputId="loading_finish" name="loading_finish" class="f-field-text text-40" v-model="model.loading_finish" format="dd/MM/yyyy">
                                                    <Addon>
                                                        <span v-if="model.loading_finish" class="textbox-icon icon-clear" title="Clear value" @click="model.loading_finish=null;"></span>
                                                    </Addon>
                                                </DateBox>
                                                <TimePicker inputId="loading_finishtime" name="loading_finishtime" class="f-field-text text-20" v-model="model.loading_finishtime" :hour24="true" :panelStyle="{width:'250px',height:'350px'}">
                                                    <Addon>
                                                        <span v-if="model.loading_finishtime" class="textbox-icon icon-clear" title="Clear value" @click="model.loading_finishtime=null;"></span>
                                                    </Addon>
                                                </TimePicker>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Documentation</td>
                                            <td>
                                                <DateBox inputId="documentation" name="documentation" class="f-field-text text-40" v-model="model.documentation" format="dd/MM/yyyy">
                                                    <Addon>
                                                        <span v-if="model.documentation" class="textbox-icon icon-clear" title="Clear value" @click="model.documentation=null;"></span>
                                                    </Addon>
                                                </DateBox>
                                                <TimePicker inputId="documentationtime" name="documentationtime" class="f-field-text text-20" v-model="model.documentationtime" :hour24="true" :panelStyle="{width:'250px',height:'350px'}">
                                                    <Addon>
                                                        <span v-if="model.documentationtime" class="textbox-icon icon-clear" title="Clear value" @click="model.documentationtime=null;"></span>
                                                    </Addon>
                                                </TimePicker>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td rowspan="3" style="width:10%; vertical-align: top; padding-left: 25px; padding-top: 10px;">Departure</td>
                                            <td>Estimation</td>
                                            <td style="width:50%;">
                                                <DateBox inputId="departure_eta" name="departure_eta" class="f-field-text text-40" v-model="model.departure_eta" format="dd/MM/yyyy">
                                                    <Addon>
                                                        <span v-if="model.departure_eta" class="textbox-icon icon-clear" title="Clear value" @click="model.departure_eta=null;"></span>
                                                    </Addon>
                                                </DateBox>
                                                <TimePicker inputId="departure_etatime" name="departure_etatime" class="f-field-text text-20" v-model="model.departure_etatime" :hour24="true" :panelStyle="{width:'250px',height:'350px'}">
                                                    <Addon>
                                                        <span v-if="model.departure_etatime" class="textbox-icon icon-clear" title="Clear value" @click="model.departure_etatime=null;"></span>
                                                    </Addon>
                                                </TimePicker>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Actual</td>
                                            <td>
                                                <DateBox inputId="departure_ata" name="departure_ata" class="f-field-text text-40" v-model="model.departure_ata" format="dd/MM/yyyy">
                                                    <Addon>
                                                        <span v-if="model.departure_ata" class="textbox-icon icon-clear" title="Clear value" @click="model.departure_ata=null;"></span>
                                                    </Addon>
                                                </DateBox>
                                                <TimePicker inputId="departure_atatime" name="departure_atatime" class="f-field-text text-20" v-model="model.departure_atatime" :hour24="true" :panelStyle="{width:'250px',height:'350px'}">
                                                    <Addon>
                                                        <span v-if="model.departure_atatime" class="textbox-icon icon-clear" title="Clear value" @click="model.departure_atatime=null;"></span>
                                                    </Addon>
                                                </TimePicker>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </TabPanel>
                            <TabPanel :title="'Image'">
                                <table width="100%" cellspacing="1" cellpadding="5" border="0">
                                    <tbody>
                                        <tr v-for="(data, idx) in listImage" v-bind:key="idx">
                                            <td width="40%">
                                                <img :src="data.image" style="width: 100%; height: auto" />    
                                            </td>
                                            <td width="60%" style="vertical-align: top;">
                                                <div style="font-size: 14px">
                                                    <b>Type</b> : {{data.type}}<br />
                                                    <b>Note</b> : {{data.note}}
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </TabPanel>
                            <TabPanel :title="'Log'">
                                <table width="100%" cellspacing="1" cellpadding="5" border="0">
                                    <tbody>
                                        <tr>
                                            <td>
                                                Created By
                                            </td>
                                            <td>
                                                <TextBox inputId="created_by" name="created_by" class="f-field-text" :value="modelNotMapping.created_by" :disabled="true"></TextBox>
                                            </td>
                                            <td>
                                                Created Date
                                            </td>
                                            <td>
                                                <TextBox inputId="created_date" name="created_date" class="f-field-text" :value="modelNotMapping.created_date" :disabled="true"></TextBox>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Updated By
                                            </td>
                                            <td>
                                                <TextBox inputId="updated_by" name="updated_by" class="f-field-text" :value="modelNotMapping.updated_by" :disabled="true"></TextBox>
                                            </td>
                                            <td>
                                                Updated Date
                                            </td>
                                            <td>
                                                <TextBox inputId="updated_date" name="updated_date" class="f-field-text" :value="modelNotMapping.updated_date" :disabled="true"></TextBox>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </TabPanel>
                        </Tabs>
                        <table width="100%" cellspacing="1" cellpadding="5" border="0">
                            <tbody>
                                <tr>
                                    <td>
                                        Notes
                                        <TextBox inputId="remark" name="remark" :multiline="true" class="f-field-text f-field-text-area" v-model="model.notes"></TextBox>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </fieldset>
                </Form>
            </div>
            <div class="dialog-button">
                <LinkButton :iconCls="iconUtil.iconSave" @click="onSaveData()" :disabled="disableBtnSave" style="width:89px">{{ btnText.save }}</LinkButton>
                <LinkButton :iconCls="iconUtil.iconCancel" @click="onPageClose()" style="width:89px">{{ btnText.cancel }}</LinkButton>
            </div>
        </Dialog>
    </div>
</template>
<script>
    
    import { GetObjectCommand } from "@aws-sdk/client-s3";
    import { S3ClientUtil } from "@/utils/S3ClientUtil";
    import { getSignedUrl } from '@aws-sdk/s3-request-presigner';

    import TrafficMonitoring from '@/models/TrafficMonitoring';
    import TransportService from '@/services/TransportService';

    import InformationUtil from '@/utils/InformationUtil';
    import ModelUtil from '@/utils/ModelUtil';
    import IconUtil from '@/utils/IconUtil';
    import GlobalUtil from '@/utils/GlobalUtil';

    export default {
        name: 'FormTimeWindowComponent',
        props: {
            actionData : {
                type : String,
                default : ''
            }
        },
        data() {
            return {
                title : InformationUtil.editText + " " + InformationUtil.timeWindow,
                titleLegend : InformationUtil.customer,
                iconUtil : IconUtil,
                icon : IconUtil.iconEdit,
                disableBtnSave : false,
                btnText :{
                    refresh : InformationUtil.refreshText,
                    add : InformationUtil.addText,
                    edit : InformationUtil.editText,
                    delete : InformationUtil.removeText,
                    save : InformationUtil.saveText,
                    cancel : InformationUtil.cancelText,
                    search : InformationUtil.searchText
                },
                
                model : TrafficMonitoring,
                modelNotMapping: {
                    created_by : "",
                    updated_by : "",
                    created_date : "",
                    updated_date : "",
                    customer_id: '',
                    customer_name : '',
                    customer_address : '',
                },

                trafficMonitoringId : '',
                listImage : []
            };
        },
        methods: {
            onPageShow(row) {
                if(
                    row.manifestId != null && row.manifestId != undefined &&
                    row.pointId != null && row.pointId != undefined &&
                    row.tmState != null && row.tmState != undefined
                    ){
                        this.trafficMonitoringId = row.trafficMonitoringId;
                        TransportService.post('/timeWindow/show', row)
                            .then((response) => {
                                let dataResponse = response;
                                if(dataResponse.data !== "" && dataResponse.data !== null){

                                    this.model = ModelUtil.resetModel(this.model);
                                    this.model = ModelUtil.mapModel(this.model, dataResponse.data);

                                    this.modelNotMapping = ModelUtil.resetModel(this.modelNotMapping);
                                    this.modelNotMapping = ModelUtil.mapModel(this.modelNotMapping, dataResponse.data);

                                    this.model.arrival_eta = ( this.model.arrival_eta != null && this.model.arrival_eta != undefined && this.model.arrival_eta != '') ? new Date(this.model.arrival_eta) : null;
                                    this.model.arrival_ata = ( this.model.arrival_ata != null && this.model.arrival_ata != undefined && this.model.arrival_ata != '') ? new Date(this.model.arrival_ata) : null;
                                    this.model.spm_submit = ( this.model.spm_submit != null && this.model.spm_submit != undefined && this.model.spm_submit != '') ? new Date(this.model.spm_submit) : null;
                                    this.model.loading_start = ( this.model.loading_start != null && this.model.loading_start != undefined && this.model.loading_start != '') ? new Date(this.model.loading_start) : null;
                                    this.model.loading_finish = ( this.model.loading_finish != null && this.model.loading_finish != undefined && this.model.loading_finish != '') ? new Date(this.model.loading_finish) : null;
                                    this.model.documentation = ( this.model.documentation != null && this.model.documentation != undefined && this.model.documentation != '') ? new Date(this.model.documentation) : null;
                                    this.model.departure_eta = ( this.model.departure_eta != null && this.model.departure_eta != undefined && this.model.departure_eta != '') ? new Date(this.model.departure_eta) : null;
                                    this.model.departure_ata = ( this.model.departure_ata != null && this.model.departure_ata != undefined && this.model.departure_ata != '') ? new Date(this.model.departure_ata) : null;
                                    
                                    this.$refs.dlg.open();
                                }
                            });

                        this.onLoadImage();
                }
            },
            onPageClose(){
                this.model = ModelUtil.resetModel(this.model);
                this.modelNotMapping = ModelUtil.resetModel(this.modelNotMapping);
                this.model.arrival_eta = null;
                this.model.arrival_ata = null;
                this.model.spm_submit = null;
                this.model.loading_start = null;
                this.model.loading_finish = null;
                this.model.documentation = null;
                this.model.departure_eta = null;
                this.model.departure_ata = null;
                this.$refs.dlg.close()
            },
            onSaveData(){
                this.disableBtnSave = true;
                this.model.arrival_eta = ( this.model.arrival_eta != null && this.model.arrival_eta != undefined && this.model.arrival_eta != '') ? new Date(this.$moment(this.model.arrival_eta).format('YYYY-MM-DD')) : null;
                this.model.arrival_ata = ( this.model.arrival_ata != null && this.model.arrival_ata != undefined && this.model.arrival_ata != '') ? new Date(this.$moment(this.model.arrival_ata).format('YYYY-MM-DD')) : null;
                this.model.spm_submit = ( this.model.spm_submit != null && this.model.spm_submit != undefined && this.model.spm_submit != '') ? new Date(this.$moment(this.model.spm_submit).format('YYYY-MM-DD')) : null;
                this.model.loading_start = ( this.model.loading_start != null && this.model.loading_start != undefined && this.model.loading_start != '') ? new Date(this.$moment(this.model.loading_start).format('YYYY-MM-DD')) : null;
                this.model.loading_finish = ( this.model.loading_finish != null && this.model.loading_finish != undefined && this.model.loading_finish != '') ? new Date(this.$moment(this.model.loading_finish).format('YYYY-MM-DD')) : null;
                this.model.documentation = ( this.model.documentation != null && this.model.documentation != undefined && this.model.documentation != '') ? new Date(this.$moment(this.model.documentation).format('YYYY-MM-DD')) : null;
                this.model.departure_eta = ( this.model.departure_eta != null && this.model.departure_eta != undefined && this.model.departure_eta != '') ? new Date(this.$moment(this.model.departure_eta).format('YYYY-MM-DD')) : null;
                this.model.departure_ata = ( this.model.departure_ata != null && this.model.departure_ata != undefined && this.model.departure_ata != '') ? new Date(this.$moment(this.model.departure_ata).format('YYYY-MM-DD')) : null;
                TransportService.post("/timeWindow/update", this.model)
                    .then((res) => {
                        this.disableBtnSave = false;
                        if(res.code == 200){
                            this.$refs.dlg.close();
                            
                            this.model = ModelUtil.resetModel(this.model);
                            this.modelNotMapping = ModelUtil.resetModel(this.modelNotMapping);

                            this.model.arrival_eta = null;
                            this.model.arrival_ata = null;
                            this.model.spm_submit = null;
                            this.model.loading_start = null;
                            this.model.loading_finish = null;
                            this.model.documentation = null;
                            this.model.departure_eta = null;
                            this.model.departure_ata = null;
                
                            // set event after save
                            this.$emit('onSaveData', this.model);
                        } else {
                            this.$messager.alert({
                                title: "Error",
                                icon: "error",
                                msg: res.message
                            });
                        }
                    }).catch((error) => {
                        this.disableBtnSave = false;
                        this.$refs.notification.error(error);
                    });

            },
            async onLoadImage(){
                this.listImage = [];

                let that = this;
                TransportService.post("/timeWindow/image", { id : this.trafficMonitoringId } )
                    .then((res) => {
                        if(res.code == 200){
                            this.listImage = [];
                            res.data.forEach(async (item) => {
                                let arrImg = item.image.split('/');
                                let bucket = String(arrImg[0]);
                                let key = String(arrImg[1]);
                                item.image = await getSignedUrl(S3ClientUtil, new GetObjectCommand({Bucket : bucket, Key : key}), { expiresIn: 15 * 60 });
                                that.setImageList(item);
                            });
                        }
                    })
            },
            setImageList(item){
                this.listImage.push(item);
            }
        },
        created(){
            GlobalUtil.removeWindow();
        }
    };
</script>