export default {
    manifest_id : "",
    transport_order_id : "",
    point_id : "",
    tm_state : "",
    arrival_eta : "",
    arrival_etatime : "",
    arrival_ata : "",
    arrival_atatime : "",
    spm_submit : "",
    spm_submittime : "",
    loading_start : "",
    loading_starttime : "",
    loading_finish : "",
    loading_finishtime : "",
    documentation : "",
    documentationtime : "",
    departure_eta : "",
    departure_etatime : "",
    departure_ata : "",
    departure_atatime : "",
    notes : ""
}