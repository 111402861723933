export default {
    id: null,
    workshop_id : "",
    name : "",
    address1 : "",
    address2 : "",
    city : "",
    postal_code : "",
    phone : "",
    additional_information : ""
}