import axios from "axios";
import GlobalUtil from "@/utils/GlobalUtil";

class AppService{
    URL_API = ""

    constructor(){
        this.API_URL = process.env.VUE_APP_API_URL;
    }

    /**
     * Function for get url api on base service
     * @returns 
     */
    getApi(){
        return this.API_URL;
    }

    /**
     * Set interceptor for handling error
     * @param {*} router 
     */
    setInterceptorApi(store, router){
        axios.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            if (error.response.status === 401) {
                GlobalUtil.removeWindow();
                store.dispatch('logout')
                router.replace({ name: 'login' })
                return
            }
        });
    }

    /**
     * For set header token when access api
     * @param {*} token 
     */
    setHeaderToken(token){
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    }

    /**
     * for remove header token
     */
    removeHeaderToken () {
        delete axios.defaults.headers.common['Authorization']
    }

    /**
     * for extends post axios when handling in base service
     */
    post(url, data){
        return axios.post(this.API_URL + url, data)
            .then(res => {
                return res.data
            }).catch(err => {
                return err.data
            })
    }

    /**
     * function for get data from api
     * @param {*} url 
     * @returns 
     */
    get(url){
        return axios.get(this.API_URL + url)
            .then(res => {
                return res.data
            }).catch(err => {
                return err.data
            })
    }

    getWithParam(url, data){
        return axios.get(this.API_URL + url, {params : data})
            .then(res => {
                return res.data
            }).catch(err => {
                return err.data
            })
    }

    /**
     * Base load data grid application
     * @param {*} url 
     * @param {*} data 
     * @returns 
     */

    loadGrid(url, data, param = {}){
        let params = {
            pageNumber: data.pageNumber,
            pageSize: data.pageSize,
            start : data.pageNumber != 1 ? (data.pageNumber - 1) * data.pageSize : 0,
            search: data.search,
            sortField: data.sortField,
            sortOrder: data.sortOrder
        }

        return axios.get(this.API_URL + url, {params : {...params, ...param}})
            .then(res => {
                return res.data
            }).catch(err => {
                return err.data
            })
    }

    addData(url, data){
        return axios.post(this.API_URL + url, data)
            .then(res => {
                return res.data
            }).catch(err => {
                return err.data
            })
    }

    updateData(url, id, data){
        return axios.put(this.API_URL + url + '/' + id, data)
            .then(res => {
                return res.data
            }).catch(err => {
                return err.data
            })
    }

    deleteData(url, id){
        return axios.delete(this.API_URL + url + '/' + id)
            .then(res => {
                return res.data
            }).catch(err => {
                return err.data
            })
    }

    uploadFile(url, data){
        return axios.post(this.API_URL + url, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
          })
            .then(res => {
                return res.data
            }).catch(err => {
                return err.data
            })
    }

    downloadFile(url, data){
        return axios.get(this.API_URL + url, {params : data, responseType: 'blob'})
            .then(res => {
                return res.data
            }).catch(err => {
                return err.data
            })
    }

    importFile(url, data){
        return axios.post(this.API_URL + url, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            responseType: 'blob'
          })
            .then(res => {
                return res.data
            }).catch(err => {
                return err.data
            })
    }

}

export default new AppService;