<template>
    <div>
        <Panel :title="titleView"  style="margin:0px 1px; ">
            <div class="datagrid-toolbar">
                <LinkButton :plain="true" :iconCls="iconUtil.iconRefresh" @click="onRefresh()">{{ btnText.refresh }}</LinkButton>
                <LinkButton v-if="action.add" :plain="true" :iconCls="iconUtil.iconAdd" @click="onAdd()">{{ btnText.add }}</LinkButton>
                <LinkButton v-if="action.edit" :plain="true" :iconCls="iconUtil.iconEdit" @click="onEdit()">{{ btnText.edit }}</LinkButton>
                <LinkButton v-if="action.delete" :plain="true" :iconCls="iconUtil.iconDelete" @click="onDelete()">{{ btnText.delete }}</LinkButton>
                <span class="button-sep"></span> Status : 
                <ComboBox  :data="statusData" inputId="searchStatusData" name="searchStatusData" class="f-field-text" style="width:75px; margin-left: 5px; margin-right: 5px;" v-model="searchStatusData" @selectionChange="onSelectionStatusData($event)"></ComboBox>
                <span class="button-sep"></span> {{ search.text }} : 
                <SearchBox style="width:300px"
                    :placeholder="search.placeholder" 
                    v-model="dataGrid.search"
                    @search="onSearch()">
                    <Addon>
                        <span v-if="dataGrid.search" class="textbox-icon icon-clear" title="Clear value" @click="dataGrid.search=null;onSearch();"></span>
                    </Addon>
                </SearchBox>
            </div>
            <DataGrid
                    ref="dataGrid"
                    :columnResizing="true"
                    :border="false"
                    :lazy="true"
                    :loading="dataGrid.loading"
                    :data="dataGrid.rows"
                    :pagination="true"
                    :selectionMode="dataGrid.selectionMode"
                    :total="dataGrid.total"
                    :pageSize="dataGrid.pageSize"
                    :pageNumber="dataGrid.pageNumber"
                    :pageLayout="dataGrid.pageLayout" 
                    @pageChange="onPageChange($event)"
                    @sortChange="onSortChange($event)"
                    @rowDblClick="onRowDblClick()"
                    >
                <GridColumn align="center" cellCss="datagrid-td-rownumber" width="30">
                    <template slot="body" slot-scope="scope">
                        {{scope.rowIndex + 1}}
                    </template>
                </GridColumn>
                <GridColumn field="self_billing_id" title="Self Billing ID" :sortable="true"></GridColumn>
                <GridColumn field="reference" title="Reference" :sortable="true"></GridColumn>
                <GridColumn field="invoice_date_desc" title="Invoice Date" :sortable="true"></GridColumn>
                <GridColumn field="type_desc" title="Type" :sortable="true"></GridColumn>
                <GridColumn field="client_desc" title="Client Id" :sortable="true"></GridColumn>
                <GridColumn field="vehicle_desc" title="No Pol" :sortable="true"></GridColumn>
                <GridColumn field="payment_term" title="Payment Term" :sortable="true"></GridColumn>
                <GridColumn field="aging_val" title="Aging" :sortable="true"></GridColumn>
                <GridColumn field="overdue_val" title="Overdue" :sortable="true"></GridColumn>
                <GridColumn field="status_desc" title="Status" :sortable="true"></GridColumn>
            </DataGrid>
        </Panel>

        <!-- Form -->
        <Dialog ref="dlg" :iconCls="icon" bodyCls="f-column" :title="title" :draggable="true" :modal="true" closed :dialogStyle="{width:'750px', height:'600px'}">
            <div class="f-full" style="overflow:auto">
                <Panel>
                    <LinkButton v-if="isCanPrint" :plain="true" :iconCls="iconUtil.iconPrint" @click="onPrint()">&nbsp;{{ btnText.printInvoice }}</LinkButton>
                </Panel>
                <Form ref="form" :model="model" :rules="rules" @validate="errors=$event">
                    <fieldset class="c-fieldset">
                        <legend class="c-legend"><b>{{ titleMessage }}</b></legend>
                        <table width="100%" cellspacing="1" cellpadding="5" border="0">
                            <tbody>
                                <tr>
                                    <td>Invoice Date</td>
                                    <td style="width:35%;">
                                        <DateBox inputId="invoice_date" name="invoice_date" class="f-field-text" v-model="model.invoice_date" format="dd/MM/yyyy" ></DateBox>
                                        <div class="error">{{textError.invoice_date}}</div>
                                    </td>
                                    <td>Schedule From Date <font color="red">*</font></td>
                                    <td>
                                        <DateBox inputId="from_date" name="from_date" class="f-field-text" v-model="model.from_date" format="dd/MM/yyyy" :disabled="disabledClosed"></DateBox>
                                        <div class="error">{{textError.from_date}}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Reference <font color="red">*</font></td>
                                    <td>
                                        <TextBox inputId="reference" name="reference" class="f-field-text" v-model="model.reference" @selectionChange="onLoadManifest" ></TextBox>
                                        <div class="error">{{textError.reference}}</div>
                                    </td>
                                    <td>Schedule To Date <font color="red">*</font></td>
                                    <td>
                                        <DateBox inputId="to_date" name="to_date" class="f-field-text" v-model="model.to_date" format="dd/MM/yyyy" :disabled="disabledClosed" @selectionChange="onLoadManifest" ></DateBox>
                                        <div class="error">{{textError.to_date}}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Type <font color="red">*</font></td>
                                    <td>
                                        <ComboBox  :data="statusTypes" inputId="type" name="type" class="f-field-text" v-model="model.type" :disabled="disabledClosed" @selectionChange="onLoadManifest"></ComboBox>
                                        <div class="error">{{textError.type}}</div>
                                    </td>
                                    <td>Status</td>
                                    <td>
                                        <TextBox class="f-field-text" v-model="modelNotMapping.status_desc" :disabled="true"></TextBox>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Client ID <font color="red">*</font>
                                    </td>
                                    <td> 
                                        <ComboBox  :data="clients" inputId="client_id" name="client_id" @filterChange="optionClient($event)" @selectionChange="onChangeClient($event);onLoadManifest();" class="f-field-text" v-model="model.client_id" :disabled="disabledClosed">
                                            <template slot="item" slot-scope="scope">
                                                <span style="font-weight:bold">{{scope.row.text}}</span><br/>
                                                <span style="color:#888">{{scope.row.address}}</span><br/>
                                            </template>
                                        </ComboBox>
                                        <div class="error">{{textError.client_id}}</div>
                                    </td>
                                    <td>
                                        Taxable <font color="red">*</font>
                                    </td>
                                    <td> 
                                        <ComboBox  :data="taxables" inputId="taxable" name="taxable" @selectionChange="onChangeTax($event);onLoadManifest();" class="f-field-text" v-model="model.taxable" :disabled="disabledClosed">
                                        </ComboBox>
                                        <div class="error">{{textError.taxable}}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Area Type <font color="red">*</font></td>
                                    <td>
                                        <ComboBox  :data="areaTypes" inputId="area_type" name="area_type" class="f-field-text" v-model="model.area_type" @selectionChange="onLoadManifest" :disabled="disabledClosed"></ComboBox>
                                        <div class="error">{{textError.area_type}}</div>
                                    </td>
                                    <td>Vat <font color="red">*</font></td>
                                    <td>
                                        <NumberBox inputId="vat" name="vat" class="f-field-text" v-model="model.vat" :spinners="false" :disabled="disableVat"></NumberBox>
                                        <div class="error">{{textError.vat}}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Vehicle ID</td>
                                    <td>
                                        <ComboBox  :data="vehicles" inputId="vehicle_id" name="vehicle_id" @filterChange="optionVehicle($event)" @selectionChange="onLoadManifest();" class="f-field-text" v-model="model.vehicle_id" :disabled="disabledClosed">
                                            <template slot="item" slot-scope="scope">
                                                <span style="font-weight:bold">{{scope.row.text}}</span><br/>
                                                <span style="color:#888">{{scope.row.vehicleType}}</span><br/>
                                                <span style="color:#888">{{scope.row.transporter}} ({{scope.row.status}})</span><br/>
                                                <span style="color:#888">Max Weight : {{scope.row.max_weight}}  Max Volume : {{scope.row.max_volume}}</span><br/>
                                            </template>
                                        </ComboBox>
                                    </td>
                                    <td>Payment Date</td>
                                    <td>
                                        <DateBox inputId="payment_date" name="payment_date" class="f-field-text" v-model="model.payment_date" format="dd/MM/yyyy" :disabled="isDataAdd"></DateBox>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Payment Term <font color="red">*</font></td>
                                    <td>
                                        <NumberBox inputId="payment_term" name="payment_term" class="f-field-text" v-model="model.payment_term" :spinners="false" ></NumberBox>
                                        <div class="error">{{textError.payment_term}}</div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </fieldset>
                    <fieldset class="c-fieldset">
                        <legend class="c-legend"><b>Details Information</b></legend>
                        <Tabs style="height:295px">
                            <TabPanel :title="'Manifests'">
                                <div class="datagrid-toolbar" style="margin-bottom: 10px;">
                                    <LinkButton v-if="!disabledClosed" :plain="true" :iconCls="iconUtil.iconRefresh" @click="onRefreshManifest()">{{ btnText.refresh }}</LinkButton>
                                    <LinkButton v-if="!disabledClosed" :plain="true" :iconCls="iconUtil.iconDelete" @click="onDeleteManifest()">{{ btnText.delete }}</LinkButton>
                                </div>

                                <DataGrid
                                        ref="dataGridManifest"
                                        :border="false"
                                        :loading="dataGridManifest.loading"
                                        :data="dataGridManifest.rows"
                                        :total="dataGridManifest.total"
                                        :showFooter="true"
                                        style="width:676px;height:203px"
                                        >
                                    <GridColumnGroup :frozen="true" align="left" width="120">
                                        <GridHeaderRow>
                                            <GridColumn v-if="!disabledClosed" field="ck" :width="30" align="center">
                                                <template slot="body" slot-scope="scope">
                                                    <CheckBox v-model="scope.row.checked" @checkedChange="onCheckedChange($event)"></CheckBox>
                                                </template>
                                            </GridColumn>
                                            <GridColumn field="manifest_id" title="Manifest Id" :frozen="true" width="90"></GridColumn>
                                        </GridHeaderRow>
                                    </GridColumnGroup>

                                    <GridColumnGroup>
                                        <GridHeaderRow>
                                            <GridColumn field="do_number" title="DO Number" width="300"></GridColumn>
                                            <GridColumn field="schedule_date" title="Schedule Date" width="100"></GridColumn>
                                            <GridColumn field="trip" title="Trip" width="100"></GridColumn>
                                            <GridColumn field="origin_name" title="Origin Name" width="100"></GridColumn>
                                            <GridColumn field="origin_area_id" title="Origin Area" width="100"></GridColumn>
                                            <GridColumn field="dest_name" title="Dest Name" width="100"></GridColumn>
                                            <GridColumn field="dest_area_id" title="Dest Area" width="100"></GridColumn>
                                        </GridHeaderRow>
                                    </GridColumnGroup>
                                    
                                    
                                </DataGrid>
                            </TabPanel>
                            <TabPanel :title="'Amounts'">
                                <table width="100%" cellspacing="1" cellpadding="5" border="0">
                                    <tbody>
                                        <tr>
                                            <td style="width:15%;">
                                                Sub Total
                                            </td>
                                            <td>
                                                <TextBox class="f-field-text text-40" :value="modelNotMapping.sub_total" :disabled="true"></TextBox>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Total Vat
                                            </td>
                                            <td>
                                                <TextBox class="f-field-text text-40" :value="modelNotMapping.total_vat" :disabled="true"></TextBox>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Total Amount
                                            </td>
                                            <td>
                                                <TextBox class="f-field-text text-40" :value="modelNotMapping.total_amount" :disabled="true"></TextBox>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </TabPanel>
                            <TabPanel :title="'Log'">
                                <table width="100%" cellspacing="1" cellpadding="5" border="0">
                                    <tbody>
                                        <tr>
                                            <td>
                                                Created By
                                            </td>
                                            <td>
                                                <TextBox class="f-field-text" :value="modelNotMapping.created_by" :disabled="true"></TextBox>
                                            </td>
                                            <td>
                                                Created Date
                                            </td>
                                            <td>
                                                <TextBox class="f-field-text" :value="modelNotMapping.created_date" :disabled="true"></TextBox>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Updated By
                                            </td>
                                            <td>
                                                <TextBox class="f-field-text" :value="modelNotMapping.updated_by" :disabled="true"></TextBox>
                                            </td>
                                            <td>
                                                Updated Date
                                            </td>
                                            <td>
                                                <TextBox class="f-field-text" :value="modelNotMapping.updated_date" :disabled="true"></TextBox>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </TabPanel>
                        </Tabs>
                    </fieldset>
                </Form>
            </div>
            <div class="dialog-button">
                <LinkButton :iconCls="iconUtil.iconSave" v-if="action.add || action.edit" @click="onSave()" :disabled="disableBtnSave" style="width:89px">{{ btnText.save }}</LinkButton>
                <LinkButton :iconCls="iconUtil.iconCancel" @click="$refs.dlg.close()" style="width:89px">{{ btnText.cancel }}</LinkButton>
            </div>
        </Dialog>

        <NotificationComponent ref="notification"></NotificationComponent>
        <PrintSalesInvoiceComponentVue ref="printInvoice" :id="idPrint"></PrintSalesInvoiceComponentVue>
    </div>
    
</template>

<script>
import NotificationComponent from '@/components/NotificationComponent.vue';
import TransportService from '@/services/TransportService';
import ModelUtil from '@/utils/ModelUtil';
import InformationUtil from '@/utils/InformationUtil';
import AccessUtil from '@/utils/AccessUtil';
import IconUtil from '@/utils/IconUtil';
import GlobalUtil from '@/utils/GlobalUtil';

import OptionService from '@/services/OptionService';
import PurchaseInvoce from '@/models/PurchaseInvoce';

import PrintSalesInvoiceComponentVue from '@/components/report/PrintSalesInvoiceComponent.vue';

export default {
    name: 'ClientInvoiceView',
    components : {
        NotificationComponent,
        PrintSalesInvoiceComponentVue
    },
    data() {
        return {
            urlAction : "salesInvoice",
            titleView : InformationUtil.selfBilling + "s",
            titleMessage : InformationUtil.selfBilling,
            iconUtil : IconUtil,

            idPrint : 0,

            titleForm :{
                add : InformationUtil.addText,
                edit : InformationUtil.editText
            },

            iconForm :{
                add : IconUtil.iconAdd,
                edit : IconUtil.iconEdit
            },
            
            action : {
                view : false,
                add : false,
                edit : false,
                delete : false
            },

            isDataAdd : true,
            isCanPrint : false,
            disableVat : false,

            filter : {
                viewStatusMessage : false,
            },

            btnText :{
                refresh : InformationUtil.refreshText,
                add : InformationUtil.addText,
                edit : InformationUtil.editText,
                delete : InformationUtil.removeText,
                save : InformationUtil.saveText,
                cancel : InformationUtil.cancelText,
                search : InformationUtil.searchText,
                printInvoice : InformationUtil.printInvoiceText
            },

            search : {
                text : InformationUtil.searching,
                placeholder : InformationUtil.searchHolder
            },

            dataGrid : {
                loading: false,
                rows : [],
                total: 0,
                start: 0,
                pageNumber: 1,
                pageSize: 10,
                selectionMode : 'single',
                pageLayout: ['list','sep','first','prev','next','last','sep','refresh','info'],

                // filter
                search : null,
                sortField : "",
                sortOrder : ""
            },

            // grid manifest
            dataGridManifest : {
                loading: false,
                rows : [],
                rowsTemp : [],
                total: 0,
            },
            allChecked: false,
            rowClicked: false,

            // form
            title: '',
            icon: '',
            model: PurchaseInvoce,
            disabledPayment : false,
            disabledClosed : false,
            disableBtnSave : false,
            modelNotMapping : {
                status_desc : "",
                sub_total : 0,
                total_vat : 0,
                total_amount : 0,
                created_by : "",
                updated_by : "",
                created_date : "",
                updated_date : "",
                inv_status : ""
            },
            searchStatusData : 0,

            // validation
            rules: {
                invoice_date : 'required',
                from_date : 'required',
                reference : 'required',
                to_date : 'required',
                type : 'required',
                area_type : 'required',
                client_id : 'required',
                taxable : 'required',
                vat : 'required',
                payment_term : 'required',
            },
            errors: {},
            textError :{
                invoice_date : '',
                from_date : '',
                reference : '',
                to_date : '',
                type : '',
                area_type : '',
                client_id : '',
                taxable : '',
                vat : '',
                payment_term : '',
            },

            // combobox
            statusData : [
                { value: 0 , text: 'UNPAID' },
                { value: 1 , text: 'PAID' },
                { value: 'all' , text: 'ALL' },
            ],
            statusTypes : [
                {text: 'ON CALL', value: '1'},
                {text: 'DEDICATED', value: '2'}
            ],
            areaTypes: [
                { value: 1, text: "SALES" },
                { value: 2, text: "BRANCH" }
            ],
            clients : [],
            taxables : [
                { value: 1, text: "PKP" },
                { value: 2, text: "NON PKP" }
            ],
            vehicles : [],
        }
    },
    methods:{
        getError(name) {
            return this.errors[name] && this.errors[name].length
            ? this.errors[name][0]
            : null;
        },
        onAdd(){
            ModelUtil.resetModel(this.textError);
            // method for add data
            this.isDataAdd = true;
            this.isCanPrint = false;
            this.disabledClosed = false;
            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);
            this.model = ModelUtil.resetModel(this.model);
            this.modelNotMapping = ModelUtil.resetModel(this.modelNotMapping);
            this.onCallAllOption();

            // set data default
            this.model.type = 1;
            this.model.area_type = 1;
            this.model.invoice_date = new Date();
            this.model.from_date = new Date();
            this.model.to_date = new Date();
            this.model.payment_date = null;
            this.dataGridManifest.rows = [];
            this.model.vat = 11;

            this.onLoadManifest();

            this.title = this.titleForm.add;
            this.icon = this.iconForm.add;
            this.$refs.dlg.open();
        },
        onEdit(){
            this.isDataAdd = false
            this.isCanPrint = true
            ModelUtil.resetModel(this.textError);
            this.model.payment_date = null;
            // method for get data to edit
            if(this.$refs.dataGrid.selectedRows.length > 0){
                this.title = this.titleForm.edit;
                this.icon = this.iconForm.edit;

                this.disabledClosed = true;

                // additional for combo box
                this.clients = [{value: this.$refs.dataGrid.selectedRows[0].client_id, text: this.$refs.dataGrid.selectedRows[0].client_desc, address : this.$refs.dataGrid.selectedRows[0].client_address}];
                this.vehicles = [{value: this.$refs.dataGrid.selectedRows[0].vehicle_id, text: this.$refs.dataGrid.selectedRows[0].vehicle_desc}]
                
                this.model = ModelUtil.resetModel(this.model);
                this.model = ModelUtil.mapModel(this.model, this.$refs.dataGrid.selectedRows[0])
                this.modelNotMapping = ModelUtil.resetModel(this.modelNotMapping);
                this.modelNotMapping = ModelUtil.mapModel(this.modelNotMapping, this.$refs.dataGrid.selectedRows[0])

                this.model.invoice_date = ( this.model.invoice_date != null && this.model.invoice_date != undefined && this.model.invoice_date != '') ? new Date(this.model.invoice_date) : null;
                this.model.from_date = ( this.model.from_date != null && this.model.from_date != undefined && this.model.from_date != '') ? new Date(this.model.from_date) : null;
                this.model.to_date = ( this.model.to_date != null && this.model.to_date != undefined && this.model.to_date != '') ? new Date(this.model.to_date) : null;
                this.model.payment_date = ( this.model.payment_date != null && this.model.payment_date != undefined && this.model.payment_date != '') ? new Date(this.model.payment_date) : null;

                this.isDataAdd = this.modelNotMapping.inv_status == 1 || this.modelNotMapping.inv_status == "1" ? true : false;
                this.disabledPayment = this.modelNotMapping.inv_status == 1 || this.modelNotMapping.inv_status == "1" ? true : false;
                
                this.model.vat = parseInt(this.model.vat);
                this.model.payment_term = parseInt(this.model.payment_term);

                this.idPrint = this.model.id;

                this.$refs.dlg.open();
            }else{
                this.$refs.notification.centerTop(InformationUtil.selectRow);
            }
        },
        onDelete(){
            if(this.$refs.dataGrid.selectedRows.length > 0){

                this.$messager.confirm({
                    title: 'Confirm',
                    msg: InformationUtil.confirmDelete,
                    result: (r) => {
                        if (r){
                            this.dataGrid.loading = true;
                            TransportService.deleteData("/" + this.urlAction, this.$refs.dataGrid.selectedRows[0].id)
                                .then((response) => {
                                    this.dataGrid.loading = false;
                                    this.$refs.dlg.close();
                                    if(response.code == 200){
                                        this.$refs.notification.success(this.titleMessage + " " + InformationUtil.delete);
                                        this.loadData();
                                    }else{
                                        this.$refs.notification.error(response.message);
                                    }
                                }).catch((err) => {
                                    this.$refs.notification.error(err.message);
                                });
                            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]) 
                        }
                    }
                })
            }else{
                this.$refs.notification.centerTop(InformationUtil.selectRow);
            }
        },
        onSave(){
            ModelUtil.resetModel(this.textError);
            this.model.invoice_date = ( this.model.invoice_date != null && this.model.invoice_date != undefined && this.model.invoice_date != '') ? new Date(this.$moment(this.model.invoice_date).format('YYYY-MM-DD')) : null;
            this.model.from_date = ( this.model.from_date != null && this.model.from_date != undefined && this.model.from_date != '') ? new Date(this.$moment(this.model.from_date).format('YYYY-MM-DD')) : null;
            this.model.to_date = ( this.model.to_date != null && this.model.to_date != undefined && this.model.to_date != '') ? new Date(this.$moment(this.model.to_date).format('YYYY-MM-DD')) : null;
            this.model.payment_date = ( this.model.payment_date != null && this.model.payment_date != undefined && this.model.payment_date != '') ? new Date(this.$moment(this.model.payment_date).format('YYYY-MM-DD')) : null;

            let manifests = [];

            this.$refs.form.validate((valid) => {
                if(!valid){
                    if(this.dataGridManifest.rows != null && this.dataGridManifest.rows.length > 0){
                        this.dataGridManifest.rows.map((item) => {
                            manifests.push(item.manifest_id)
                        });
                    }

                    if(manifests.length == 0){
                        this.$messager.alert({
                            title: "Error",
                            icon: "error",
                            msg: InformationUtil.addInvoiceManifest
                        });
                        return;
                    }
                    this.model.manifests = manifests;

                    this.disableBtnSave = true; 
                    if(this.model.id == null){
                        // action add data
                        TransportService.addData("/" + this.urlAction, this.model)
                            .then((res) => {
                                this.disableBtnSave = false;
                                if(res.code == 200){
                                    this.$refs.notification.success(this.titleMessage + " " + InformationUtil.add);
                                    this.loadData();
                                    this.$refs.dlg.close();
                                } else {
                                    this.$messager.alert({
                                        title: "Error",
                                        icon: "error",
                                        msg: res.message
                                    });
                                }
                            }).catch((error) => {
                                this.disableBtnSave = false;
                                this.$refs.notification.error(error);
                            });

                    }else{
                        // action update data
                        TransportService.updateData("/" + this.urlAction, this.model.id, this.model)
                            .then((res) => {
                                this.disableBtnSave = false;
                                if(res.code == 200){
                                    this.$refs.notification.success(this.titleMessage + " " + InformationUtil.update);
                                    this.loadData();
                                    this.$refs.dlg.close();
                                } else {
                                    this.$messager.alert({
                                        title: "Error",
                                        icon: "error",
                                        msg: res.message
                                    });
                                }
                            }).catch((error) => {
                                this.disableBtnSave = false;
                                this.$refs.notification.error(error);
                            });
                    }
                    this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0])
                } else {
                    for(let key in valid){
                        this.textError[key] = this.getError(key);
                    }
                }
            });
            
        },
        
        // handle data grid
        async onSelectionStatusData(){
            this.dataGrid.pageNumber = 1;
            await this.loadData();
            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);
            if(this.filter.viewStatusMessage){
                this.$refs.notification.success(InformationUtil.refresh);
            }
            this.filter.viewStatusMessage = true;
        },
        onRowDblClick(){
            if(this.action.edit){
                this.onEdit();
            }
        },
        async onRefresh(){
            this.dataGrid.pageNumber = 1;
            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);
            await this.loadData();
            this.$refs.notification.success(InformationUtil.refresh);
        },
        async onSearch(){
            await this.loadData();
            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);
            this.$refs.notification.success(InformationUtil.filter);
        },
        onSortChange(event){
            if(event.length > 0){
                this.dataGrid.pageNumber = 1;
                this.dataGrid.sortField = event[0].field;
                this.dataGrid.sortOrder = event[0].order;
                this.loadData();
            }
        },
        onPageChange(event) {
            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);
            this.dataGrid.pageNumber = event.pageNumber;
            this.dataGrid.pageSize = event.pageSize;
            if(event.refresh) this.dataGrid.pageNumber = 1;
            this.loadData();
            if(event.refresh){
                this.$refs.notification.success(InformationUtil.refresh);
            }
        },

        async loadData(){
            this.dataGrid.loading = true;
            let param = {
                statusData : this.searchStatusData,
            }
            return TransportService.getData("/" + this.urlAction, this.dataGrid, param)
                .then((response) => {
                    if(response.data !== "" && response.data !== null){
                        let data = response.data; 
                        this.dataGrid.total = data.total;
                        this.dataGrid.rows = data.rows;
                    }
                    this.dataGrid.loading = false;
                });
        },

        // data grid manifest
        onCheckedChange() {
            this.rowClicked = true;
            this.$nextTick(() => (this.rowClicked = false));
        },
        async onRefreshManifest(){
            await this.onLoadManifest();
        },
        onDeleteManifest(){
            let rowSelected = this.dataGridManifest.rows.filter(row => row.checked);
            let result = [];
            this.dataGridManifest.rows.map((val) => {
                if(rowSelected.length > 0){
                    rowSelected.map((valSelected) => {
                        if(valSelected.manifest_id != val.manifest_id){
                            result.push(val);
                        }
                    })
                } else {
                    result.push(val);
                }
            })
            
            this.dataGridManifest.rows = result;
        },

        onLoadManifest(){
            this.model.invoice_date = ( this.model.invoice_date != null && this.model.invoice_date != undefined && this.model.invoice_date != '') ? new Date(this.$moment(this.model.invoice_date).format('YYYY-MM-DD')) : null;
            this.model.from_date = ( this.model.from_date != null && this.model.from_date != undefined && this.model.from_date != '') ? new Date(this.$moment(this.model.from_date).format('YYYY-MM-DD')) : null;
            this.model.to_date = ( this.model.to_date != null && this.model.to_date != undefined && this.model.to_date != '') ? new Date(this.$moment(this.model.to_date).format('YYYY-MM-DD')) : null;
            this.model.payment_date = ( this.model.payment_date != null && this.model.payment_date != undefined && this.model.payment_date != '') ? new Date(this.$moment(this.model.payment_date).format('YYYY-MM-DD')) : null;
            this.dataGridManifest.loading = true;
            this.model.invoice_type = "sales";
            return TransportService.post("/manifest/manifestInvoice", this.model)
                .then((response) => {
                    if(response.data !== "" && response.data !== null){
                        let data = response.data; 
                        this.dataGridManifest.total = data.total;
                        this.dataGridManifest.rows = data.rows;
                    }
                    this.dataGridManifest.loading = false;
                });
        },

        onPrint(){
            this.idPrint = this.model.id;
            this.$refs.printInvoice.print();
        },

        onChangeClient(event){
            if(event.payment_term != undefined && event.payment_term != null && event.payment_term != ''){
                this.model.payment_term = parseInt(event.payment_term);
            }
        },

        onChangeTax(event){
            if(event.value == 2){
                // No Tax
                this.disableVat = true;
                this.model.vat = 0;
            } else {
                this.model.vat = 11;
                this.disableVat = false;
            }
        },

        // combo box data
        async optionClient(event){
            let search = event == null ? "" : event.filterValue;
            return await OptionService.getOption("/client", search)
                .then((response) => {
                    this.clients = response.data;
                }).catch((error) => {
                    this.$refs.notification.error(error);
                });
        },
        async optionVehicle(event){
            let search = event == null ? "" : event.filterValue;
            return await OptionService.getOption("/vehicle", search)
                .then((response) => {
                    this.vehicles = response.data;
                }).catch((error) => {
                    this.$refs.notification.error(error);
                });
        },
        onCallAllOption(){
            this.optionClient();
            this.optionVehicle();
        }
    },
    async mounted(){
        this.action = AccessUtil.getAccessAction('trucking_order');
        await this.loadData();
        this.$refs.notification.success(InformationUtil.loadGrid + " " + this.dataGrid.total + " " + InformationUtil.successLoadGrid);
    },
    created(){
        GlobalUtil.removeWindow();
    }
};
</script>