<template>
    <div>
        <Panel :title="titleView"  style="margin:0px 1px; ">
            <div class="datagrid-toolbar">
                <LinkButton :plain="true" :iconCls="iconUtil.iconRefresh" @click="onRefresh()">{{ btnText.refresh }}</LinkButton>
                <LinkButton v-if="action.add" :plain="true" :iconCls="iconUtil.iconAdd" @click="onAdd()">{{ btnText.add }}</LinkButton>
                <LinkButton v-if="action.edit" :plain="true" :iconCls="iconUtil.iconEdit" @click="onEdit()">{{ btnText.edit }}</LinkButton>
                <LinkButton v-if="action.delete" :plain="true" :iconCls="iconUtil.iconDelete" @click="onDelete()">{{ btnText.delete }}</LinkButton>
                <LinkButton v-if="action.add" :plain="true" :iconCls="iconUtil.iconUpload" @click="onImport()">{{ btnText.import }}</LinkButton>
                <span class="button-sep"></span> {{ search.text }} : 
                <SearchBox style="width:300px"
                    :placeholder="search.placeholder" 
                    v-model="dataGrid.search"
                    @search="onSearch()">
                    <Addon>
                        <span v-if="dataGrid.search" class="textbox-icon icon-clear" title="Clear value" @click="dataGrid.search=null;onSearch();"></span>
                    </Addon>
                </SearchBox>
                <LinkButton :plain="true" :iconCls="iconUtil.iconMultiSearch" @click="onMultiSearch()">{{ informationUtil.multiSearchText }}</LinkButton>
            </div>
            <DataGrid
                    ref="dataGrid"
                    :columnResizing="true"
                    :border="false"
                    :lazy="true"
                    :loading="dataGrid.loading"                                    
                    :data="dataGrid.rows"
                    :pagination="true"
                    :selectionMode="dataGrid.selectionMode"
                    :total="dataGrid.total"
                    :pageSize="dataGrid.pageSize"
                    :pageNumber="dataGrid.pageNumber"
                    :pageLayout="dataGrid.pageLayout" 
                    @pageChange="onPageChange($event)"
                    @sortChange="onSortChange($event)"
                    @rowDblClick="onRowDblClick()"
                    >
                
                    <GridColumn align="center" cellCss="datagrid-td-rownumber" width="30">
                    <template slot="body" slot-scope="scope">
                        {{scope.rowIndex + 1}}
                    </template>
                </GridColumn>
                <GridColumn field="client_desc" title="Client Id" :sortable="true"></GridColumn>
                <GridColumn field="type_desc" title="Vehicle Type" :sortable="true"></GridColumn>
                <GridColumn field="status_desc" title="Vehicle Status" :sortable="true"></GridColumn>
                <GridColumn field="origin_desc" title="Origin" :sortable="true"></GridColumn>
                <GridColumn field="destination_desc" title="Destination" :sortable="true"></GridColumn>
                <GridColumn field="currency" title="Currency" :sortable="true"></GridColumn>
                <GridColumn field="vehicle_rate_desc" title="Vehicle Rate" :sortable="true" align="right"></GridColumn>
                <GridColumn field="rate_status_desc" title="Status" :sortable="true"></GridColumn>
            </DataGrid>
        </Panel>

        <!-- Multi Search -->
        <Dialog ref="dlgSearch" :iconCls="iconUtil.iconMultiSearch" bodyCls="f-column" :title="informationUtil.multiSearchText" :draggable="true" :modal="true" closed :dialogStyle="{width:'320px', height:'auto'}">
            <div class="f-full" style="overflow:auto">
                <table width="100%" cellspacing="1" cellpadding="5" border="0">
                    <tbody>
                        <tr>
                            <td style="width : 30%" class="bg_subtitle">
                                Client ID
                            </td>
                            <td class="bg_grid2"> 
                                <ComboBox  :data="clients" inputId="client_id" name="client_id" class="f-field-text" v-model="modelMultiSearch.client_id">
                                    <template slot="item" slot-scope="scope">
                                        <span style="font-weight:bold">{{scope.row.text}}</span><br/>
                                        <span style="color:#888">{{scope.row.address}}</span><br/>
                                    </template>
                                    <Addon>
                                        <span v-if="modelMultiSearch.client_id" class="textbox-icon icon-clear" title="Clear value" @click="modelMultiSearch.client_id=null;"></span>
                                    </Addon>
                                </ComboBox>
                            </td>
                        </tr>
                        <tr>
                            <td class="bg_subtitle">Vehicle Type</td>
                            <td class="bg_grid2">
                                <ComboBox  :data="vehicleTypes" inputId="type_id" name="type_id" class="f-field-text" v-model="modelMultiSearch.type_id">
                                    <template slot="item" slot-scope="scope">
                                        <span style="font-weight:bold">{{scope.row.text}}</span><br/>
                                        <span style="color:#888">Weight : {{scope.row.weight_cap}}  Volume : {{scope.row.volume_cap}}</span><br/>
                                    </template>
                                    <Addon>
                                        <span v-if="modelMultiSearch.type_id" class="textbox-icon icon-clear" title="Clear value" @click="modelMultiSearch.type_id=null;"></span>
                                    </Addon>
                                </ComboBox>
                            </td>
                        </tr>
                        <tr>
                            <td class="bg_subtitle">Status</td>
                            <td class="bg_grid2">
                                <ComboBox  :data="statusTypes" inputId="status" name="status" class="f-field-text" v-model="modelMultiSearch.status">
                                    <Addon>
                                        <span v-if="modelMultiSearch.status" class="textbox-icon icon-clear" title="Clear value" @click="modelMultiSearch.status=null;"></span>
                                    </Addon>
                                </ComboBox>
                            </td>
                        </tr>
                        <tr>
                            <td class="bg_subtitle">Origin</td>
                            <td class="bg_grid2">
                                <ComboBox  :data="origins" inputId="origin_id" name="origin_id" class="f-field-text" v-model="modelMultiSearch.origin_id">
                                    <Addon>
                                        <span v-if="modelMultiSearch.origin_id" class="textbox-icon icon-clear" title="Clear value" @click="modelMultiSearch.origin_id=null;"></span>
                                    </Addon>
                                </ComboBox>
                            </td>
                        </tr>
                        <tr>
                            <td class="bg_subtitle">Destination</td>
                            <td class="bg_grid2">
                                <ComboBox  :data="destinations" inputId="destination_id" name="destination_id" class="f-field-text" v-model="modelMultiSearch.destination_id">
                                    <Addon>
                                        <span v-if="modelMultiSearch.destination_id" class="textbox-icon icon-clear" title="Clear value" @click="modelMultiSearch.destination_id=null;"></span>
                                    </Addon>
                                </ComboBox>
                            </td>
                        </tr>
                        <tr>
                            <td class="bg_subtitle">
                                Rate Status
                            </td>
                            <td class="bg_grid2">
                                <ComboBox  :data="rateStatus" inputId="rate_status" name="rate_status" class="f-field-text" v-model="modelMultiSearch.rate_status">
                                    <Addon>
                                        <span v-if="modelMultiSearch.rate_status" class="textbox-icon icon-clear" title="Clear value" @click="modelMultiSearch.rate_status=null;"></span>
                                    </Addon>
                                </ComboBox>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="dialog-button">
                <LinkButton :iconCls="iconUtil.iconSearch" @click="onSearchMulti()" style="width:89px">{{ informationUtil.searchText }}</LinkButton>
                <LinkButton :iconCls="iconUtil.iconCancel" @click="onResetSearchMulti()" style="width:89px">{{ informationUtil.resetText }}</LinkButton>
            </div>
        </Dialog>

        <!-- Form -->
        <Dialog ref="dlg" :iconCls="icon" bodyCls="f-column" :title="title" :draggable="true" :modal="true" closed :dialogStyle="{width:'786px', height:'420px'}">
            <div class="f-full" style="overflow:auto">
                <Form ref="form" :model="model" :rules="rules" @validate="errors=$event">
                    <table width="100%" cellspacing="1" cellpadding="5" border="0">
                        <tbody>
                            <tr>
                                <td class="bg_subtitle">
                                    Rate Status <font color="red">*</font>
                                </td>
                                <td class="bg_grid2">
                                    <ComboBox  :data="rateStatus" inputId="rate_status" name="rate_status" class="f-field-text text-40" v-model="model.rate_status"></ComboBox>
                                    <div class="error">{{textError.rate_status}}</div>
                                </td>
                            </tr>
                            <tr>
                                <td class="bg_subtitle">
                                    Client ID <font color="red">*</font>
                                </td>
                                <td class="bg_grid2"> 
                                    <ComboBox  :data="clients" inputId="client_id" name="client_id" @filterChange="optionClient($event)" class="f-field-text" v-model="model.client_id" :disabled="disabled">
                                        <template slot="item" slot-scope="scope">
                                            <span style="font-weight:bold">{{scope.row.text}}</span><br/>
                                            <span style="color:#888">{{scope.row.address}}</span><br/>
                                        </template>
                                    </ComboBox>
                                    <div class="error">{{textError.client_id}}</div>
                                </td>
                                <td class="bg_subtitle">Vehicle Type <font color="red">*</font></td>
                                <td class="bg_grid2">
                                    <ComboBox  :data="vehicleTypes" inputId="type_id" name="type_id" @filterChange="optionVehicleType($event)" class="f-field-text" v-model="model.type_id" :disabled="disabled">
                                        <template slot="item" slot-scope="scope">
                                            <span style="font-weight:bold">{{scope.row.text}}</span><br/>
                                            <span style="color:#888">Weight : {{scope.row.weight_cap}}  Volume : {{scope.row.volume_cap}}</span><br/>
                                        </template>
                                    </ComboBox>
                                    <div class="error">{{textError.type_id}}</div>
                                </td>
                            </tr>
                            <tr>
                                <td class="bg_subtitle">Origin <font color="red">*</font></td>
                                <td class="bg_grid2">
                                    <ComboBox  :data="origins" inputId="origin_id" name="origin_id" @filterChange="optionOrigin($event)" class="f-field-text" v-model="model.origin_id" :disabled="disabled"></ComboBox>
                                    <div class="error">{{textError.origin_id}}</div>
                                </td>
                                <td class="bg_subtitle">Status <font color="red">*</font></td>
                                <td class="bg_grid2">
                                    <ComboBox  :data="statusTypes" @selectionChange="onSelectStatus($event)" inputId="status" name="status" class="f-field-text" v-model="model.status" :disabled="disabled"></ComboBox>
                                    <div class="error">{{textError.status}}</div>
                                </td>
                            </tr>
                            <tr>
                                <td class="bg_subtitle">Destination <font color="red">*</font></td>
                                <td class="bg_grid2">
                                    <ComboBox  :data="destinations" inputId="destination_id" name="destination_id" @filterChange="optionDestination($event)" class="f-field-text" v-model="model.destination_id" :disabled="disabled"></ComboBox>
                                    <div class="error">{{textError.destination_id}}</div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <Tabs>
                        <TabPanel :title="'Basic'">
                            <table width="100%" cellspacing="1" cellpadding="5" border="0">
                                <tbody>
                                    <tr>
                                        <td class="bg_subtitle">
                                            Currency
                                        </td>
                                        <td class="bg_grid2">
                                            <TextBox inputId="currency" name="currency" class="f-field-text text-50" v-model="model.currency" :disabled="true"></TextBox>
                                        </td>
                                        <td class="bg_subtitle">Rate Type <font color="red">*</font></td>
                                        <td class="bg_grid2">
                                            <ComboBox  :data="rateTypes" inputId="rate_type" name="rate_type" class="f-field-text" v-model="model.rate_type"></ComboBox>
                                            <div class="error">{{textError.rate_type}}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="bg_subtitle">Vehicle Rate <font color="red">*</font></td>
                                        <td class="bg_grid2">
                                            <NumberBox  inputId="vehicle_rate" name="vehicle_rate" class="f-field-text text-right" v-model="model.vehicle_rate" :spinners="false"></NumberBox>
                                            <div class="error">{{textError.vehicle_rate}}</div>
                                        </td>
                                        <td class="bg_subtitle">Unloading Cost</td>
                                        <td class="bg_grid2">
                                            <NumberBox  inputId="unloading_cost" name="unloading_cost" class="f-field-text text-right" v-model="model.unloading_cost" :spinners="false"></NumberBox>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </TabPanel>
                        <TabPanel :title="'Expense'" :disabled="disableTabExpense">
                            <fieldset class="c-fieldset">
                                <legend class="c-legend"><b>Shipment</b></legend>
                                <table width="100%" cellspacing="1" cellpadding="5" border="0">
                                    <tbody>
                                        <td>
                                            Fixed Trip
                                        </td>
                                        <td> 
                                            <NumberBox inputId="start_shipment" name="start_shipment" class="f-field-text" v-model="model.start_shipment" :spinners="false"></NumberBox>
                                        </td>
                                    </tbody>
                                </table>
                            </fieldset>
                            
                            <fieldset class="c-fieldset">
                                <legend class="c-legend"><b>Drop Charge</b></legend>
                                <fieldset class="c-fieldset">
                                    <legend class="c-legend"><b>In The City</b></legend>
                                    <table width="100%" cellspacing="1" cellpadding="5" border="0">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    Start Charge
                                                </td>
                                                <td> 
                                                    <NumberBox inputId="start_drop_charge_in_city" name="start_drop_charge_in_city" class="f-field-text" v-model="model.start_drop_charge_in_city" :spinners="false"></NumberBox>
                                                </td>
                                                <td>
                                                    Rate
                                                </td>
                                                <td> 
                                                    <NumberBox inputId="rate_drop_charge_in_city" name="rate_drop_charge_in_city" class="f-field-text" v-model="model.rate_drop_charge_in_city" :spinners="false"></NumberBox>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </fieldset>
                                <fieldset class="c-fieldset">
                                    <legend class="c-legend"><b>Out The City</b></legend>
                                    <table width="100%" cellspacing="1" cellpadding="5" border="0">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    Start Charge
                                                </td>
                                                <td> 
                                                    <NumberBox inputId="start_drop_charge_out_city" name="start_drop_charge_out_city" class="f-field-text" v-model="model.start_drop_charge_out_city" :spinners="false"></NumberBox>
                                                </td>
                                                <td>
                                                    Rate
                                                </td>
                                                <td> 
                                                    <NumberBox inputId="rate_drop_charge_out_city" name="rate_drop_charge_out_city" class="f-field-text" v-model="model.rate_drop_charge_out_city" :spinners="false"></NumberBox>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </fieldset>
                            </fieldset>
                            <fieldset class="c-fieldset">
                                <legend class="c-legend"><b>Different Area</b></legend>
                                <fieldset class="c-fieldset">
                                    <legend class="c-legend"><b>In The City</b></legend>
                                    <table width="100%" cellspacing="1" cellpadding="5" border="0">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    Start Charge
                                                </td>
                                                <td> 
                                                    <NumberBox inputId="start_diff_area_in_city" name="start_diff_area_in_city" class="f-field-text" v-model="model.start_diff_area_in_city" :spinners="false"></NumberBox>
                                                </td>
                                                <td>
                                                    Rate
                                                </td>
                                                <td> 
                                                    <NumberBox inputId="rate_diff_area_in_city" name="rate_diff_area_in_city" class="f-field-text" v-model="model.rate_diff_area_in_city" :spinners="false"></NumberBox>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </fieldset>
                                <fieldset class="c-fieldset">
                                    <legend class="c-legend"><b>Out The City</b></legend>
                                    <table width="100%" cellspacing="1" cellpadding="5" border="0">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    Start Charge
                                                </td>
                                                <td> 
                                                    <NumberBox inputId="start_diff_area_out_city" name="start_diff_area_out_city" class="f-field-text" v-model="model.start_diff_area_out_city" :spinners="false"></NumberBox>
                                                </td>
                                                <td>
                                                    Rate
                                                </td>
                                                <td> 
                                                    <NumberBox inputId="rate_diff_area_out_city" name="rate_diff_area_out_city" class="f-field-text" v-model="model.rate_diff_area_out_city" :spinners="false"></NumberBox>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </fieldset>
                            </fieldset>
                        </TabPanel>
                        <TabPanel :title="'Expense Area'" :disabled="disableTabExpense">
                            <fieldset class="c-fieldset">
                                <legend class="c-legend"><b>In Default</b></legend>
                                <table width="100%" cellspacing="0" cellpadding="2" border="0">
                                    <tbody>
                                        <tr>
                                            <td style="width:50%;" align="left">
                                                Area :
                                                <ComboBox  :data="areas" @filterChange="optionArea($event)" @selectionChange="onSelectAreaIn($event)"  inputId="area_id" name="area_id" class="f-field-text" v-model="modelInputAreaIn.area_id"></ComboBox>
                                            </td>
                                            <td style="width:30%;padding-left: 10px;" align="left">
                                                Rate :
                                                <NumberBox inputId="rate" name="rate" class="f-field-text" :spinners="false" v-model="modelInputAreaIn.rate"></NumberBox>
                                            </td>
                                            <td style="width:5%;padding-left: 10px;" align="left">
                                                &nbsp;&nbsp;
                                                <LinkButton :iconCls="iconUtil.iconAdd" style="border:none;" @click="addAreaIn()"></LinkButton>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <Panel>
                                    <div class="datagrid-toolbar" style="margin-bottom: 10px;">
                                        {{ search.text }} : 
                                        <SearchBox style="width:300px"
                                            :placeholder="search.placeholder" 
                                            v-model="dataGridAreaIn.search"
                                            @search="onSearchAreaIn()">
                                            <Addon>
                                                <span v-if="dataGridAreaIn.search" class="textbox-icon icon-clear" title="Clear value" @click="dataGridAreaIn.search=null;onSearchAreaIn();"></span>
                                            </Addon>
                                        </SearchBox>
                                        <LinkButton v-if="action.add || action.edit" :plain="true" :iconCls="iconUtil.iconDelete" @click="onDeleteAreaIn()">{{ btnText.delete }}</LinkButton>
                                    </div>

                                    <DataGrid
                                        ref="dataGridAreaIn"
                                        :columnResizing="true"
                                        :border="false"
                                        :loading="dataGridAreaIn.loading"
                                        :data="dataGridAreaIn.rows"
                                        :pagination="true"
                                        :selectionMode="dataGridAreaIn.selectionMode"
                                        :total="dataGridAreaIn.total"
                                        :pageSize="dataGridAreaIn.pageSize"
                                        :pageNumber="dataGridAreaIn.pageNumber"
                                        :pageLayout="dataGridAreaIn.pageLayout"
                                        style="width: 694px;height:190px">
                                        <GridColumn field="area_desc" title="Area" width="150"></GridColumn>
                                        <GridColumn field="rate" title="Rate" width="80" align="right"></GridColumn>
                                    </DataGrid>
                                </Panel>
                            </fieldset>
                            <fieldset class="c-fieldset">
                                <legend class="c-legend"><b>After Default</b></legend>
                                <table width="100%" cellspacing="0" cellpadding="2" border="0">
                                    <tbody>
                                        <tr>
                                            <td style="width:50%;" align="left">
                                                Area :
                                                <ComboBox  :data="areas" @filterChange="optionArea($event)" @selectionChange="onSelectAreaOut($event)"  inputId="area_id" name="area_id" class="f-field-text" v-model="modelInputAreaOut.area_id"></ComboBox>
                                            </td>
                                            <td style="width:30%;padding-left: 10px;" align="left">
                                                Rate :
                                                <NumberBox inputId="rate" name="rate" class="f-field-text" :spinners="false" v-model="modelInputAreaOut.rate"></NumberBox>
                                            </td>
                                            <td style="width:5%;padding-left: 10px;" align="left">
                                                &nbsp;&nbsp;
                                                <LinkButton :iconCls="iconUtil.iconAdd" style="border:none;" @click="addAreaOut()"></LinkButton>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <Panel>
                                    <div class="datagrid-toolbar" style="margin-bottom: 10px;">
                                        {{ search.text }} : 
                                        <SearchBox style="width:300px"
                                            :placeholder="search.placeholder" 
                                            v-model="dataGridAreaOut.search"
                                            @search="onSearchAreaOut()">
                                            <Addon>
                                                <span v-if="dataGridAreaOut.search" class="textbox-icon icon-clear" title="Clear value" @click="dataGridAreaOut.search=null;onSearchAreaOut();"></span>
                                            </Addon>
                                        </SearchBox>
                                        <LinkButton v-if="action.add || action.edit" :plain="true" :iconCls="iconUtil.iconDelete" @click="onDeleteAreaOut()">{{ btnText.delete }}</LinkButton>
                                    </div>

                                    <DataGrid
                                        ref="dataGridAreaOut"
                                        :columnResizing="true"
                                        :border="false"
                                        :loading="dataGridAreaOut.loading"
                                        :data="dataGridAreaOut.rows"
                                        :pagination="true"
                                        :selectionMode="dataGridAreaOut.selectionMode"
                                        :total="dataGridAreaOut.total"
                                        :pageSize="dataGridAreaOut.pageSize"
                                        :pageNumber="dataGridAreaOut.pageNumber"
                                        :pageLayout="dataGridAreaOut.pageLayout"
                                        style="width: 694px;height:190px">
                                        <GridColumn field="area_desc" title="Area" width="150"></GridColumn>
                                        <GridColumn field="rate" title="Rate" width="80" align="right"></GridColumn>
                                    </DataGrid>
                                </Panel>
                            </fieldset>
                        </TabPanel>
                        <TabPanel :title="'Notes'">
                            <table width="100%" cellspacing="1" cellpadding="5" border="0">
                                <tbody>
                                    <tr>
                                        <td class="bg_subtitle">
                                            Remark
                                        </td>
                                        <td class="bg_grid2">
                                            <TextBox inputId="remark" name="remark" :multiline="true" class="f-field-text f-field-text-area" v-model="model.remark"></TextBox>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </TabPanel>
                        <TabPanel :title="'Log'">
                            <table width="100%" cellspacing="1" cellpadding="5" border="0">
                                <tbody>
                                    <tr>
                                        <td class="bg_subtitle">
                                            Created By
                                        </td>
                                        <td class="bg_grid2">
                                            <TextBox inputId="created_by" name="created_by" class="f-field-text" :value="modelNotMapping.created_by" :disabled="true"></TextBox>
                                        </td>
                                        <td class="bg_subtitle">
                                            Created Date
                                        </td>
                                        <td class="bg_grid2">
                                            <TextBox inputId="created_date" name="created_date" class="f-field-text" :value="modelNotMapping.created_date" :disabled="true"></TextBox>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="bg_subtitle">
                                            Updated By
                                        </td>
                                        <td class="bg_grid2">
                                            <TextBox inputId="updated_by" name="updated_by" class="f-field-text" :value="modelNotMapping.updated_by" :disabled="true"></TextBox>
                                        </td>
                                        <td class="bg_subtitle">
                                            updated Date
                                        </td>
                                        <td class="bg_grid2">
                                            <TextBox inputId="updated_date" name="updated_date" class="f-field-text" :value="modelNotMapping.updated_date" :disabled="true"></TextBox>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </TabPanel>
                    </Tabs>
                </Form>
            </div>
            <div class="dialog-button">
                <LinkButton :iconCls="iconUtil.iconSave" v-if="action.add || action.edit" @click="onSave()" :disabled="disableBtnSave" style="width:89px">{{ btnText.save }}</LinkButton>
                <LinkButton :iconCls="iconUtil.iconCancel" @click="$refs.dlg.close()" style="width:89px">{{ btnText.cancel }}</LinkButton>
            </div>
        </Dialog>

        <Dialog ref="dlgImport" :iconCls="iconUtil.iconUpload" bodyCls="f-column" :title="informationUtil.importText" :draggable="true" :modal="true" closed :dialogStyle="{width:'480px', height:'auto'}">
            <div class="f-full" style="overflow:auto">
                <Form ref="formFile">
                    <table width="100%" cellspacing="1" cellpadding="5" border="0">
                        <tbody>
                            <tr>
                                <td> 
                                    <FileButton style="width:100px" @select="onSelectedFile($event)">Choose File</FileButton> &nbsp;&nbsp;{{fileName}}
                                </td>
                                <td> 
                                    <LinkButton :plain="true" :iconCls="iconUtil.iconDownload" @click="onDownloadFile()">{{ btnText.downloadTemplate }}</LinkButton>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <b>(Total Max Upload file size 5MB, allowed file type .xls)</b>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Form>
            </div>
            <div class="dialog-button">
                <LinkButton :iconCls="iconUtil.iconSave" @click="onSaveImport()" style="width:89px">{{ informationUtil.saveText }}</LinkButton>
                <LinkButton :iconCls="iconUtil.iconCancel" @click="onCancelImport()" style="width:89px">{{ informationUtil.cancelText }}</LinkButton>
            </div>
        </Dialog>

        <NotificationComponent ref="notification"></NotificationComponent>
    </div>
    
</template>

<script>
import NotificationComponent from '@/components/NotificationComponent.vue';
import ClientRate from '@/models/ClientRate';
import MasterService from '@/services/MasterService';
import ModelUtil from '@/utils/ModelUtil';
import InformationUtil from '@/utils/InformationUtil';
import OptionService from '@/services/OptionService';
import AccessUtil from '@/utils/AccessUtil';
import IconUtil from '@/utils/IconUtil';
import GlobalUtil from '@/utils/GlobalUtil';

export default {
    name: 'ClientRateView',
    components : {
        NotificationComponent
    },
    data() {
        return {
            titleView : InformationUtil.clientRate + "s",
            titleMessage : InformationUtil.clientRate,
            iconUtil : IconUtil,
            informationUtil : InformationUtil,

            titleForm :{
                add : InformationUtil.addText,
                edit : InformationUtil.editText
            },

            iconForm :{
                add : IconUtil.iconAdd,
                edit : IconUtil.iconEdit
            },

            action : {
                view : false,
                add : false,
                edit : false,
                delete : false,
            },

            btnText :{
                refresh : InformationUtil.refreshText,
                add : InformationUtil.addText,
                edit : InformationUtil.editText,
                delete : InformationUtil.removeText,
                save : InformationUtil.saveText,
                cancel : InformationUtil.cancelText,
                search : InformationUtil.searchText,
                import : InformationUtil.importText,
                upload : InformationUtil.uploadText,
                downloadTemplate : InformationUtil.downloadTemplateText
            },

            search : {
                text : InformationUtil.searching,
                placeholder : InformationUtil.searchHolder
            },

            dataGrid : {
                loading: false,
                rows : [],
                total: 0,
                start: 0,
                pageNumber: 1,
                pageSize: 10,
                selectionMode : 'single',
                pageLayout: ['list','sep','first','prev','next','last','sep','refresh','info'],

                // filter
                search : "",
                sortField : "",
                sortOrder : ""
            },

            // grid area in
            dataGridAreaIn : {
                loading: false,
                rows : [],
                rowsTemp : [],
                total: 0,
                start: 0,
                pageNumber: 1,
                pageSize: 5,
                selectionMode : 'single',
                pageLayout: ['first','prev','next','last','sep','refresh','info'],

                // filter
                search : null,
                sortField : "",
                sortOrder : "",
            },

            dataGridAreaOut : {
                loading: false,
                rows : [],
                rowsTemp : [],
                total: 0,
                start: 0,
                pageNumber: 1,
                pageSize: 5,
                selectionMode : 'single',
                pageLayout: ['first','prev','next','last','sep','refresh','info'],

                // filter
                search : null,
                sortField : "",
                sortOrder : "",
            },

            // form
            title: '',
            icon: '',
            model: ClientRate,
            disabled : false,
            disableBtnSave : false,
            disableTabExpense : true,
            
            modelMultiSearch : {
                client_id : "",
                type_id : "",
                status : "",
                origin_id : "",
                destination_id : "",
                rate_status : ""
            },

            modelInputAreaIn : {
                area_id : "",
                area_desc : "",
                rate : 0,
            },

            modelInputAreaOut : {
                area_id : "",
                area_desc : "",
                rate : 0,
            },

            // validation
            rules: {
                rate_status: 'required',
                client_id: 'required',
                origin_id: 'required',
                destination_id: 'required',
                type_id: 'required',
                status: 'required',
                rate_type: 'required',
                vehicle_rate: 'required',
            },
            errors: {},
            textError :{
                rate_status: "",
                client_id: "",
                origin_id: "",
                destination_id: "",
                type_id: "",
                status: "",
                rate_type: "",
                vehicle_rate: "",
            },

            modelNotMapping : {
                created_by : "",
                updated_by : "",
                created_date : "",
                updated_date : ""
            },

            // file
            file : '',
            fileName : '',

            // combobox
            rateStatus : [
                {text: 'ACTIVE', value: '1'},
                {text: 'OFF', value: '2'}
            ],
            statusTypes : [
                {text: 'ON CALL', value: '1'},
                {text: 'DEDICATED', value: '2'}
            ],
            rateTypes : [
                {text: 'REGULAR', value: '1'},
                {text: 'WEIGHT', value: '2'}
            ],
            clients : [],
            origins : [],
            destinations : [],
            vehicleTypes : [],
            areas : [],
        }
    },
    methods:{
        getError(name) {
            return this.errors[name] && this.errors[name].length
            ? this.errors[name][0]
            : null;
        },
        onAdd(){
            ModelUtil.resetModel(this.textError);
            // method for add data
            this.disabled = false;
            this.title = this.titleForm.add;
            this.icon = this.iconForm.add;
            this.onCallAllOption();

            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);
            this.model = ModelUtil.resetModel(this.model);
            this.modelNotMapping = ModelUtil.resetModel(this.modelNotMapping);

            this.modelInputAreaIn = ModelUtil.resetModelByType(this.modelInputAreaIn);
            this.modelInputAreaIn.component_id = null;
            this.dataGridAreaIn.rows = [];
            this.dataGridAreaIn.rowsTemp = this.dataGridAreaIn.rows;

            this.modelInputAreaOut = ModelUtil.resetModelByType(this.modelInputAreaOut);
            this.modelInputAreaOut.component_id = null;
            this.dataGridAreaOut.rows = [];
            this.dataGridAreaOut.rowsTemp = this.dataGridAreaOut.rows;

            // set default
            this.model.rate_status = 1;
            this.model.rate_type = 1;
            this.model.status = 1;
            this.model.currency = "IDR";
            this.model.vehicle_rate = parseInt(this.model.vehicle_rate);
            this.model.unloading_cost = parseInt(this.model.unloading_cost);
            this.model.start_shipment = parseInt(this.model.start_shipment);
            this.model.start_drop_charge_in_city = parseInt(this.model.start_drop_charge_in_city);
            this.model.rate_drop_charge_in_city = parseInt(this.model.rate_drop_charge_in_city);
            this.model.start_drop_charge_out_city = parseInt(this.model.start_drop_charge_out_city);
            this.model.rate_drop_charge_out_city = parseInt(this.model.rate_drop_charge_out_city);
            this.model.start_diff_area_in_city = parseInt(this.model.start_diff_area_in_city);
            this.model.rate_diff_area_in_city = parseInt(this.model.rate_diff_area_in_city);
            this.model.start_diff_area_out_city = parseInt(this.model.start_diff_area_out_city);
            this.model.rate_diff_area_out_city = parseInt(this.model.rate_diff_area_out_city);
            this.$refs.dlg.open();
        },
        onEdit(){
            ModelUtil.resetModel(this.textError);
            // method for get data to edit
            if(this.$refs.dataGrid.selectedRows.length > 0){
                this.disabled = true;
                this.title = this.titleForm.edit;
                this.icon = this.iconForm.edit;
                this.onCallAllOption();

                // additional for combo box
                this.clients = [{value: this.$refs.dataGrid.selectedRows[0].client_id, text: this.$refs.dataGrid.selectedRows[0].client_desc}]
                this.origins = [{value: this.$refs.dataGrid.selectedRows[0].origin_id, text: this.$refs.dataGrid.selectedRows[0].origin_desc +" - "+this.$refs.dataGrid.selectedRows[0].origin_description}]
                this.destinations = [{value: this.$refs.dataGrid.selectedRows[0].destination_id, text: this.$refs.dataGrid.selectedRows[0].destination_desc +" - "+this.$refs.dataGrid.selectedRows[0].destination_description}]
                this.vehicleTypes = [{value: this.$refs.dataGrid.selectedRows[0].type_id, text: this.$refs.dataGrid.selectedRows[0].type_desc}]

                this.model = ModelUtil.resetModel(this.model);
                this.model = ModelUtil.mapModel(this.model, this.$refs.dataGrid.selectedRows[0])
                this.modelNotMapping = ModelUtil.resetModel(this.modelNotMapping);
                this.modelNotMapping = ModelUtil.mapModel(this.modelNotMapping, this.$refs.dataGrid.selectedRows[0])

                this.modelNotMapping = ModelUtil.resetModel(this.modelNotMapping);
                this.modelInputAreaIn = ModelUtil.resetModelByType(this.modelInputAreaIn);
                this.modelInputAreaIn.component_id = null;
                this.dataGridAreaIn.rows = [];
                this.dataGridAreaIn.rows = this.$refs.dataGrid.selectedRows[0].area_in;
                this.dataGridAreaIn.rowsTemp = this.dataGridAreaIn.rows;
                this.modelInputAreaOut = ModelUtil.resetModelByType(this.modelInputAreaOut);
                this.modelInputAreaOut.component_id = null;
                this.dataGridAreaOut.rows = [];
                this.dataGridAreaOut.rows = this.$refs.dataGrid.selectedRows[0].area_out;
                this.dataGridAreaOut.rowsTemp = this.dataGridAreaOut.rows;

                this.model.vehicle_rate = parseInt(this.model.vehicle_rate);
                this.model.unloading_cost = parseInt(this.model.unloading_cost);
                this.model.start_shipment = parseInt(this.model.start_shipment);
                this.model.start_drop_charge_in_city = parseInt(this.model.start_drop_charge_in_city);
                this.model.rate_drop_charge_in_city = parseInt(this.model.rate_drop_charge_in_city);
                this.model.start_drop_charge_out_city = parseInt(this.model.start_drop_charge_out_city);
                this.model.rate_drop_charge_out_city = parseInt(this.model.rate_drop_charge_out_city);
                this.model.start_diff_area_in_city = parseInt(this.model.start_diff_area_in_city);
                this.model.rate_diff_area_in_city = parseInt(this.model.rate_diff_area_in_city);
                this.model.start_diff_area_out_city = parseInt(this.model.start_diff_area_out_city);
                this.model.rate_diff_area_out_city = parseInt(this.model.rate_diff_area_out_city);
                this.disableTabExpense = this.model.status == "2" ? false : true;
                this.$refs.dlg.open();
            }else{
                this.$refs.notification.centerTop(InformationUtil.selectRow);
            }
        },
        onDelete(){
            if(this.$refs.dataGrid.selectedRows.length > 0){

                this.$messager.confirm({
                    title: 'Confirm',
                    msg: InformationUtil.confirmDelete,
                    result: (r) => {
                        if (r){
                            this.dataGrid.loading = true;
                            MasterService.deleteData("/client-rate", this.$refs.dataGrid.selectedRows[0].id)
                                .then(() => {
                                    this.dataGrid.loading = false;
                                    this.$refs.notification.success(this.titleMessage + " " + InformationUtil.delete);
                                    this.loadData();
                                    this.$refs.dlg.close();
                                }).catch((err) => {
                                    this.$refs.notification.error(err.data.message);
                                });
                            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]) 
                        }
                    }
                })
                
            }else{
                this.$refs.notification.centerTop(InformationUtil.selectRow);
            }
            
        },
        onSave(){
            ModelUtil.resetModel(this.textError);
            this.$refs.form.validate((valid) => {
                if(!valid){
                    this.disableBtnSave = true; 
                    this.model.area_in = this.dataGridAreaIn.rowsTemp;
                    this.model.area_out = this.dataGridAreaOut.rowsTemp;

                    if(this.model.id == null){
                        // action add data
                        MasterService.addData("/client-rate", this.model)
                            .then((res) => {
                                this.disableBtnSave = false;
                                if(res.code == 200){
                                    this.$refs.notification.success(this.titleMessage + " " + InformationUtil.add);
                                    this.loadData();
                                    this.$refs.dlg.close();
                                } else {
                                    this.$messager.alert({
                                        title: "Error",
                                        icon: "error",
                                        msg: res.message
                                    });
                                }                               
                            }).catch((error) => {
                                this.disableBtnSave = false;
                                this.$refs.notification.error(error);
                            });

                    }else{
                        // action update data
                        MasterService.updateData("/client-rate", this.model.id, this.model)
                            .then((res) => {
                                this.disableBtnSave = false;
                                if(res.code == 200){
                                    this.$refs.notification.success(this.titleMessage + " " + InformationUtil.update);
                                    this.loadData();
                                    this.$refs.dlg.close();
                                } else {
                                    this.$messager.alert({
                                        title: "Error",
                                        icon: "error",
                                        msg: res.message
                                    });
                                }
                            }).catch((error) => {
                                this.disableBtnSave = false;
                                this.$refs.notification.error(error);
                            });
                    }
                    this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0])
                } else {
                    for(let key in valid){
                        this.textError[key] = this.getError(key);
                    }
                }
            });
            
        },

        // status
        onSelectStatus(event){
            if(event.value == "2")
            {
                this.disableTabExpense = false;
            }
            else
            {
                this.disableTabExpense = true;
            }
        },

        // import file
        onDownloadFile(){
            MasterService.downloadFile("/client-rate/download")
                .then((res) => {
                    if(res.type === 'application/vnd.ms-excel' && res != null){
                        let blob = new Blob([res], { type: "application/vnd.ms-excel" })
                        let objectUrl = window.URL.createObjectURL(blob)
                        const elem = window.document.createElement('a')
                        elem.href = objectUrl;
                        elem.download = "client_rate_template.xls"
                        document.body.appendChild(elem)
                        elem.click()
                        document.body.removeChild(elem)
                        window.URL.revokeObjectURL(blob);
                        window.URL.revokeObjectURL(objectUrl);
                    } else {
                        let message = new Blob([res], { type: 'application/json' })
                        message.text()
                            .then(value => {
                                let valMsg = JSON.parse(value);
                                this.$messager.alert({
                                    title: "Error",
                                    icon: "error",
                                    msg: valMsg.message
                                });
                            })
                            .catch(error => {
                                console.log("Something went wrong" + error);
                            });
                    }
                }).catch((error) => {
                    this.$refs.notification.error(error);
                });
        },
        onImport(){
            this.$refs.dlgImport.open();
            this.file = null;
            this.fileName = "";
        },
        onSelectedFile(event){
            this.file = event[0];
            this.fileName = this.file.name;
        },

        onSaveImport(){
            if(this.file != null && this.file != ''){
                let formData = new FormData();
                formData.append('file', this.file);
                MasterService.importFile("/client-rate/import", formData)
                    .then((res) => {
                        if(res != null){
                            let blob = new Blob([res], { type: "application/vnd.ms-excel" })
                            let objectUrl = window.URL.createObjectURL(blob)
                            const elem = window.document.createElement('a')
                            elem.href = objectUrl;
                            elem.download = "client_rate_validation.xls"
                            document.body.appendChild(elem)
                            elem.click()
                            document.body.removeChild(elem)
                            window.URL.revokeObjectURL(blob);
                            window.URL.revokeObjectURL(objectUrl);

                            this.loadData();
                            this.$refs.notification.success(InformationUtil.refresh);

                            this.$refs.dlgImport.close();
                        } else {
                            this.$messager.alert({
                                        title: "Error",
                                        icon: "error",
                                        msg: res.message
                                    });
                        }
                    }).catch((error) => {
                        this.$refs.notification.error(error);
                    });
            } else {
                this.$messager.alert({
                    title: "Info",
                    icon: "info",
                    msg: InformationUtil.infoSelectFile
                });
            }
        },

        onCancelImport(){
            this.$refs.dlgImport.close();
        },

        // handle data grid
        onMultiSearch(){
            this.onCallAllOption();
            this.$refs.dlgSearch.open();
        },
        onSearchMulti(){
            this.dataGrid.pageNumber = 1;
            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);
            this.loadData();
            this.$refs.dlgSearch.close();
        },
        onResetSearchMulti(){
            this.modelMultiSearch = ModelUtil.resetModel(this.modelMultiSearch);
            this.dataGrid.pageNumber = 1;
            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);
            this.loadData();
            this.$refs.dlgSearch.close();
        },
        onRowDblClick(){
            if(this.action.edit){
                this.onEdit();
            }
        },
        async onRefresh(){  
            this.dataGrid.pageNumber = 1;          
            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);
            await this.loadData();
            this.$refs.notification.success(InformationUtil.refresh);
        },
        onSearch(){
            this.loadData();
        },
        onSortChange(event){
            if(event.length > 0){
                this.dataGrid.pageNumber = 1;
                this.dataGrid.sortField = event[0].field;
                this.dataGrid.sortOrder = event[0].order;
                this.loadData();
            }
        },
        onPageChange(event) {
            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);
            this.dataGrid.pageNumber = event.pageNumber;
            this.dataGrid.pageSize = event.pageSize;
            if(event.refresh) this.dataGrid.pageNumber = 1;
            this.loadData();
            if(event.refresh){
                this.$refs.notification.success(InformationUtil.refresh);
            }
        },

        async loadData(){
            this.dataGrid.loading = true;
            return MasterService.getData('/client-rate', this.dataGrid, { multiSearch : this.modelMultiSearch } )
                .then((response) => {
                    if(response.data !== "" && response.data !== null){
                        let data = response.data; 
                        this.dataGrid.total = data.total;
                        this.dataGrid.rows = data.rows;
                    }
                    
                    this.dataGrid.loading = false;
                });
        },

        // data grid area in
        onSelectAreaIn(event){
            if(event != null && event.text != undefined){
                this.modelInputAreaIn.area_desc = event.text;
            }
        },
        addAreaIn(){
            // validation
            if(this.modelInputAreaIn.area_desc == null || this.modelInputAreaIn.area_desc == ""){
                this.$messager.alert({
                    title: "Error",
                    icon: "error",
                    msg: "Area required"
                });
                return;
            }

            if(this.modelInputAreaIn.rate == null || this.modelInputAreaIn.rate == "" || this.modelInputAreaIn.rate == 0){
                this.$messager.alert({
                    title: "Error",
                    icon: "error",
                    msg: "Rate is required"
                });
                return;
            }

            let isExistArea = false;
            this.dataGridAreaIn.rows.forEach(element => {
                if(element.area_id == this.modelInputAreaIn.area_id){
                    this.$messager.alert({
                        title: "Error",
                        icon: "error",
                        msg: "Area already exist"
                    });
                    isExistArea = true;
                    return;
                }
            });
            if(isExistArea) return;

            let data = {
                area_id : this.modelInputAreaIn.area_id,
                area_desc : this.modelInputAreaIn.area_desc,
                rate : parseInt(this.modelInputAreaIn.rate),
            }
            this.dataGridAreaIn.rows.push(data);
            this.dataGridAreaIn.rowsTemp = this.dataGridAreaIn.rows;
            this.dataGridAreaIn.total = this.dataGridAreaIn.rows.length;

            ModelUtil.resetModelByType(this.modelInputAreaIn);
            this.modelInputAreaIn.area_id = null;
        },

        onSearchAreaIn(){
            let search = this.dataGridAreaIn.search;
            let rows = this.dataGridAreaIn.rowsTemp;
            let result = [];
            if(search != null && search != ""){
                for(let i = 0; i < rows.length; i++){
                    if(rows[i].area_desc.toLowerCase().indexOf(search.toLowerCase()) > -1){
                        result.push(rows[i]);
                    }
                }
                this.dataGridAreaIn.rows = result;
            }else{
                this.dataGridAreaIn.rows = this.dataGridAreaIn.rowsTemp;
            }
        },

        onDeleteAreaIn(){
            if(this.$refs.dataGridAreaIn.selectedRows.length > 0){
                this.$messager.confirm({
                    title: 'Confirm',
                    msg: InformationUtil.confirmDelete,
                    result: (r) => {
                        if (r){
                            this.dataGridAreaIn.loading = true;
                            this.dataGridAreaIn.rows.splice(this.$refs.dataGridAreaIn.selectedRows[0].index, 1);
                            this.dataGridAreaIn.loading = false;
                            this.$refs.notification.success(this.titleMessage + " " + InformationUtil.delete);
                            this.$refs.dataGridAreaIn.unselectRow(this.$refs.dataGridAreaIn.selectedRows[0]);
                            this.dataGridAreaIn.rowsTemp = this.dataGridAreaIn.rows;
                        }
                    }
                })
            }else{
                this.$messager.alert({
                    title: "Info",
                    icon: "info",
                    msg: InformationUtil.selectRow
                });
            }
        },

        // data grid area out
        onSelectAreaOut(event){
            if(event != null && event.text != undefined){
                this.modelInputAreaOut.area_desc = event.text;
            }
        },
        addAreaOut(){
            // validation
            if(this.modelInputAreaOut.area_desc == null || this.modelInputAreaOut.area_desc == ""){
                this.$messager.alert({
                    title: "Error",
                    icon: "error",
                    msg: "Area required"
                });
                return;
            }

            if(this.modelInputAreaOut.rate == null || this.modelInputAreaOut.rate == "" || this.modelInputAreaOut.rate == 0){
                this.$messager.alert({
                    title: "Error",
                    icon: "error",
                    msg: "Rate is required"
                });
                return;
            }

            let isExistArea = false;
            this.dataGridAreaOut.rows.forEach(element => {
                if(element.area_id == this.modelInputAreaOut.area_id){
                    this.$messager.alert({
                        title: "Error",
                        icon: "error",
                        msg: "Area already exist"
                    });
                    isExistArea = true;
                    return;
                }
            });
            if(isExistArea) return;

            let data = {
                area_id : this.modelInputAreaOut.area_id,
                area_desc : this.modelInputAreaOut.area_desc,
                rate : parseInt(this.modelInputAreaOut.rate),
            }
            this.dataGridAreaOut.rows.push(data);
            this.dataGridAreaOut.rowsTemp = this.dataGridAreaOut.rows;
            this.dataGridAreaOut.total = this.dataGridAreaOut.rows.length;

            ModelUtil.resetModelByType(this.modelInputAreaOut);
            this.modelInputAreaOut.area_id = null;
        },

        onSearchAreaOut(){
            let search = this.dataGridAreaOut.search;
            let rows = this.dataGridAreaOut.rowsTemp;
            let result = [];
            if(search != null && search != ""){
                for(let i = 0; i < rows.length; i++){
                    if(rows[i].area_desc.toLowerCase().indexOf(search.toLowerCase()) > -1){
                        result.push(rows[i]);
                    }
                }
                this.dataGridAreaOut.rows = result;
            }else{
                this.dataGridAreaOut.rows = this.dataGridAreaOut.rowsTemp;
            }
        },

        onDeleteAreaOut(){
            if(this.$refs.dataGridAreaOut.selectedRows.length > 0){
                this.$messager.confirm({
                    title: 'Confirm',
                    msg: InformationUtil.confirmDelete,
                    result: (r) => {
                        if (r){
                            this.dataGridAreaOut.loading = true;
                            this.dataGridAreaOut.rows.splice(this.$refs.dataGridAreaOut.selectedRows[0].index, 1);
                            this.dataGridAreaOut.loading = false;
                            this.$refs.notification.success(this.titleMessage + " " + InformationUtil.delete);
                            this.$refs.dataGridAreaOut.unselectRow(this.$refs.dataGridAreaOut.selectedRows[0]);
                            this.dataGridAreaOut.rowsTemp = this.dataGridAreaOut.rows;
                        }
                    }
                })
            }else{
                this.$messager.alert({
                    title: "Info",
                    icon: "info",
                    msg: InformationUtil.selectRow
                });
            }
        },

        // combo box data
        async optionClient(event){
            let search = event == null ? "" : event.filterValue;
            return await OptionService.getOption("/client", search)
                .then((response) => {
                    this.clients = response.data;
                }).catch((error) => {
                    this.$refs.notification.error(error);
                });
        },
        async optionOrigin(event){
            let search = event == null ? "" : event.filterValue;
            return await OptionService.getOption("/area", search)
                .then((response) => {
                    this.origins = response.data;
                }).catch((error) => {
                    this.$refs.notification.error(error);
                });
        },
        async optionDestination(event){
            let search = event == null ? "" : event.filterValue;
            return await OptionService.getOption("/area", search)
                .then((response) => {
                    this.destinations = response.data;
                }).catch((error) => {
                    this.$refs.notification.error(error);
                });
        },
        async optionVehicleType(event){
            let search = event == null ? "" : event.filterValue;
            return await OptionService.getOption("/vehicleType", search)
                .then((response) => {
                    this.vehicleTypes = response.data;
                }).catch((error) => {
                    this.$refs.notification.error(error);
                });
        },
        async optionArea(event){
            let search = event == null ? "" : event.filterValue;
            return await OptionService.getOption("/area", search)
                .then((response) => {
                    for(let i = 0; i < response.data.length; i++){
                        response.data[i].text = response.data[i].area_id;
                    }
                    this.areas = response.data;
                }).catch((error) => {
                    this.$refs.notification.error(error);
                });
        },
        onCallAllOption(){
            this.optionClient();
            this.optionOrigin();
            this.optionDestination();
            this.optionVehicleType();
            this.optionArea();
        },
        
    },
    async mounted(){
        this.action = AccessUtil.getAccessAction('client_rate');
        await this.loadData();
        this.$refs.notification.success(InformationUtil.loadGrid + " " + this.dataGrid.total + " " + InformationUtil.successLoadGrid);     
    },
    created(){
        GlobalUtil.removeWindow();
    }
};
</script>