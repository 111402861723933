<template>
    <div>
        <Panel :title="titleView"  style="margin:0px 1px; ">
            <Tabs @tabSelect="onTabSelected($event)">
                <TabPanel :title="'Moving Monitoring'">
                    <div class="datagrid-toolbar">
                        <LinkButton :plain="true" :iconCls="iconUtil.iconRefresh" @click="onRefresh()">{{ informationUtil.refreshText }}</LinkButton>
                        <span class="button-sep"></span> {{ search.text }} : 
                        <SearchBox style="width:300px"
                            :placeholder="search.placeholder" 
                            v-model="dataGrid.search"
                            @search="onSearch()">
                            <Addon>
                                <span v-if="dataGrid.search" class="textbox-icon icon-clear" title="Clear value" @click="dataGrid.search=null;onSearch();"></span>
                            </Addon>
                        </SearchBox>
                    </div>
                    <DataGrid
                            ref="dataGrid"
                            :columnResizing="true"
                            :border="false"
                            :lazy="true"
                            :loading="dataGrid.loading"
                            :data="dataGrid.rows"
                            :pagination="true"
                            :selectionMode="dataGrid.selectionMode"
                            :total="dataGrid.total"
                            :pageSize="dataGrid.pageSize"
                            :pageNumber="dataGrid.pageNumber"
                            :pageLayout="dataGrid.pageLayout" 
                            @pageChange="onPageChange($event)"
                            @sortChange="onSortChange($event)"
                            @rowDblClick="onRowDblClick()"
                            >
                        <GridColumn align="center" cellCss="datagrid-td-rownumber" width="30">
                            <template slot="body" slot-scope="scope">
                                {{scope.rowIndex + 1}}
                            </template>
                        </GridColumn>
                        <GridColumn field="do_number" title="Do Number" :sortable="true"></GridColumn>
                        <GridColumn field="so_number" title="So Number" :sortable="true"></GridColumn>
                        <GridColumn field="submit_doc_date_desc" title="Submit Document Date" :sortable="true"></GridColumn>
                        <GridColumn field="validation_date_desc" title="Validation Date" :sortable="true"></GridColumn>
                        <GridColumn field="payment_verification_date_desc" title="Payment Verification Date" :sortable="true"></GridColumn>
                        <GridColumn field="penjaluran_date_desc" title="Penjaluran Date" :sortable="true"></GridColumn>
                        <GridColumn field="sppb_date_desc" title="SPPB Date" :sortable="true"></GridColumn>
                    </DataGrid>
                </TabPanel>
                <TabPanel :title="'Port'">
                    <div class="datagrid-toolbar">
                        <LinkButton :plain="true" :iconCls="iconUtil.iconRefresh" @click="onRefreshPort()">{{ informationUtil.refreshText }}</LinkButton>
                        <span class="button-sep"></span> {{ search.text }} : 
                        <SearchBox style="width:300px"
                            :placeholder="search.placeholder" 
                            v-model="dataGridPort.search"
                            @search="onSearchPort()">
                            <Addon>
                                <span v-if="dataGridPort.search" class="textbox-icon icon-clear" title="Clear value" @click="dataGridPort.search=null;onSearchPort();"></span>
                            </Addon>
                        </SearchBox>
                    </div>
                    <DataGrid
                            ref="dataGridPort"
                            :columnResizing="true"
                            :border="false"
                            :lazy="true"
                            :loading="dataGridPort.loading"
                            :data="dataGridPort.rows"
                            :pagination="true"
                            :selectionMode="dataGridPort.selectionMode"
                            :total="dataGridPort.total"
                            :pageSize="dataGridPort.pageSize"
                            :pageNumber="dataGridPort.pageNumber"
                            :pageLayout="dataGridPort.pageLayout" 
                            @pageChange="onPageChangePort($event)"
                            @sortChange="onSortChangePort($event)"
                            @rowDblClick="onRowDblClickPort()"
                            >
                            <GridColumn align="center" cellCss="datagrid-td-rownumber" width="30">
                            <template slot="body" slot-scope="scope">
                                {{scope.rowIndex + 1}}
                            </template>
                        </GridColumn>
                        <GridColumn field="do_number" title="Do Number" :sortable="true"></GridColumn>
                        <GridColumn field="so_number" title="So Number" :sortable="true"></GridColumn>
                        <GridColumn field="sppb_date_desc" title="SPPB Date" :sortable="true"></GridColumn>
                        <GridColumn field="aging" title="Aging" :sortable="true"></GridColumn>
                    </DataGrid>
                </TabPanel>
                <TabPanel :title="'Demurrage'">
                    <div class="datagrid-toolbar">
                        <LinkButton :plain="true" :iconCls="iconUtil.iconRefresh" @click="onRefreshDemurrage()">{{ informationUtil.refreshText }}</LinkButton>
                        <span class="button-sep"></span> {{ search.text }} : 
                        <SearchBox style="width:300px"
                            :placeholder="search.placeholder" 
                            v-model="dataGridDemurrage.search"
                            @search="onSearchDemurrage()">
                            <Addon>
                                <span v-if="dataGridDemurrage.search" class="textbox-icon icon-clear" title="Clear value" @click="dataGridDemurrage.search=null;onSearchDemurrage();"></span>
                            </Addon>
                        </SearchBox>
                    </div>
                    <DataGrid
                            ref="dataGridDemurrage"
                            :columnResizing="true"
                            :border="false"
                            :lazy="true"
                            :loading="dataGridDemurrage.loading"
                            :data="dataGridDemurrage.rows"
                            :pagination="true"
                            :selectionMode="dataGridDemurrage.selectionMode"
                            :total="dataGridDemurrage.total"
                            :pageSize="dataGridDemurrage.pageSize"
                            :pageNumber="dataGridDemurrage.pageNumber"
                            :pageLayout="dataGridDemurrage.pageLayout" 
                            @pageChange="onPageChangeDemurrage($event)"
                            @sortChange="onSortChangeDemurrage($event)"
                            @rowDblClick="onRowDblClickDemurrage()"
                            >
                        <GridColumn align="center" cellCss="datagrid-td-rownumber" width="30">
                            <template slot="body" slot-scope="scope">
                                {{scope.rowIndex + 1}}
                            </template>
                        </GridColumn>
                        <GridColumn field="do_number" title="Do Number" :sortable="true"></GridColumn>
                        <GridColumn field="so_number" title="So Number" :sortable="true"></GridColumn>
                        <GridColumn field="sppb_date_desc" title="SPPB Date" :sortable="true"></GridColumn>
                        <GridColumn field="aging" title="Aging" :sortable="true"></GridColumn>
                        <GridColumn field="expense_demurrage" title="Expense" :sortable="true" align="right"></GridColumn>
                    </DataGrid>
                </TabPanel>
            </Tabs>
        </Panel>

        <!-- form moving document -->
        <Dialog ref="dlg" :iconCls="iconUtil.iconEdit" bodyCls="f-column" :title="titleForm" :draggable="true" :modal="true" closed @close="onCancel" :dialogStyle="{width:'550px', height:'450px'}">
            <div class="f-full" style="overflow:auto">
                <Form ref="form" :model="model">
                    <fieldset class="c-fieldset">
                        <legend class="c-legend"><b>{{ titleLegend }}</b></legend>
                        <table width="100%" cellspacing="1" cellpadding="5" border="0">
                            <tbody>
                                <tr>
                                    <td style="width:30%;">SO Number</td>
                                    <td style="width:70%;">
                                        {{modelNotMapping.so_number}}
                                    </td>
                                </tr>
                                <tr>
                                    <td>DO Number</td>
                                    <td>
                                        {{modelNotMapping.do_number}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </fieldset>
                    <fieldset class="c-fieldset">
                        <legend class="c-legend"><b>Details Information</b></legend>
                        <Tabs style="height:220px">
                            <TabPanel :title="'Time Stamp'">
                                <table width="100%" cellspacing="1" cellpadding="5" border="0">
                                    <tbody>
                                        <tr>
                                            <td style="width:30%;">Submit Document</td>
                                            <td style="width:60%;">
                                                <DateBox inputId="submit_doc_date" name="submit_doc_date" class="f-field-text text-60" v-model="model.submit_doc_date" format="dd/MM/yyyy" :disabled="disableSubmit" @selectionChange="changeSubmit($event)">
                                                    <Addon>
                                                        <span v-if="model.submit_doc_date && !disableSubmit" class="textbox-icon icon-clear" title="Clear value" @click="model.submit_doc_date=null;changeSubmit(null)"></span>
                                                    </Addon>
                                                </DateBox>
                                                <TimePicker inputId="submit_doc_time" name="submit_doc_time" class="f-field-text text-40" v-model="model.submit_doc_time" :hour24="true" :panelStyle="{width:'250px',height:'350px'}" :disabled="disableSubmit">
                                                    <Addon>
                                                        <span v-if="model.submit_doc_time && !disableSubmit" class="textbox-icon icon-clear" title="Clear value" @click="model.submit_doc_time=null;"></span>
                                                    </Addon>
                                                </TimePicker>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Validation</td>
                                            <td>
                                                <DateBox inputId="validation_date" name="validation_date" class="f-field-text text-60" v-model="model.validation_date" format="dd/MM/yyyy" :disabled="disableValidation" @selectionChange="changeValidation($event)">
                                                    <Addon>
                                                        <span v-if="model.validation_date && !disableValidation" class="textbox-icon icon-clear" title="Clear value" @click="model.validation_date=null;changeValidation(null)"></span>
                                                    </Addon>
                                                </DateBox>
                                                <TimePicker inputId="validation_time" name="validation_time" class="f-field-text text-40" v-model="model.validation_time" :hour24="true" :panelStyle="{width:'250px',height:'350px'}" :disabled="disableValidation">
                                                    <Addon>
                                                        <span v-if="model.validation_time && !disableValidation" class="textbox-icon icon-clear" title="Clear value" @click="model.validation_time=null;"></span>
                                                    </Addon>
                                                </TimePicker>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Payment Verification</td>
                                            <td>
                                                <DateBox inputId="payment_verification_date" name="payment_verification_date" class="f-field-text text-60" v-model="model.payment_verification_date" format="dd/MM/yyyy" :disabled="disablePayment" @selectionChange="changePayment($event)">
                                                    <Addon>
                                                        <span v-if="model.payment_verification_date && !disablePayment" class="textbox-icon icon-clear" title="Clear value" @click="model.payment_verification_date=null;changePayment(null)"></span>
                                                    </Addon>
                                                </DateBox>
                                                <TimePicker inputId="payment_verification_time" name="payment_verification_time" class="f-field-text text-40" v-model="model.payment_verification_time" :hour24="true" :panelStyle="{width:'250px',height:'350px'}" :disabled="disablePayment"> 
                                                    <Addon>
                                                        <span v-if="model.payment_verification_time && !disablePayment" class="textbox-icon icon-clear" title="Clear value" @click="model.payment_verification_time=null;"></span>
                                                    </Addon>
                                                </TimePicker>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Penjaluran</td>
                                            <td>
                                                <DateBox inputId="penjaluran_date" name="penjaluran_date" class="f-field-text text-60" v-model="model.penjaluran_date" format="dd/MM/yyyy" :disabled="disablePenjaluran" @selectionChange="changePenjaluran($event)">
                                                    <Addon>
                                                        <span v-if="model.penjaluran_date && !disablePenjaluran" class="textbox-icon icon-clear" title="Clear value" @click="model.penjaluran_date=null;changePenjaluran(null)"></span>
                                                    </Addon>
                                                </DateBox>
                                                <TimePicker inputId="penjaluran_time" name="penjaluran_time" class="f-field-text text-40" v-model="model.penjaluran_time" :hour24="true" :panelStyle="{width:'250px',height:'350px'}" :disabled="disablePenjaluran">
                                                    <Addon>
                                                        <span v-if="model.penjaluran_time && !disablePenjaluran" class="textbox-icon icon-clear" title="Clear value" @click="model.penjaluran_time=null;"></span>
                                                    </Addon>
                                                </TimePicker>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>SPPB</td>
                                            <td>
                                                <DateBox inputId="sppb_date" name="sppb_date" class="f-field-text text-60" v-model="model.sppb_date" format="dd/MM/yyyy" :disabled="disableSppb">
                                                    <Addon>
                                                        <span v-if="model.sppb_date  && !disableSppb" class="textbox-icon icon-clear" title="Clear value" @click="model.sppb_date=null;"></span>
                                                    </Addon>
                                                </DateBox>
                                                <TimePicker inputId="sppb_time" name="sppb_time" class="f-field-text text-40" v-model="model.sppb_time" :hour24="true" :panelStyle="{width:'250px',height:'350px'}" :disabled="disableSppb">
                                                    <Addon>
                                                        <span v-if="model.sppb_time && !disableSppb" class="textbox-icon icon-clear" title="Clear value" @click="model.sppb_time=null;"></span>
                                                    </Addon>
                                                </TimePicker>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </TabPanel>
                            <TabPanel :title="'Log'">
                                <table width="100%" cellspacing="1" cellpadding="5" border="0">
                                    <tbody>
                                        <tr>
                                            <td>
                                                Created By
                                            </td>
                                            <td>
                                                <TextBox inputId="created_by" name="created_by" class="f-field-text" :value="modelNotMapping.created_by" :disabled="true"></TextBox>
                                            </td>
                                            <td>
                                                Created Date
                                            </td>
                                            <td>
                                                <TextBox inputId="created_date" name="created_date" class="f-field-text" :value="modelNotMapping.created_date" :disabled="true"></TextBox>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Updated By
                                            </td>
                                            <td>
                                                <TextBox inputId="updated_by" name="updated_by" class="f-field-text" :value="modelNotMapping.updated_by" :disabled="true"></TextBox>
                                            </td>
                                            <td>
                                                Updated Date
                                            </td>
                                            <td>
                                                <TextBox inputId="updated_date" name="updated_date" class="f-field-text" :value="modelNotMapping.updated_date" :disabled="true"></TextBox>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </TabPanel>
                        </Tabs>
                    </fieldset>
                </Form>
            </div>
            <div class="dialog-button">
                <LinkButton :iconCls="iconUtil.iconSave" @click="onSaveData()" :disabled="disableBtnSave" style="width:89px">{{ informationUtil.saveText }}</LinkButton>
                <LinkButton :iconCls="iconUtil.iconCancel" @click="onCancel()" style="width:89px">{{ informationUtil.cancelText }}</LinkButton>
            </div>
        </Dialog>

        <Dialog ref="dlgPort" :iconCls="iconUtil.iconEdit" bodyCls="f-column" :title="titleForm" :draggable="true" :modal="true" closed :dialogStyle="{width:'550px', height:'450px'}">
            <div class="f-full" style="overflow:auto">
                <fieldset class="c-fieldset">
                    <legend class="c-legend"><b>{{ titleLegend }}</b></legend>
                    <table width="100%" cellspacing="1" cellpadding="5" border="0">
                        <tbody>
                            <tr>
                                <td style="width:30%;">SO Number</td>
                                <td style="width:70%;">
                                    {{modelNotMapping.so_number}}
                                </td>
                            </tr>
                            <tr>
                                <td>DO Number</td>
                                <td>
                                    {{modelNotMapping.do_number}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </fieldset>
                <fieldset class="c-fieldset">
                    <legend class="c-legend"><b>Details Information</b></legend>
                    <Tabs style="height:220px">
                        <TabPanel :title="'Time Stamp'">
                            <table width="100%" cellspacing="1" cellpadding="5" border="0">
                                <tbody>
                                    <tr>
                                        <td style="width:30%;">Submit Document</td>
                                        <td style="width:60%;">
                                            {{ modelNotMapping.submit_doc_date_desc }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Validation</td>
                                        <td>
                                            {{ modelNotMapping.validation_date_desc }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Payment Verification</td>
                                        <td>
                                            {{ modelNotMapping.payment_verification_date_desc }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Penjaluran</td>
                                        <td>
                                            {{ modelNotMapping.penjaluran_date_desc }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>SPPB</td>
                                        <td>
                                            {{ modelNotMapping.sppb_date_desc }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Aging From SPPB</td>
                                        <td>
                                            {{ modelNotMapping.aging }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </TabPanel>
                        <TabPanel :title="'Log'">
                            <table width="100%" cellspacing="1" cellpadding="5" border="0">
                                <tbody>
                                    <tr>
                                        <td>
                                            Created By
                                        </td>
                                        <td>
                                            {{ modelNotMapping.created_by }}
                                        </td>
                                        <td>
                                            Created Date
                                        </td>
                                        <td>
                                            {{ modelNotMapping.created_date }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Updated By
                                        </td>
                                        <td>
                                            {{ modelNotMapping.updated_by }}
                                        </td>
                                        <td>
                                            Updated Date
                                        </td>
                                        <td>
                                            {{ modelNotMapping.updated_date }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </TabPanel>
                    </Tabs>
                </fieldset>
            </div>
            <div class="dialog-button">
                <LinkButton :iconCls="iconUtil.iconCancel" @click="$refs.dlgPort.close()" style="width:89px">{{ informationUtil.cancelText }}</LinkButton>
            </div>
        </Dialog>

        <Dialog ref="dlgDemurrage" :iconCls="iconUtil.iconEdit" bodyCls="f-column" :title="titleForm" :draggable="true" :modal="true" closed :dialogStyle="{width:'550px', height:'450px'}">
            <div class="f-full" style="overflow:auto">
                <fieldset class="c-fieldset">
                    <legend class="c-legend"><b>{{ titleLegend }}</b></legend>
                    <table width="100%" cellspacing="1" cellpadding="5" border="0">
                        <tbody>
                            <tr>
                                <td style="width:30%;">SO Number</td>
                                <td style="width:70%;">
                                    {{modelNotMapping.so_number}}
                                </td>
                            </tr>
                            <tr>
                                <td>DO Number</td>
                                <td>
                                    {{modelNotMapping.do_number}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </fieldset>
                <fieldset class="c-fieldset">
                    <legend class="c-legend"><b>Details Information</b></legend>
                    <Tabs style="height:220px">
                        <TabPanel :title="'Time Stamp'">
                            <table width="100%" cellspacing="1" cellpadding="5" border="0">
                                <tbody>
                                    <tr>
                                        <td style="width:30%;">Submit Document</td>
                                        <td style="width:60%;">
                                            {{ modelNotMapping.submit_doc_date_desc }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Validation</td>
                                        <td>
                                            {{ modelNotMapping.validation_date_desc }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Payment Verification</td>
                                        <td>
                                            {{ modelNotMapping.payment_verification_date_desc }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Penjaluran</td>
                                        <td>
                                            {{ modelNotMapping.penjaluran_date_desc }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>SPPB</td>
                                        <td>
                                            {{ modelNotMapping.sppb_date_desc }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Aging From SPPB</td>
                                        <td>
                                            {{ modelNotMapping.aging }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Expense</td>
                                        <td>
                                            {{ modelNotMapping.expense_demurrage }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </TabPanel>
                        <TabPanel :title="'Log'">
                            <table width="100%" cellspacing="1" cellpadding="5" border="0">
                                <tbody>
                                    <tr>
                                        <td>
                                            Created By
                                        </td>
                                        <td>
                                            {{ modelNotMapping.created_by }}
                                        </td>
                                        <td>
                                            Created Date
                                        </td>
                                        <td>
                                            {{ modelNotMapping.created_date }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Updated By
                                        </td>
                                        <td>
                                            {{ modelNotMapping.updated_by }}
                                        </td>
                                        <td>
                                            Updated Date
                                        </td>
                                        <td>
                                            {{ modelNotMapping.updated_date }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </TabPanel>
                    </Tabs>
                </fieldset>
            </div>
            <div class="dialog-button">
                <LinkButton :iconCls="iconUtil.iconCancel" @click="$refs.dlgDemurrage.close()" style="width:89px">{{ informationUtil.cancelText }}</LinkButton>
            </div>
        </Dialog>

        <NotificationComponent ref="notification"></NotificationComponent>
    </div>
</template>

<script>
import NotificationComponent from '@/components/NotificationComponent.vue';
import TransportService from '@/services/TransportService';
import ModelUtil from '@/utils/ModelUtil';
import InformationUtil from '@/utils/InformationUtil';
import AccessUtil from '@/utils/AccessUtil';
import IconUtil from '@/utils/IconUtil';
import GlobalUtil from '@/utils/GlobalUtil';
import TransportOrder from '@/models/TransportOrder';

export default {
    name: 'MovingMonitoringView',
    components : {
        NotificationComponent
    },
    data() {
        return {
            titleView : InformationUtil.movingMonitoring + "s",
            titleMessage : InformationUtil.movingMonitoring,
            titleForm : InformationUtil.movingMonitoring,
            titleLegend : InformationUtil.transportOrder,
            iconUtil : IconUtil,
            informationUtil : InformationUtil,
            
            action : {
                view : false,
                add : false,
                edit : false,
                delete : false
            },

            filter : {
                viewStatusMessage : false,
            },

            search : {
                text : InformationUtil.searching,
                placeholder : InformationUtil.searchHolder
            },

            disableSubmit : false,
            disableValidation : true,
            disablePayment : true,
            disablePenjaluran : true,
            disableSppb : true,
            disableBtnSave : false,

            dataGrid : {
                loading: false,
                rows : [],
                total: 0,
                start: 0,
                pageNumber: 1,
                pageSize: 10,
                selectionMode : 'single',
                pageLayout: ['list','sep','first','prev','next','last','sep','refresh','info'],

                // filter
                search : null,
                sortField : "",
                sortOrder : ""
            },

            dataGridPort : {
                loading: false,
                rows : [],
                total: 0,
                start: 0,
                pageNumber: 1,
                pageSize: 10,
                selectionMode : 'single',
                pageLayout: ['list','sep','first','prev','next','last','sep','refresh','info'],

                // filter
                search : null,
                sortField : "",
                sortOrder : ""
            },

            dataGridDemurrage : {
                loading: false,
                rows : [],
                total: 0,
                start: 0,
                pageNumber: 1,
                pageSize: 10,
                selectionMode : 'single',
                pageLayout: ['list','sep','first','prev','next','last','sep','refresh','info'],

                // filter
                search : null,
                sortField : "",
                sortOrder : ""
            },

            // model
            model : TransportOrder,
            modelNotMapping: {
                created_by : "",
                updated_by : "",
                created_date : "",
                updated_date : "",
                so_number : '',
                do_number : '',
                expense_demurrage : '',
                submit_doc_date_desc : '',
                validation_date_desc : '',
                payment_verification_date_desc : '',
                penjaluran_date_desc : '',
                sppb_date_desc : '',
                aging : '',
            },
        }
    },
    methods:{
        onTabSelected(event){
            if(event.title == "Moving Monitoring"){
                this.loadData();
            }

            if(event.title == "Port"){
                this.loadDataPort();
            }

            if(event.title == "Demurrage"){
                this.loadDataDemurrage();
            }
        },
        onEdit(){
            if(this.$refs.dataGrid.selectedRows.length > 0){
                this.model = ModelUtil.resetModel(this.model);
                this.model = ModelUtil.mapModel(this.model, this.$refs.dataGrid.selectedRows[0]);

                this.modelNotMapping = ModelUtil.resetModel(this.modelNotMapping);
                this.modelNotMapping = ModelUtil.mapModel(this.modelNotMapping, this.$refs.dataGrid.selectedRows[0]);

                this.model.submit_doc_date = ( this.model.submit_doc_date != null && this.model.submit_doc_date != undefined && this.model.submit_doc_date != '') ? new Date(this.model.submit_doc_date) : null;
                this.model.validation_date = ( this.model.validation_date != null && this.model.validation_date != undefined && this.model.validation_date != '') ? new Date(this.model.validation_date) : null;
                this.model.payment_verification_date = ( this.model.payment_verification_date != null && this.model.payment_verification_date != undefined && this.model.payment_verification_date != '') ? new Date(this.model.payment_verification_date) : null;
                this.model.penjaluran_date = ( this.model.penjaluran_date != null && this.model.penjaluran_date != undefined && this.model.penjaluran_date != '') ? new Date(this.model.penjaluran_date) : null;
                this.model.sppb_date = ( this.model.sppb_date != null && this.model.sppb_date != undefined && this.model.sppb_date != '') ? new Date(this.model.sppb_date) : null;

                if(this.model.submit_doc_date != null){
                    this.disableSubmit = true;
                } else {
                    this.disableSubmit = false;
                }

                if(this.model.validation_date != null){
                    this.disableValidation = true;
                } else {
                    this.disableValidation = this.model.submit_doc_date == null ? true : false;
                }

                if(this.model.payment_verification_date != null){
                    this.disablePayment = true;
                } else {
                    this.disablePayment = this.model.validation_date == null ? true : false;
                }

                if(this.model.penjaluran_date != null){
                    this.disablePenjaluran = true;
                } else {
                    this.disablePenjaluran = this.model.payment_verification_date == null ? true : false;
                }

                if(this.model.sppb_date != null){
                    this.disableSppb = true;
                } else {
                    this.disableSppb = this.model.penjaluran_date == null ? true : false;
                }


                this.$refs.dlg.open();
            } else {
                this.$refs.notification.error(InformationUtil.selectRow);
            }
        },
        onSaveData(){
            if(this.model.submit_doc_date != null && this.model.submit_doc_time == null){
                this.$messager.alert({
                        title: "Error",
                        icon: "error",
                        msg: "Please Add Submit Document Time"
                    });
                return;
            }

            if(this.model.validation_date != null && this.model.validation_time == null){
                this.$messager.alert({
                        title: "Error",
                        icon: "error",
                        msg: "Please Add Validation Time"
                    });
                return;
            }

            if(this.model.payment_verification_date != null && this.model.payment_verification_time == null){
                this.$messager.alert({
                        title: "Error",
                        icon: "error",
                        msg: "Please Add Payment Verification Time"
                    });
                return;
            }

            if(this.model.penjaluran_date != null && this.model.penjaluran_time == null){
                this.$messager.alert({
                        title: "Error",
                        icon: "error",
                        msg: "Please Add Penjaluran Time"
                    });
                return;
            }

            if(this.model.sppb_date != null && this.model.sppb_time == null){
                this.$messager.alert({
                        title: "Error",
                        icon: "error",
                        msg: "Please Add SPPB Time"
                    });
                return;
            }

            this.disableBtnSave = true;
            if(this.model.id != null){
                this.model.submit_doc_date = ( this.model.submit_doc_date != null && this.model.submit_doc_date != undefined && this.model.submit_doc_date != '') ? new Date(this.$moment(this.model.submit_doc_date).format('YYYY-MM-DD')) : null;
                this.model.validation_date = ( this.model.validation_date != null && this.model.validation_date != undefined && this.model.validation_date != '') ? new Date(this.$moment(this.model.validation_date).format('YYYY-MM-DD')) : null;
                this.model.payment_verification_date = ( this.model.payment_verification_date != null && this.model.payment_verification_date != undefined && this.model.payment_verification_date != '') ? new Date(this.$moment(this.model.payment_verification_date).format('YYYY-MM-DD')) : null;
                this.model.penjaluran_date = ( this.model.penjaluran_date != null && this.model.penjaluran_date != undefined && this.model.penjaluran_date != '') ? new Date(this.$moment(this.model.penjaluran_date).format('YYYY-MM-DD')) : null;
                this.model.sppb_date = ( this.model.sppb_date != null && this.model.sppb_date != undefined && this.model.sppb_date != '') ? new Date(this.$moment(this.model.sppb_date).format('YYYY-MM-DD')) : null;

                

                // action update data
                TransportService.updateData("/moving-monitoring", this.model.id, this.model)
                    .then((res) => {
                        this.disableBtnSave = false;
                        if(res.code == 200){
                            this.$refs.notification.success(this.titleMessage + " " + InformationUtil.update);
                            this.loadData();
                            this.$refs.dlg.close();
                            this.disableBtnSave = false;
                        } else {
                            this.$messager.alert({
                                title: "Error",
                                icon: "error",
                                msg: res.message
                            });
                        }
                    }).catch((error) => {
                        this.disableBtnSave = false;
                        this.$refs.notification.error(error);
                    });
            } else {
                this.disableBtnSave = true;
                this.$refs.notification.error(InformationUtil.selectRow);
            }
        },
        onCancel(){
            this.$refs.dlg.close();
            this.model.submit_doc_date = null;
            this.model.validation_date = null;
            this.model.payment_verification_date = null;
            this.model.penjaluran_date = null;
            this.model.sppb_date = null;
        },
        onViewDataPort(){
            if(this.$refs.dataGridPort.selectedRows.length > 0){
                this.modelNotMapping = ModelUtil.resetModel(this.modelNotMapping);
                this.modelNotMapping = ModelUtil.mapModel(this.modelNotMapping, this.$refs.dataGridPort.selectedRows[0]);
                this.$refs.dlgPort.open();
            } else {
                this.$refs.notification.error(InformationUtil.selectRow);
            }
        },
        onViewDataDemurrage(){
            if(this.$refs.dataGridDemurrage.selectedRows.length > 0){
                this.modelNotMapping = ModelUtil.resetModel(this.modelNotMapping);
                this.modelNotMapping = ModelUtil.mapModel(this.modelNotMapping, this.$refs.dataGridDemurrage.selectedRows[0]);
                this.$refs.dlgDemurrage.open();
            } else {
                this.$refs.notification.error(InformationUtil.selectRow);
            }
        },
        
        // handle data grid
        onRowDblClick(){
            if(this.action.edit){
                this.onEdit();
            }
        },
        async onRefresh(){
            this.dataGrid.pageNumber = 1;
            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);
            await this.loadData();
            this.$refs.notification.success(InformationUtil.refresh);
        },
        async onSearch(){
            await this.loadData();
            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);
            this.$refs.notification.success(InformationUtil.filter);
        },
        onSortChange(event){
            if(event.length > 0){
                this.dataGrid.pageNumber = 1;
                this.dataGrid.sortField = event[0].field;
                this.dataGrid.sortOrder = event[0].order;
                this.loadData();
            }
        },
        onPageChange(event) {
            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);
            this.dataGrid.pageNumber = event.pageNumber;
            this.dataGrid.pageSize = event.pageSize;
            if(event.refresh) this.dataGrid.pageNumber = 1;
            this.loadData();
            if(event.refresh){
                this.$refs.notification.success(InformationUtil.refresh);
            }
        },

        async loadData(){
            this.dataGrid.loading = true;
            return TransportService.getData('/moving-monitoring', this.dataGrid)
                .then((response) => {
                    if(response.data !== "" && response.data !== null){
                        let data = response.data; 
                        this.dataGrid.total = data.total;
                        this.dataGrid.rows = data.rows;
                    }
                    this.dataGrid.loading = false;
                });
        },

        // port
        onRowDblClickPort(){
            this.onViewDataPort();
        },
        async onRefreshPort(){
            this.dataGridPort.pageNumber = 1;
            this.$refs.dataGridPort.unselectRow(this.$refs.dataGridPort.selectedRows[0]);
            await this.loadDataPort();
            this.$refs.notification.success(InformationUtil.refresh);
        },
        async onSearchPort(){
            await this.loadDataPort();
            this.$refs.dataGridPort.unselectRow(this.$refs.dataGridPort.selectedRows[0]);
            this.$refs.notification.success(InformationUtil.filter);
        },
        onSortChangePort(event){
            if(event.length > 0){
                this.dataGridPort.pageNumber = 1;
                this.dataGridPort.sortField = event[0].field;
                this.dataGridPort.sortOrder = event[0].order;
                this.loadDataPort();
            }
        },
        onPageChangePort(event) {
            this.$refs.dataGridPort.unselectRow(this.$refs.dataGridPort.selectedRows[0]);
            this.dataGridPort.pageNumber = event.pageNumber;
            this.dataGridPort.pageSize = event.pageSize;
            if(event.refresh) this.dataGridPort.pageNumber = 1;
            this.loadDataPort();
            if(event.refresh){
                this.$refs.notification.success(InformationUtil.refresh);
            }
        },

        async loadDataPort(){
            this.dataGridPort.loading = true;
            return TransportService.getData('/moving-monitoring/port', this.dataGridPort)
                .then((response) => {
                    if(response.data !== "" && response.data !== null){
                        let data = response.data; 
                        this.dataGridPort.total = data.total;
                        this.dataGridPort.rows = data.rows;
                    }
                    this.dataGridPort.loading = false;
                });
        },

        // Demurrage
        onRowDblClickDemurrage(){
            this.onViewDataDemurrage();
        },
        async onRefreshDemurrage(){
            this.dataGridDemurrage.pageNumber = 1;
            this.$refs.dataGridDemurrage.unselectRow(this.$refs.dataGridDemurrage.selectedRows[0]);
            await this.loadData();
            this.$refs.notification.success(InformationUtil.refresh);
        },
        async onSearchDemurrage(){
            await this.loadData();
            this.$refs.dataGridDemurrage.unselectRow(this.$refs.dataGridDemurrage.selectedRows[0]);
            this.$refs.notification.success(InformationUtil.filter);
        },
        onSortChangeDemurrage(event){
            if(event.length > 0){
                this.dataGridDemurrage.pageNumber = 1;
                this.dataGridDemurrage.sortField = event[0].field;
                this.dataGridDemurrage.sortOrder = event[0].order;
                this.loadData();
            }
        },
        onPageChangeDemurrage(event) {
            this.$refs.dataGridDemurrage.unselectRow(this.$refs.dataGridDemurrage.selectedRows[0]);
            this.dataGridDemurrage.pageNumber = event.pageNumber;
            this.dataGridDemurrage.pageSize = event.pageSize;
            if(event.refresh) this.dataGridDemurrage.pageNumber = 1;
            this.loadData();
            if(event.refresh){
                this.$refs.notification.success(InformationUtil.refresh);
            }
        },

        async loadDataDemurrage(){
            this.dataGridDemurrage.loading = true;
            return TransportService.getData('/moving-monitoring/demurrage', this.dataGridDemurrage)
                .then((response) => {
                    if(response.data !== "" && response.data !== null){
                        let data = response.data; 
                        this.dataGridDemurrage.total = data.total;
                        this.dataGridDemurrage.rows = data.rows;
                    }
                    this.dataGridDemurrage.loading = false;
                });
        },

        // handle data
        changeSubmit(val){
            if(val != null){
                this.disableValidation = false;
            } else {
                this.disableValidation = true;
                this.disablePayment = true;
                this.disablePenjaluran = true;
                this.disableSppb = true;
                this.model.validation_date = null;
                this.model.validation_time = null;
                this.model.payment_verification_date = null;
                this.model.payment_verification_time = null;
                this.model.penjaluran_date = null;
                this.model.penjaluran_time = null;
                this.model.sppb_date = null;
                this.model.sppb_time = null;
            }
        },
        changeValidation(val){
            if(val != null){
                this.disablePayment = false;
            } else {
                this.disablePayment = true;
                this.disablePenjaluran = true;
                this.disableSppb = true;
                this.model.payment_verification_date = null;
                this.model.payment_verification_time = null;
                this.model.penjaluran_date = null;
                this.model.penjaluran_time = null;
                this.model.sppb_date = null;
                this.model.sppb_time = null;
            }
        },
        changePayment(val){
            if(val != null){
                this.disablePenjaluran = false;
            } else {
                this.disablePenjaluran = true;
                this.disableSppb = true;
                this.model.penjaluran_date = null;
                this.model.penjaluran_time = null;
                this.model.sppb_date = null;
                this.model.sppb_time = null;
            }
        },
        changePenjaluran(val){
            if(val != null){
                this.disableSppb = false;
            } else {
                this.disableSppb = true;
                this.model.sppb_date = null;
                this.model.sppb_time = null;
            }
        },
    },
    async mounted(){
        this.action = AccessUtil.getAccessAction('trucking_order');
        await this.loadData();
        this.$refs.notification.success(InformationUtil.loadGrid + " " + this.dataGrid.total + " " + InformationUtil.successLoadGrid);
    },
    created(){
        GlobalUtil.removeWindow();
    }
};
</script>