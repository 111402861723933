// import EncryptUtil from "@/utils/EncryptUtil";
import AppService from "../services/AppService";
import AuthService from "../services/AuthService";

export default{
    state: { 
        user: null,
        isLoggedIn: false,
    },
    mutations: { 
        set_user (state, data) {
            state.user = data
            state.isLoggedIn = true
        }, 
        reset_user (state) {
            state.user = null
            state.isLoggedIn = false
        }
    },
    getters:{ 
        isLoggedIn (state){
            return state.isLoggedIn
        },
        user (state) {
            return state.user
        }
    },
    actions: { 
        login({ commit }, data) {
            return new Promise((resolve, reject) => { 
              AuthService.login(data)
                .then(response => {
                    if(response.status == 'success'){
                        const token = response.data.token
                        localStorage.setItem('token', token)
                        AppService.setHeaderToken(token)
                        commit('set_user', response.data.user)
                    }
                    resolve(response)
                })
                .catch(err => { 
                    commit('reset_user')
                    localStorage.removeItem('token')
                    reject(err)
                })
            })
        },
        logout({ commit }) {
            return new Promise((resolve) => {
                commit('reset_user')
                localStorage.removeItem('token')
                AppService.removeHeaderToken()
                resolve()
            })
        },
        getUser({ commit }){
            return new Promise((resolve, reject) => { 
                AuthService.getUser()
                  .then(response => {
                      if(response.status == 'success'){
                          const data = response.data
                          commit('set_user', data)
                      }
                      resolve(response)
                  })
                  .catch(err => { 
                      commit('reset_user')
                      localStorage.removeItem('token')
                      reject(err)
                  })
              })
        }
    } 
}