<template>
  <div> 
    <table width="100%" cellspacing="1" cellpadding="5" border="0">
        <tbody>
            <tr>
              <td><b>Find Location</b></td>
            </tr>
            <tr>
                <td>
                  <gmap-autocomplete placeholder="Input Location"  :options="optionAutocomplete" @place_changed="setPlace($event)"> </gmap-autocomplete>
                </td>
                <td>
                  <LinkButton :plain="true" :iconCls="iconUtil.iconDelete" @click="onClearDrawing()">{{ informationUtil.clearPolygon }}</LinkButton>
                  <LinkButton :plain="true" :iconCls="iconUtil.iconAdd" @click="onSaveDrawing">{{ informationUtil.savePolygon }}</LinkButton>
                </td>
            </tr>
        </tbody>
    </table>
    <br/>
    <gmap-map ref="mainMaps" :center="maps.center" :zoom="maps.zoom" style="width: 100%; height: 365px;">
      <!-- For Info Window and Marker -->
      <gmap-info-window ref="infoWindow" :options="infoWindow" :position="infoWindow.position" :opened="infoWindow.opened" @closeclick="infoWindow.opened=false"></gmap-info-window>      
      <gmap-marker @drag="onDragMarker($event)" @dragend="onDragMarker($event)" :draggable="true" :clickable="true" :position="marker.position" @click="center=marker.position"></gmap-marker>

      <!-- For polygon and  -->
      <gmap-polygon ref="polygon" v-for="(path, i) in polygons" v-bind:key="i" :paths="path" :options="polygonSetting" @paths_changed="onUpdatePolygon($event, i)"></gmap-polygon>
      <!-- For drawing polygon -->
      <gmap-drawing-manager 
        ref="drawingManager"
        :shapes="shapes" 
        :drawingControl="drawingControl"
        :polygonOptions="polygonOptions"
        position="TOP_CENTER">
      </gmap-drawing-manager>
    </gmap-map>
  </div>
</template>

<script>

import InformationUtil from '@/utils/InformationUtil';
import IconUtil from '@/utils/IconUtil';

export default {
  name: 'GoogleMapComponent',
  props: {
    centerMaps : {
      type: Object,
      default: () => {
        return { lat: -8.6091131, lng: 115.2006365 };
      },
    },
    zoomMaps : {
      type: Number,
      default: 13,
    },
    geofenceData : {
      type: String,
      default: '',
    }
  },
  created () {
    this.polygons = [];
    this.polygonsEdit = [];
    if(this.geofenceData != '' && this.geofenceData != null && this.geofenceData != undefined){
      let arrGeofence = this.geofenceData.split(',');
      arrGeofence.forEach((item) => {
        this.$gmapApiPromiseLazy().then((gmapApi) => {
          this.polygons.push(gmapApi.maps.geometry.encoding.decodePath(item));
          this.polygonsEdit.push(gmapApi.maps.geometry.encoding.decodePath(item));
        });
      });
    }
  },
  data() {
    return {
      iconUtil : IconUtil,
      informationUtil : InformationUtil,

      // for geo location
      valLocation : [],

      // setting maps
      maps : {
        center: this.$props.centerMaps,
        zoom: this.$props.zoomMaps,
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
      },

      // auto complete address
      optionAutocomplete : {
        componentRestrictions: { country: 'id' },
        fields: ["formatted_address", "geometry", "name"],
        strictBounds: true,
      },
      
      // marker (done)
      marker: {
        position: this.$props.centerMaps,
      },

      infoWindow : {
        content: '<h4>Drag to change the location</h4>',
        opened : true,
        position: this.$props.centerMaps,
        pixelOffset: {
          width: 0,
          height: -35
        }
      },


      // drawingManager
      polygons: [],
      polygonsEdit: [],
      polygonSetting: {
        fillColor: '#2d89ef',
        fillOpacity: 0.5,
        strokeWeight: 5,
        clickable: false,
        editable : true,
        zIndex: 1
      },

      shapes: [],
      drawingMode: 'polygon',
      drawingControl: true,
      polygonOptions: {
        fillColor: '#2d89ef',
        fillOpacity: 0.5,
        strokeWeight: 5,
        clickable: false,
        editable: true,
        zIndex: 1
      }
    }
  },
  methods: {
    // auto complete
    setPlace(place) {
      if(!place.geometry) {
        this.$messager.alert({
                              title: "Error",
                              icon: "error",
                              msg: "Please input the correct address"
                          });
        return;
      }
      
      this.infoWindow.opened = false;
      this.marker.position = place.geometry.location;

      if (place.geometry.viewport) {
        this.maps.center = place.geometry.location;
        this.maps.zoom = 13;
      } else {
        this.maps.center = place.geometry.location;
        this.maps.zoom = 17;
      }

      this.infoWindow.position = this.marker.position;
      this.infoWindow.content = '<h4>Drag to change the location</h4>';
      this.infoWindow.opened = true;
      
      this.$emit('onChangeLocation', this.marker.position);
      this.$emit('setRadius', this.maps.zoom);
    },

    // marker
    onDragMarker(e) {
      let position = {
        lat: e.latLng.lat(),
        lng: e.latLng.lng()
      }
      this.infoWindow.position = position;
      this.infoWindow.opened = true;

      this.marker.position = position;

      this.$emit('onDragMarker', position);
      this.$emit('setRadius', this.maps.zoom);
    },
    
    // polygon
    onUpdatePolygon(e, i) {
      this.polygons[i] = e.getAt(0).getArray();

      // save valLocation
      this.onReSaveEncode();
    },

    // drawingManager
    onClearDrawing(){
      this.$refs.drawingManager.clearAll();
      this.polygons = [];      
      this.onReSaveEncode();
    },
    onSaveDrawing(){
      this.$refs.drawingManager.clearAll();
      this.polygons = [];
      let data = this.$refs.drawingManager.finalShapes;
      data.map((item) => {
        let polygon = item.overlay.latLngs.getAt(0).getArray();
        this.polygons.push(polygon);
      });

      this.polygonsEdit.map((item) => {
        this.polygons.push(item);
      });

      // save valLocation
      this.onReSaveEncode();
    },

    async onReSaveEncode(){
      this.valLocation = [];
      this.polygons.map((polygon) => {
        this.$gmapApiPromiseLazy().then((gmapApi) => {
          this.valLocation.push(gmapApi.maps.geometry.encoding.encodePath(polygon));
          this.$emit('setEncode', this.valLocation.toString())
        });
      });

      this.$emit('setEncode', this.valLocation.toString())
    },
  },
};
</script>

<style>
.pac-target-input{
  font-family: open sans, tahoma, sans-serif;
  font-size: 11px !important;
  border-radius: 5px 5px 5px 5px;
  border: none;
  width: 100%;
  padding: 5px;
}

.pac-container{
  z-index: 9099 !important;
}
</style>