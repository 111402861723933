<template>
    <div>
        <Dialog ref="dlg" :iconCls="icon" bodyCls="f-column" :title="title" :draggable="true" :modal="true" closed :dialogStyle="{width:'750px', height:'600px'}">
            <div class="f-full" style="overflow:auto">
                <Form ref="form" :model="model">
                    <fieldset class="c-fieldset">
                        <legend class="c-legend"><b>{{ titleLegend }}</b></legend>
                        <table width="100%" cellspacing="1" cellpadding="5" border="0">
                            <tbody>
                                <tr>
                                    <td>Manifest ID</td>
                                    <td>
                                        <NumberBox class="f-field-text" v-model="modelNotMapping.manifest_id" :disabled="true" :spinners="false"></NumberBox>                                      
                                    </td>
                                    <td>Transporter</td>
                                    <td>
                                        <TextBox class="f-field-text" v-model="modelNotMapping.transporter_id" :disabled="true"></TextBox>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Client ID</td>
                                    <td>
                                        <TextBox class="f-field-text" v-model="modelNotMapping.client_id" :disabled="true"></TextBox>
                                    </td>
                                    <td>&nbsp;</td>
                                    <td>
                                        <TextBox class="f-field-text" v-model="modelNotMapping.transporter_name" :disabled="true"></TextBox>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Origin</td>
                                    <td>
                                        <TextBox class="f-field-text" v-model="modelNotMapping.origin_desc" :disabled="true"></TextBox>
                                    </td>
                                    <td>Vehicle Type</td>
                                    <td>
                                        <TextBox class="f-field-text" v-model="modelNotMapping.vehicle_type" :disabled="true"></TextBox>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Destination</td>
                                    <td>
                                        <TextBox class="f-field-text" v-model="modelNotMapping.dest_desc" :disabled="true"></TextBox>
                                    </td>
                                    <td>Status</td>
                                    <td>
                                        <TextBox class="f-field-text" v-model="modelNotMapping.vehicle_status" :disabled="true"></TextBox>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </fieldset>
                    <fieldset class="c-fieldset">
                        <legend class="c-legend"><b>{{ infoDetail }}</b></legend>
                        <Tabs style="height:355px">
                            <TabPanel :title="'Transporter'">
                                <fieldset class="c-fieldset">
                                    <legend class="c-legend"><b>Rates</b></legend>
                                    <table width="100%" cellspacing="1" cellpadding="5" border="0">
                                        <tbody>
                                            <tr>
                                                <td style="width: 14%">Vehicle Rate</td>
                                                <td style="width: 35%">
                                                    <NumberBox class="f-field-text" v-model="model.variable_cost" :disabled="disableTransporterRate" :spinners="false" ></NumberBox>                                      
                                                </td>
                                                <td>
                                                    <LinkButton v-if="disableTransporterRate && !disableTransporterInvoice" :iconCls="iconUtil.iconEdit" style="border:none;" @click="onEditTransporterRate()">{{btnText.edit}}</LinkButton>
                                                    <LinkButton v-if="!disableTransporterRate && !disableTransporterInvoice" :iconCls="iconUtil.iconSave" style="border:none;" @click="onSaveTransporterRate()">{{btnText.save}}</LinkButton>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </fieldset>                                
                                <fieldset class="c-fieldset">
                                    <legend class="c-legend"><b>Cost Component</b></legend>
                                    <table width="100%" cellspacing="0" cellpadding="2" border="0">
                                        <tbody>
                                            <tr>
                                                <td style="width:50%;" align="left">
                                                    Component :
                                                    <TextBox class="f-field-text" name="component_desc" @input="onUpperComponentTransporter" v-model="modelInputTransporter.component_desc"></TextBox>
                                                </td>
                                                <td style="width:15%;padding-left: 10px;" align="left">
                                                    Quantity :
                                                    <NumberBox inputId="qty" name="qty" class="f-field-text" :spinners="false" v-model="modelInputTransporter.qty"></NumberBox>
                                                </td>
                                                <td style="width:30%;padding-left: 10px;" align="left">
                                                    Rate :
                                                    <NumberBox inputId="price" name="price" class="f-field-text" :spinners="false" v-model="modelInputTransporter.price">
                                                    </NumberBox>
                                                </td>
                                                <td style="width:5%;padding-left: 10px;" align="left">
                                                    &nbsp;&nbsp;
                                                    <LinkButton v-if="!disableTransporterInvoice" :iconCls="iconUtil.iconAdd" style="border:none;" @click="addTransporter()"></LinkButton>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <Panel>
                                        <div class="datagrid-toolbar" style="margin-bottom: 10px;">
                                            {{ search.text }} : 
                                            <SearchBox style="width:300px"
                                                :placeholder="search.placeholder" 
                                                v-model="dataGridTransporter.search"
                                                @search="onSearchDetailTransporter()">
                                                <Addon>
                                                    <span v-if="dataGridTransporter.search" class="textbox-icon icon-clear" title="Clear value" @click="dataGridTransporter.search=null;onSearchDetailTransporter();"></span>
                                                </Addon>
                                            </SearchBox>
                                            <LinkButton v-if="action.delete && !disableTransporterInvoice" :plain="true" :iconCls="iconUtil.iconDelete" @click="onDeleteTransporter()">{{ btnText.delete }}</LinkButton>
                                        </div>
                                        <DataGrid
                                                ref="dataGridTransporter"
                                                :columnResizing="true"
                                                :border="false"
                                                :loading="dataGridTransporter.loading"                                    
                                                :data="dataGridTransporter.rows"
                                                :pagination="true"
                                                :selectionMode="dataGridTransporter.selectionMode"
                                                :total="dataGridTransporter.total"
                                                :pageSize="dataGridTransporter.pageSize"
                                                :pageNumber="dataGridTransporter.pageNumber"
                                                :pageLayout="dataGridTransporter.pageLayout"
                                                :footerData="dataGridTransporter.footerData"
                                                :showFooter="true"
                                                style="width: 630px;height:386px">
                                            <GridColumn field="component_desc" title="Service Task" width="150"></GridColumn>
                                            <GridColumn field="qty" title="Qty"  width="50" align="right"></GridColumn>
                                            <GridColumn field="price" title="Rate" width="80" align="right"></GridColumn>
                                            <GridColumn field="amount" title="Amount" width="80" align="right"></GridColumn>
                                        </DataGrid>
                                    </Panel>
                                </fieldset> 
                            </TabPanel>
                            <TabPanel :title="'Client'">
                                <fieldset class="c-fieldset">
                                    <legend class="c-legend"><b>Rates</b></legend>
                                    <table width="100%" cellspacing="1" cellpadding="5" border="0">
                                        <tbody>
                                            <tr>
                                                <td style="width: 14%">Vehicle Rate</td>
                                                <td style="width: 35%">
                                                    <NumberBox class="f-field-text" v-model="model.client_variable_cost" :disabled="disableClientRate" :spinners="false" ></NumberBox>                                      
                                                </td>
                                                <td>
                                                    <LinkButton v-if="disableClientRate && !disableClientInvoice" :iconCls="iconUtil.iconEdit" style="border:none;" @click="onEditClientRate()">{{btnText.edit}}</LinkButton>
                                                    <LinkButton v-if="!disableClientRate && !disableClientInvoice" :iconCls="iconUtil.iconSave" style="border:none;" @click="onSaveClientRate()">{{btnText.save}}</LinkButton>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </fieldset>
                                <fieldset class="c-fieldset">
                                    <legend class="c-legend"><b>Cost Component</b></legend>
                                    <table width="100%" cellspacing="0" cellpadding="2" border="0">
                                        <tbody>
                                            <tr>
                                                <td style="width:50%;" align="left">
                                                    Component :
                                                    <TextBox class="f-field-text" name="component_desc" @input="onUpperComponentClient" v-model="modelInputClient.component_desc"></TextBox>
                                                </td>
                                                <td style="width:15%;padding-left: 10px;" align="left">
                                                    Quantity :
                                                    <NumberBox inputId="qty" name="qty" class="f-field-text" :spinners="false" v-model="modelInputClient.qty"></NumberBox>
                                                </td>
                                                <td style="width:30%;padding-left: 10px;" align="left">
                                                    Rate :
                                                    <NumberBox inputId="price" name="price" class="f-field-text" :spinners="false" v-model="modelInputClient.price">
                                                    </NumberBox>
                                                </td>
                                                <td style="width:5%;padding-left: 10px;" align="left">
                                                    &nbsp;&nbsp;
                                                    <LinkButton v-if="!disableClientInvoice" :iconCls="iconUtil.iconAdd" style="border:none;" @click="addClient()"></LinkButton>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <Panel>
                                        <div class="datagrid-toolbar" style="margin-bottom: 10px;">
                                            {{ search.text }} : 
                                            <SearchBox style="width:300px"
                                                :placeholder="search.placeholder" 
                                                v-model="dataGridClient.search"
                                                @search="onSearchDetailClient()">
                                                <Addon>
                                                    <span v-if="dataGridClient.search" class="textbox-icon icon-clear" title="Clear value" @click="dataGridClient.search=null;onSearchDetailClient();"></span>
                                                </Addon>
                                            </SearchBox>
                                            <LinkButton v-if="action.delete && !disableClientInvoice" :plain="true" :iconCls="iconUtil.iconDelete" @click="onDeleteClient()">{{ btnText.delete }}</LinkButton>
                                        </div>
                                        <DataGrid
                                                ref="dataGridClient"
                                                :columnResizing="true"
                                                :border="false"
                                                :loading="dataGridClient.loading"                                    
                                                :data="dataGridClient.rows"
                                                :pagination="true"
                                                :selectionMode="dataGridClient.selectionMode"
                                                :total="dataGridClient.total"
                                                :pageSize="dataGridClient.pageSize"
                                                :pageNumber="dataGridClient.pageNumber"
                                                :pageLayout="dataGridClient.pageLayout"
                                                :footerData="dataGridClient.footerData"
                                                :showFooter="true"
                                                style="width: 630px;height:386px">
                                            <GridColumn field="component_desc" title="Service Task" width="150"></GridColumn>
                                            <GridColumn field="qty" title="Qty"  width="50" align="right"></GridColumn>
                                            <GridColumn field="price" title="Rate" width="80" align="right"></GridColumn>
                                            <GridColumn field="amount" title="Amount" width="80" align="right"></GridColumn>
                                        </DataGrid>
                                    </Panel>
                                </fieldset> 
                            </TabPanel>
                        </Tabs>
                    </fieldset>
                </Form>
            </div>
            <div class="dialog-button">
                <LinkButton :iconCls="iconUtil.iconSave" v-if="action.add || action.edit" @click="onSaveData()" :disabled="disableBtnSave" style="width:89px">{{ btnText.save }}</LinkButton>
                <LinkButton :iconCls="iconUtil.iconCancel" @click="onPageClose" style="width:89px">{{ btnText.close }}</LinkButton>
            </div>
        </Dialog>
        
        <NotificationComponent ref="notification"></NotificationComponent>
    </div>
</template>
<script>

import NotificationComponent from '@/components/NotificationComponent.vue';

import ManifestRate from '@/models/ManifestRate';
import TransportService from '@/services/TransportService';

import InformationUtil from '@/utils/InformationUtil';
import AccessUtil from '@/utils/AccessUtil';
import ModelUtil from '@/utils/ModelUtil';
import IconUtil from '@/utils/IconUtil';
import GlobalUtil from '@/utils/GlobalUtil';

export default{
    name: 'FormManifestRateComponent',
    components : {
        NotificationComponent
    },
    props : {
        actionData : {
            type : String,
            default : ''
        }
    },
    data(){
        return {
            title : InformationUtil.editText + InformationUtil.manifest + " Rates",
            titleLegend : InformationUtil.manifest,
            titleMessage : InformationUtil.manifestRate,
            
            infoDetail : InformationUtil.infoDetail,
            
            iconUtil : IconUtil,
            icon : IconUtil.iconEdit,
            disableBtnSave : false,
            disableTransporterInvoice : false,
            disableClientInvoice : false,
            disableTransporterRate : true,
            disableClientRate : true,

            dataGridTransporter : {
                loading: false,
                rows : [],
                rowsTemp : [],
                total: 0,
                start: 0,
                pageNumber: 1,
                pageSize: 10,
                selectionMode : 'single',
                pageLayout: ['list','sep','first','prev','next','last','sep','refresh','info'],

                // filter
                search : null,
                sortField : "",
                sortOrder : "",

                // footer
                footerData : [
                    { price: "Total:", amount: 0 }
                ]
            },

            dataGridClient : {
                loading: false,
                rows : [],
                rowsTemp : [],
                total: 0,
                start: 0,
                pageNumber: 1,
                pageSize: 10,
                selectionMode : 'single',
                pageLayout: ['list','sep','first','prev','next','last','sep','refresh','info'],

                // filter
                search : null,
                sortField : "",
                sortOrder : "",

                // footer
                footerData : [
                    { price: "Total:", amount: 0 }
                ]
            },

            model : ManifestRate,
            modelNotMapping : {
                manifest_id : "",
                client_id : "",
                origin_desc : "",
                dest_desc : "",
                transporter_id : "",
                transporter_name : "",
                vehicle_type : "",
                vehicle_status : "",
                invsal_hash_id : null,
                invpch_hash_id : null,
            },

            modelInputTransporter : {
                component_desc : "",
                qty : 0,
                price : 0,
                amount : 0
            },

            modelInputClient : {
                component_desc : "",
                qty : 0,
                price : 0,
                amount : 0
            },

            modelTotal : {
                total_transporter : 0,
                total_client : 0
            },

            action : {
                view : false,
                add : false,
                edit : false,
                delete : false
            },
            btnText :{
                refresh : InformationUtil.refreshText,
                add : InformationUtil.addText,
                edit : InformationUtil.editText,
                delete : InformationUtil.removeText,
                save : InformationUtil.saveText,
                cancel : InformationUtil.cancelText,
                search : InformationUtil.searchText,
                download : InformationUtil.downloadText,
                close : InformationUtil.closeText
            },
            search : {
                text : InformationUtil.searching,
                placeholder : InformationUtil.searchHolder
            },

            // combobox
            compTransporters : [],
            compClients : []
        }
    },
    methods : {
        onUpperComponentTransporter(evt) {
            if(evt != null && evt != ''){
                this.modelInputTransporter.component_desc = evt.toUpperCase();
            }
        },
        onUpperComponentClient(evt) {
            if(evt != null && evt != ''){
                this.modelInputClient.component_desc = evt.toUpperCase();
            }
        },
        onPageShow(id){
            this.action = AccessUtil.getAccessAction(this.$props.actionData);
            if(this.action.view && id != null && id != undefined){
                let param = {
                    id : id
                }
                this.disableTransporterRate = true;
                this.disableClientRate = true;
                TransportService.post('/manifest/rate', param)
                    .then((response) => {
                        let dataResponse = response;
                        if(dataResponse.data !== "" && dataResponse.data !== null){

                            this.model = ModelUtil.resetModel(this.model);
                            this.model = ModelUtil.mapModel(this.model, dataResponse.data);

                            this.modelNotMapping = ModelUtil.resetModel(this.modelNotMapping);
                            this.modelNotMapping = ModelUtil.mapModel(this.modelNotMapping, dataResponse.data);

                            this.model.variable_cost = parseInt(this.model.variable_cost);
                            this.model.client_variable_cost = parseInt(this.model.client_variable_cost);
                            
                            this.modelTotal = ModelUtil.resetModelByType(this.modelTotal);

                            this.dataGridTransporter.rows = this.model.component_entries != null ? JSON.parse(this.model.component_entries) : null;
                            this.dataGridTransporter.rowsTemp = this.dataGridTransporter.rows;
                            this.onSumDataTotalTransporter(); 

                            this.dataGridClient.rows = this.model.component_entries != null ? JSON.parse(this.model.client_component_entries) : null;
                            this.dataGridClient.rowsTemp = this.dataGridClient.rows;
                            this.onSumDataTotalClient();
                            
                            this.disableTransporterInvoice = this.modelNotMapping.invpch_hash_id != null && this.modelNotMapping.invpch_hash_id != "" ? true : false;
                            this.disableClientInvoice = this.modelNotMapping.invsal_hash_id != null && this.modelNotMapping.invsal_hash_id != "" ? true : false;

                            this.$refs.dlg.open();
                        }
                    });
                }
        },
        onPageClose(){
            this.$refs.dlg.close();
        },
        onSaveData(){
            
            this.model.component_entries = this.dataGridTransporter.rowsTemp;
            this.onSumDataTotalTransporter();
            this.model.sum_component_cost = this.modelTotal.total_transporter;

            this.model.client_component_entries = this.dataGridClient.rowsTemp;
            this.onSumDataTotalClient();
            this.model.client_sum_component_cost = this.modelTotal.total_client;

            TransportService.updateData('/manifest/updateRate', this.modelNotMapping.manifest_id, this.model)
                .then((response) => {
                    let dataResponse = response;
                    if(dataResponse.data !== "" && dataResponse.data !== null){
                        this.$refs.notification.success(this.titleMessage + " " + InformationUtil.update);

                        this.$refs.dlg.close();
                        
                        this.$emit('onSaveData', this.model);
                    }
                }).catch((err) => {
                    this.$refs.notification.error(err.data.message);
                })
        },

        // transporter
        onEditTransporterRate(){
            this.disableTransporterRate = false;
        },
        onSaveTransporterRate(){
            let param = {
                variable_cost : this.model.variable_cost
            }
            TransportService.updateData('/manifest/updateRate', this.modelNotMapping.manifest_id, param)
                .then((response) => {
                    let dataResponse = response;
                    if(dataResponse.data !== "" && dataResponse.data !== null){
                        this.$refs.notification.success(this.titleMessage + " " + InformationUtil.update);
                        this.disableTransporterRate = true;
                    }
                }).catch((err) => {
                    this.$refs.notification.error(err.data.message);
                })
        },

        // client
        onEditClientRate(){
            this.disableClientRate = false;
        },
        onSaveClientRate(){
            let param = {
                client_variable_cost : this.model.client_variable_cost
            }
            TransportService.updateData('/manifest/updateRate', this.modelNotMapping.manifest_id, param)
                .then((response) => {
                    let dataResponse = response;
                    if(dataResponse.data !== "" && dataResponse.data !== null){
                        this.$refs.notification.success(this.titleMessage + " " + InformationUtil.update);
                        this.disableClientRate = true;
                    }
                }).catch((err) => {
                    this.$refs.notification.error(err.data.message);
                })
        },

        //grid transporter
        onDeleteTransporter(){
            if(this.$refs.dataGridTransporter.selectedRows.length > 0){
                this.$messager.confirm({
                    title: 'Confirm',
                    msg: InformationUtil.confirmDelete,
                    result: (r) => {
                        if (r){
                            this.dataGridTransporter.loading = true;
                            this.dataGridTransporter.rows.splice(this.$refs.dataGridTransporter.selectedRows[0].index, 1);
                            this.dataGridTransporter.loading = false;
                            this.$refs.notification.success(this.titleMessage + " " + InformationUtil.delete);
                            this.$refs.dataGridTransporter.unselectRow(this.$refs.dataGridTransporter.selectedRows[0]);
                            this.onSumDataTotalTransporter();
                            this.dataGridTransporter.rowsTemp = this.dataGridTransporter.rows;
                        }
                    }
                })
            }else{
                this.$messager.alert({
                    title: "Info",
                    icon: "info",
                    msg: InformationUtil.selectRow
                });
            }
        },
        addTransporter(){
            if(this.dataGridTransporter.rows == null) this.dataGridTransporter.rows = [];
            // validation
            if(this.modelInputTransporter.component_desc == null || this.modelInputTransporter.component_desc == ""){
                this.$messager.alert({
                    title: "Error",
                    icon: "error",
                    msg: "Component is required"
                });
                return;
            }

            if(this.modelInputTransporter.qty == null || this.modelInputTransporter.qty == "" || this.modelInputTransporter.qty == 0){
                this.$messager.alert({
                    title: "Error",
                    icon: "error",
                    msg: "Qty is required"
                });
                return;
            }

            if(this.modelInputTransporter.price == null || this.modelInputTransporter.price == "" || this.modelInputTransporter.price == 0){
                this.$messager.alert({
                    title: "Error",
                    icon: "error",
                    msg: "Rate is required"
                });
                return;
            }

            let data = {
                component_desc : this.modelInputTransporter.component_desc,
                qty : parseInt(this.modelInputTransporter.qty),
                price : parseInt(this.modelInputTransporter.price),
                amount : parseInt(this.modelInputTransporter.qty) * parseInt(this.modelInputTransporter.price)
            }
            this.dataGridTransporter.rows.push(data);
            this.dataGridTransporter.rowsTemp = this.dataGridTransporter.rows;
            this.dataGridTransporter.total = this.dataGridTransporter.rows.length;
            this.onSumDataTotalTransporter();

            ModelUtil.resetModel(this.modelInputTransporter);
        },
        onSearchDetailTransporter(){
            let search = this.dataGridTransporter.search;
            let rows = this.dataGridTransporter.rowsTemp;
            let result = [];
            if(search != null && search != ""){
                for(let i = 0; i < rows.length; i++){
                    if(rows[i].component_desc.toLowerCase().indexOf(search.toLowerCase()) > -1){
                        result.push(rows[i]);
                    }
                }
                this.dataGridTransporter.rows = result;
            }else{
                this.dataGridTransporter.rows = this.dataGridTransporter.rowsTemp;
            }
        },
        onSumDataTotalTransporter(){
            let amount = 0;
            if(this.dataGridTransporter.rowsTemp != null && this.dataGridTransporter.rowsTemp.length > 0){
                this.dataGridTransporter.rowsTemp.forEach((item) => {
                    amount += item.amount;
                });
            }
            this.modelTotal.total_transporter = amount;

            this.dataGridTransporter.footerData[0].amount = this.modelTotal.total_transporter;
        },

        // grid component client
        onDeleteClient(){
            if(this.$refs.dataGridClient.selectedRows.length > 0){
                this.$messager.confirm({
                    title: 'Confirm',
                    msg: InformationUtil.confirmDelete,
                    result: (r) => {
                        if (r){
                            this.dataGridClient.loading = true;
                            this.dataGridClient.rows.splice(this.$refs.dataGridClient.selectedRows[0].index, 1);
                            this.dataGridClient.loading = false;
                            this.$refs.notification.success(this.titleMessage + " " + InformationUtil.delete);
                            this.$refs.dataGridClient.unselectRow(this.$refs.dataGridClient.selectedRows[0]);
                            this.onSumDataTotalClient();
                            this.dataGridClient.rowsTemp = this.dataGridClient.rows;
                        }
                    }
                })
            }else{
                this.$messager.alert({
                    title: "Info",
                    icon: "info",
                    msg: InformationUtil.selectRow
                });
            }
        },
        addClient(){
            if(this.dataGridClient.rows == null) this.dataGridClient.rows = [];
            // validation
            if(this.modelInputClient.component_desc == null || this.modelInputClient.component_desc == ""){
                this.$messager.alert({
                    title: "Error",
                    icon: "error",
                    msg: "Component is required"
                });
                return;
            }

            if(this.modelInputClient.qty == null || this.modelInputClient.qty == "" || this.modelInputClient.qty == 0){
                this.$messager.alert({
                    title: "Error",
                    icon: "error",
                    msg: "Qty is required"
                });
                return;
            }

            if(this.modelInputClient.price == null || this.modelInputClient.price == "" || this.modelInputClient.price == 0){
                this.$messager.alert({
                    title: "Error",
                    icon: "error",
                    msg: "Rate is required"
                });
                return;
            }

            let data = {
                component_desc : this.modelInputClient.component_desc,
                qty : parseInt(this.modelInputClient.qty),
                price : parseInt(this.modelInputClient.price),
                amount : parseInt(this.modelInputClient.qty) * parseInt(this.modelInputClient.price)
            }
            this.dataGridClient.rows.push(data);
            this.dataGridClient.rowsTemp = this.dataGridClient.rows;
            this.dataGridClient.total = this.dataGridClient.rows.length;
            this.onSumDataTotalClient();

            ModelUtil.resetModel(this.modelInputClient);
        },
        onSearchDetailClient(){
            let search = this.dataGridClient.search;
            let rows = this.dataGridClient.rowsTemp;
            let result = [];
            if(search != null && search != ""){
                for(let i = 0; i < rows.length; i++){
                    if(rows[i].component_desc.toLowerCase().indexOf(search.toLowerCase()) > -1){
                        result.push(rows[i]);
                    }
                }
                this.dataGridClient.rows = result;
            }else{
                this.dataGridClient.rows = this.dataGridClient.rowsTemp;
            }
        },
        onSumDataTotalClient(){
            let amount = 0;
            if(this.dataGridClient.rowsTemp != null && this.dataGridClient.rowsTemp.length > 0){
                this.dataGridClient.rowsTemp.forEach((item) => {
                    amount += item.amount;
                });
            }
            this.modelTotal.total_client = amount;

            this.dataGridClient.footerData[0].amount = this.modelTotal.total_client;
        },

    },
    created(){
        GlobalUtil.removeWindow();
    }
}
</script>