<template>
    <div>
        <Panel :title="titleView"  style="margin:0px 1px; ">
            <div class="datagrid-toolbar">
                <LinkButton :plain="true" :iconCls="iconUtil.iconRefresh" @click="onRefresh()">{{ btnText.refresh }}</LinkButton>
                <span class="button-sep"></span> Status : 
                <ComboBox  :data="statusData" inputId="searchStatusData" name="searchStatusData" class="f-field-text" style="width:75px; margin-left: 5px; margin-right: 5px;" v-model="searchStatusData" @selectionChange="onSelectionStatusData($event)"></ComboBox>
                <span class="button-sep"></span> {{ search.text }} : 
                <SearchBox style="width:300px"
                    :placeholder="search.placeholder" 
                    v-model="dataGrid.search"
                    @search="onSearch()">
                    <Addon>
                        <span v-if="dataGrid.search" class="textbox-icon icon-clear" title="Clear value" @click="dataGrid.search=null;onSearch();"></span>
                    </Addon>
                </SearchBox>
            </div>
            <div @contextmenu.prevent="$refs.menuManifest.showContextMenu($event.pageX,$event.pageY)">
                <DataGrid
                    ref="dataGrid"
                    :columnResizing="true"
                    :border="false"
                    :lazy="true"
                    :loading="dataGrid.loading"                                    
                    :data="dataGrid.rows"
                    :pagination="true"
                    :selectionMode="dataGrid.selectionMode"
                    :total="dataGrid.total"
                    :pageSize="dataGrid.pageSize"
                    :pageNumber="dataGrid.pageNumber"
                    :pageLayout="dataGrid.pageLayout" 
                    @pageChange="onPageChange($event)"
                    @rowDblClick="onRowDblClick()"
                    groupField="manifest_number"
                    :pageList="dataGrid.pageList" 
                    @selectionChange="onSelectionChange($event)"
                    >
                    <GridColumnGroup :frozen="true" align="left" width="370">
                        <GridHeaderRow>
                            <GridColumn field="ck" :width="50" align="center">
                                <template slot="header">
                                    <CheckBox ref="allDataChecked" v-model="dataGrid.allChecked" @checkedChange="onAllCheckedChange($event)"></CheckBox>
                                </template>
                                <template slot="body" slot-scope="scope">
                                    <CheckBox v-model="scope.row.checked" @checkedChange="onCheckedChange($event)"></CheckBox>
                                </template>
                            </GridColumn>
                            <GridColumn field="reference_transport_no" title="Reference" :frozen="true"></GridColumn>
                            <GridColumn field="do_number" title="DO Number" :frozen="true"></GridColumn>
                            <GridColumn field="schedule_date" title="Schedule Date" :frozen="true" width="100"></GridColumn>
                            <GridColumn field="trip_no" title="Trip" :frozen="true" width="30"></GridColumn>
                        </GridHeaderRow>
                    </GridColumnGroup>
                    <GridColumnGroup>
                        <GridHeaderRow>
                            <GridColumn field="vehicle_id" title="Vehicle Id" width="150"></GridColumn>
                            <GridColumn field="transporter_id" title="Transporter" width="200"></GridColumn>
                            <GridColumn field="origin_name" title="Origin Name" width="200"></GridColumn>
                            <GridColumn field="origin_address" title="Origin Address" width="300"></GridColumn>
                            <GridColumn field="origin_area_id" title="Origin Area" width="100"></GridColumn>
                            <GridColumn field="dest_name" title="Destination Name" width="200"></GridColumn>
                            <GridColumn field="dest_address" title="Destination Address" width="300"></GridColumn>
                            <GridColumn field="dest_area_id" title="Destination Area" width="100"></GridColumn>
                            <GridColumn field="arrival_ata" title="Arrival Time" width="100"></GridColumn>
                            <GridColumn field="pod_time_desc" title="Pod Time" width="100"></GridColumn>
                            <GridColumn field="pod_code" title="Pod Code" width="100"></GridColumn>
                            <GridColumn field="pod_code_desc" title="Pod Description" width="200"></GridColumn>
                            <GridColumn field="pending_pod_code" title="Pod Pending Code" width="100"></GridColumn>
                            <GridColumn field="pending_pod_desc" title="Pod Pending Description" width="200"></GridColumn>
                            <GridColumn field="remark" title="Pod Remark" width="500"></GridColumn>
                            <GridColumn field="submit_time_desc" title="Pod Submit Time" width="100"></GridColumn>
                        </GridHeaderRow>
                    </GridColumnGroup>
                </DataGrid>
                <Menu ref="menuManifest" :menuWidth="200" @itemClick="onMenuManifest($event)">
                    <MenuItem value="onSetPod"  :iconCls="iconUtil.iconEdit" text="Set Pod"></MenuItem>
                    <MenuItem value="onViewManifest"  :iconCls="iconUtil.iconEdit" text="View Manifest"></MenuItem>
                </Menu>
            </div>
            
        </Panel>

        <!-- Form -->
        <Dialog ref="dlg" :iconCls="iconUtil.iconEdit" bodyCls="f-column" :title="title" :draggable="true" :modal="true" closed :dialogStyle="{width:'490px', height:'540px'}">
            <div class="f-full" style="overflow:auto">
                <Form ref="form" :model="model" :rules="rules" @validate="errors=$event">
                    <Tabs>
                        <TabPanel :title="'POD Option'">
                            <table width="100%" cellspacing="1" cellpadding="5" border="0">
                                <tbody>
                                    <tr>
                                        <td>Set POD Recursively</td>
                                        <td>
                                            <CheckBox name="pod_recursive" v-model="modelNotMapping.pod_recursive"></CheckBox>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="width: 25%">
                                            Pending POD <br>
                                            Code
                                        </td>
                                        <td> 
                                            <ComboBox  :data="componentPendingPod" inputId="pending_code" name="pending_code" @filterChange="optCompPendingPod($event)" @selectionChange="onSelectPendingPod($event)" class="f-field-text" v-model="model.pending_code"></ComboBox>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            PIC
                                        </td>
                                        <td> 
                                            <TextBox class="f-field-text" v-model="modelNotMapping.picPendingPod" :disabled="true"></TextBox>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>POD Time <font color="red">*</font></td>
                                        <td>
                                            <DateBox inputId="pod_time" name="pod_time" class="f-field-text text-60" v-model="model.pod_time" format="dd/MM/yyyy"></DateBox>
                                            <div class="error">{{textError.pod_time}}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Code</td>
                                        <td> 
                                            <ComboBox  :data="componentPod" inputId="code" name="code" @filterChange="optCompPod($event)" @selectionChange="onSelectPod($event)" class="f-field-text" v-model="model.code"></ComboBox>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            PIC
                                        </td>
                                        <td> 
                                            <TextBox class="f-field-text" v-model="modelNotMapping.picPod" :disabled="true"></TextBox>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            KM Finish
                                        </td>
                                        <td> 
                                            <TextBox class="f-field-text" v-model="modelNotMapping.kmFinish" :disabled="true"></TextBox>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Submit Time</td>
                                        <td>
                                            <DateBox inputId="submit_time" name="submit_time" class="f-field-text text-60" v-model="model.submit_time" format="dd/MM/yyyy">
                                                <Addon>
                                                    <span v-if="model.submit_time" class="textbox-icon icon-clear" title="Clear value" @click="model.submit_time=null;"></span>
                                                </Addon>
                                            </DateBox>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Doc.Reference</td>
                                        <td>
                                            <TextBox inputId="doc_reference" name="doc_reference" class="f-field-text" v-model="model.doc_reference"></TextBox>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Receive Time</td>
                                        <td >
                                            <DateBox inputId="receivetime" name="receivetime" class="f-field-text text-60" v-model="model.receivetime" format="dd/MM/yyyy">
                                                <Addon>
                                                    <span v-if="model.receivetime" class="textbox-icon icon-clear" title="Clear value" @click="model.receivetime=null;"></span>
                                                </Addon>
                                            </DateBox>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Receiver</td>
                                        <td>
                                            <TextBox inputId="receiver" name="receiver" class="f-field-text" v-model="model.receiver"></TextBox>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Remark</td>
                                        <td>
                                            <TextBox inputId="remark" name="remark" :multiline="true" class="f-field-text f-field-text-area" v-model="model.remark"></TextBox>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </TabPanel>
                        <TabPanel :title="'Log'">
                            <table width="100%" cellspacing="1" cellpadding="5" border="0">
                                <tbody>
                                    <tr>
                                        <td>
                                            Created By
                                        </td>
                                        <td>
                                            <TextBox inputId="created_by" name="created_by" class="f-field-text" :value="modelNotMapping.created_by" :disabled="true"></TextBox>
                                        </td>
                                        <td>
                                            Created Date
                                        </td>
                                        <td>
                                            <TextBox inputId="created_date" name="created_date" class="f-field-text" :value="modelNotMapping.created_date" :disabled="true"></TextBox>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Updated By
                                        </td>
                                        <td>
                                            <TextBox inputId="updated_by" name="updated_by" class="f-field-text" :value="modelNotMapping.updated_by" :disabled="true"></TextBox>
                                        </td>
                                        <td>
                                            Updated Date
                                        </td>
                                        <td>
                                            <TextBox inputId="updated_date" name="updated_date" class="f-field-text" :value="modelNotMapping.updated_date" :disabled="true"></TextBox>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </TabPanel>
                    </Tabs>
                </Form>
            </div>
            <div class="dialog-button">
                <LinkButton :iconCls="iconUtil.iconSave" v-if="action.add || action.edit" @click="onSave()" :disabled="disableBtnSave" style="width:89px">{{ btnText.save }}</LinkButton>
                <LinkButton :iconCls="iconUtil.iconCancel" @click="$refs.dlg.close()" style="width:89px">{{ btnText.cancel }}</LinkButton>
            </div>
        </Dialog>

        <NotificationComponent ref="notification"></NotificationComponent>
        <FormManifestComponent ref="formManifest" actionData="pod"></FormManifestComponent>
    </div>
    
</template>

<script>
import NotificationComponent from '@/components/NotificationComponent.vue';
import FormManifestComponent from '@/components/forms/FormManifestComponent.vue';

import TransportService from '@/services/TransportService';
import OptionService from '@/services/OptionService';

import ModelUtil from '@/utils/ModelUtil';
import InformationUtil from '@/utils/InformationUtil';
import AccessUtil from '@/utils/AccessUtil';
import IconUtil from '@/utils/IconUtil';
import GlobalUtil from '@/utils/GlobalUtil';

import POD from'@/models/POD';

export default {
    name: 'PODView',
    components : {
        NotificationComponent,
        FormManifestComponent
    },
    data() {
        return {
            titleView : InformationUtil.pod,
            iconUtil : IconUtil,         
            action : {
                view : false,
                add : false,
                edit : false,
                delete : false,
            },

            btnText :{
                refresh : InformationUtil.refreshText,
                add : InformationUtil.addText,
                edit : InformationUtil.editText,
                delete : InformationUtil.removeText,
                save : InformationUtil.saveText,
                cancel : InformationUtil.cancelText,
                search : InformationUtil.searchText
            },

            search : {
                text : InformationUtil.searching,
                placeholder : InformationUtil.searchHolder
            },
            searchStatusData : 0,

            dataGrid : {
                loading: false,
                rows : [],
                total: 0,
                start: 0,
                pageNumber: 1,
                pageSize: 50,
                selectionMode : 'multiple',
                pageLayout: ['list','sep','first','prev','next','last','sep','refresh','info'],
                pageList: [50,100,200,500,1000],

                // filter
                search : null,
                sortField : "",
                sortOrder : "",
                allChecked : false,
                rowClicked : false
            },

            filter : {
                viewStatusMessage : false,
            },

            model : POD,
            modelNotMapping : {
                created_by : "",
                updated_by : "",
                created_date : "",
                updated_date : "",
                picPod : '',
                picPendingPod : '',
                kmFinish : '0',
                pod_recursive : false
            },
            // form
            title: InformationUtil.pod,
            icon: '',
            iconForm :{
                add : IconUtil.iconAdd,
                edit : IconUtil.iconEdit
            },
            disabled : false,
            disableBtnSave : false,

            // validation
            rules: {
                pod_time: 'required',
            },
            errors: {},
            textError :{
                pod_time : "",
            },


            // combobox
            statusData : [
                { value: 0 , text: 'Open' },
                { value: 1 , text: 'Closed' },
                { value: 'all' , text: 'All' },
            ],
            componentPod : [],
            componentPendingPod : []
        }
    },
    methods:{
        getError(name) {
            return this.errors[name] && this.errors[name].length
            ? this.errors[name][0]
            : null;
        },
        // handle data grid
        async onUpdateManifest(){
            this.dataGrid.pageNumber = 1;       
            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);
            await this.loadData();
            this.$refs.notification.success(InformationUtil.refresh);
        },
        async onSelectionStatusData(){
            this.dataGrid.pageNumber = 1;
            await this.loadData();
            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);
            if(this.filter.viewStatusMessage){
                this.$refs.notification.success(InformationUtil.refresh);
            }
            this.filter.viewStatusMessage = true;
        },
        onEdit(){
            ModelUtil.resetModel(this.textError);
            this.model.pod_time = null;
            this.model.receivetime = null;
            this.model.submit_time = null;
            // method for get data to edit
            if(this.$refs.dataGrid.selectedRows.length > 0){
                this.title = InformationUtil.editText;
                this.icon = this.iconForm.edit;
                
                this.onCallAllOption();

                this.model = ModelUtil.resetModel(this.model);
                this.model = ModelUtil.mapModel(this.model, this.$refs.dataGrid.selectedRows[0]);

                this.modelNotMapping = ModelUtil.resetModel(this.modelNotMapping);
                this.modelNotMapping = ModelUtil.mapModel(this.modelNotMapping, this.$refs.dataGrid.selectedRows[0]);
                
                this.model.code = this.model.code == 0 || this.model.code == '' ? '' : this.model.code; 
                this.model.pending_code = this.model.pending_code == 0 || this.model.pending_code == '' ? '' : this.model.pending_code;
                
                this.model.pod_time = ( this.model.pod_time != null && this.model.pod_time != undefined && this.model.pod_time != '') ? new Date(this.model.pod_time) : null;
                this.model.receivetime = ( this.model.receivetime != null && this.model.receivetime != undefined && this.model.receivetime != '') ? new Date(this.model.receivetime) : null;
                this.model.submit_time = ( this.model.submit_time != null && this.model.submit_time != undefined && this.model.submit_time != '') ? new Date(this.model.submit_time) : null;

                this.$refs.dlg.open();
            }else{
                this.$refs.notification.centerTop(InformationUtil.selectRow);
            }
        },
        onSave(){
            ModelUtil.resetModel(this.textError);
            this.$refs.form.validate((valid) => {
                if(!valid){
                    this.disableBtnSave = true; 
                    this.model.pod_time = ( this.model.pod_time != null && this.model.pod_time != undefined && this.model.pod_time != '') ? new Date(this.$moment(this.model.pod_time).format('YYYY-MM-DD')) : null;
                    this.model.receivetime = ( this.model.receivetime != null && this.model.receivetime != undefined && this.model.receivetime != '') ? new Date(this.$moment(this.model.receivetime).format('YYYY-MM-DD')) : null;
                    this.model.submit_time = ( this.model.submit_time != null && this.model.submit_time != undefined && this.model.submit_time != '') ? new Date(this.$moment(this.model.submit_time).format('YYYY-MM-DD')) : null;

                    this.model.manifests = [];
                    this.model.ids = [];

                    if(this.modelNotMapping.pod_recursive){
                        this.dataGrid.rows.map((item) => {
                            if(item.checked){
                                this.model.manifests.push(item.reference_id);
                            }
                        });

                        this.model.manifests = this.model.manifests.filter((v, i, a) => a.indexOf(v) === i);
                    } else {
                        this.dataGrid.rows.map((item) => {
                            if(item.checked){
                                this.model.ids.push(item.id);
                            }
                        });
                        this.model.ids = this.model.ids.filter((v, i, a) => a.indexOf(v) === i);
                    }
                    // action update data
                    TransportService.post("/pod/podMultiple", this.model)
                        .then((res) => {
                            this.disableBtnSave = false;
                            if(res.code == 200){
                                this.$refs.notification.success(this.titleMessage + " " + InformationUtil.update);
                                this.loadData();
                                this.$refs.dlg.close();
                                this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0])
                            } else {
                                this.$messager.alert({
                                    title: "Error",
                                    icon: "error",
                                    msg: res.message
                                });
                            }
                        }).catch((error) => {
                            this.disableBtnSave = false;
                            this.$refs.notification.error(error);
                        });
                    
                } else {
                    for(let key in valid){
                        this.textError[key] = this.getError(key);
                    }
                }
            });
        },

        // grid
        onRowDblClick(){
            if(this.action.edit){
                if(this.$refs.dataGrid.selectedRows.length > 0){
                    this.onEdit();
                }else{
                    this.$refs.notification.centerTop(InformationUtil.selectRow);
                }
            }
        },
        async onRefresh(){
            this.dataGrid.pageNumber = 1;       
            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);
            await this.loadData();
            this.$refs.notification.success(InformationUtil.refresh);
        },
        onSearch(){
            this.loadData();
        },
        onPageChange(event) {
            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);
            this.dataGrid.pageNumber = event.pageNumber;
            this.dataGrid.pageSize = event.pageSize;
            if(event.refresh) this.dataGrid.pageNumber = 1;
            this.loadData();
            if(event.refresh){
                this.$refs.notification.success(InformationUtil.refresh);
            }
        },

        async loadData(){
            this.dataGrid.loading = true;
            let param = {
                statusData : this.searchStatusData,
            }
            return TransportService.getData('/pod', this.dataGrid, param)
                .then((response) => {
                    if(response.data !== "" && response.data !== null){
                        let data = response.data;
                        data.rows.forEach((item) => {
                            item.checked = false;
                        });
                        this.dataGrid.total = data.total;
                        this.dataGrid.rows = data.rows;
                    }
                    
                    this.dataGrid.loading = false;
                });
        },

        onMenuManifest(value){
            if(value == "onViewManifest"){
                if(this.action.edit){
                    ModelUtil.resetModel(this.textError);
                    // method for get data to edit
                    if(this.$refs.dataGrid.selectedRows.length > 0){
                        if(this.action.edit){
                            this.$refs.formManifest.onPageShow(this.$refs.dataGrid.selectedRows[0].reference_id);
                        }
                    }else{
                        this.$messager.alert({
                            title: "Info",
                            icon: "info",
                            msg: InformationUtil.selectRow
                        });
                    }
                }
            }

            if(value == "onSetPod"){
                this.onEdit();
            }
        },

        onAllCheckedChange(checked) {
            if (this.dataGrid.rowClicked) {
                return;
            }
            this.dataGrid.rows = this.dataGrid.rows.map(row => {
                return Object.assign({}, row, {
                    checked: checked
                });
            });
        },
        onCheckedChange() {
            let rowSelected = this.dataGrid.rows.filter(row => row.checked);
            if(rowSelected.length == this.dataGrid.rows.length){
                this.$refs.allDataChecked.checked = true;
                this.dataGrid.allChecked = true;
            }else{
                this.$refs.allDataChecked.checked = false;
                this.dataGrid.allChecked = false;
            }
            this.dataGrid.rowClicked = true;
            this.$nextTick(() => (this.dataGrid.rowClicked = false));
        },
        onSelectionChange(row) {
            this.dataGrid.rows = this.dataGrid.rows.map((item) => {
                let checked = false;
                row.map((val) => {
                    if(item.id == val.id){
                        checked = true;
                    }
                })
                item.checked = checked;
                return item;
            });

            let rowSelected = this.dataGrid.rows.filter(row => row.checked);
            if(rowSelected.length == this.dataGrid.rows.length){
                this.$refs.allDataChecked.checked = true;
                this.dataGrid.allChecked = true;
            }else{
                this.$refs.allDataChecked.checked = false;
                this.dataGrid.allChecked = false;
            }
        },

        onSelectPod(row){
            this.modelNotMapping.picPod = '';
            if(row != null && row.pic != undefined && row.pic != null){
                this.modelNotMapping.picPod = row.pic;
            }            
        },
        onSelectPendingPod(row){
            this.modelNotMapping.picPendingPod = '';
            if(row != null && row.pic != undefined && row.pic != null){
                this.modelNotMapping.picPendingPod = row.pic;
            }
        },

        async optCompPod(event){
            let search = event == null ? "" : event.filterValue;
            return await OptionService.getOption("/componentPod", search)
                .then((response) => {
                    this.componentPod = response.data;
                }).catch((error) => {
                    this.$refs.notification.error(error);
                });
        },
        async optCompPendingPod(event){
            let search = event == null ? "" : event.filterValue;
            return await OptionService.getOption("/componentPod", search)
                .then((response) => {
                    this.componentPendingPod = response.data;
                }).catch((error) => {
                    this.$refs.notification.error(error);
                });

        },

        onCallAllOption(){
            this.optCompPod();
            this.optCompPendingPod();
        }
    },
    async mounted(){
        this.action = AccessUtil.getAccessAction('pod');
        await this.loadData();
        this.$refs.notification.success(InformationUtil.loadGrid + " " + this.dataGrid.total + " " + InformationUtil.successLoadGrid);
    },
    created(){
        GlobalUtil.removeWindow();
    }
};
</script>