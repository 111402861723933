export default{
    id : null,
    vehicle_id : null,
    driver_id : null,
    client_id : null,
    accident_date : null,
    accident_type : null,
    location : "",
    chronology_accident : "",
    vehicle_condition : "",
    current_position : "",
    amount_less : 0,
    police_investigation_report : "",
    additional_information : "",
}