<template>
    <div>
        <Panel :title="titleView"  style="margin:0px 1px; ">
            <div class="datagrid-toolbar">
                <LinkButton :plain="true" :iconCls="iconUtil.iconRefresh" @click="onRefresh()">{{ btnText.refresh }}</LinkButton>
                <LinkButton v-if="action.add" :plain="true" :iconCls="iconUtil.iconAdd" @click="onAddManifest()">{{ btnText.add }}</LinkButton>
                <LinkButton v-if="action.edit" :plain="true" :iconCls="iconUtil.iconEdit" @click="onEditManifest()">{{ btnText.edit }}</LinkButton>
                <LinkButton v-if="action.delete" :plain="true" :iconCls="iconUtil.iconDelete" @click="onDeleteManifest()">{{ btnText.delete }}</LinkButton>
                <span class="button-sep"></span>&nbsp;&nbsp;{{ filter.scheduleDate.text }} : <DateBox v-model="filter.scheduleDate.value" format="dd/MM/yyyy" style="width: 175px" @selectionChange="onChangeScheduleDate()"></DateBox>
                &nbsp;<span class="button-sep"></span>&nbsp;&nbsp;{{ filter.trip.text }} : <ComboBox  :data="tripNumbers" v-model="filter.trip.value" style="width: 55px" @selectionChange="onChangeTrip()"></ComboBox>
                &nbsp;<span class="button-sep"></span>&nbsp;&nbsp;{{ filter.clientId.text }} : 
                <ComboBox  :data="clients" @filterChange="optionClient($event)" v-model="filter.clientId.value" @selectionChange="onChangeClientId()">
                    <Addon>
                        <span v-if="filter.clientId.value!=null" class="textbox-icon icon-clear" title="Clear value" @click="filter.clientId.value=null"></span>
                    </Addon>
                    <template slot="item" slot-scope="scope">
                        <span style="font-weight:bold">{{scope.row.text}}</span><br/>
                        <span style="color:#888">{{scope.row.address}}</span><br/>
                    </template>
                </ComboBox>
                &nbsp;<span class="button-sep"></span>&nbsp;&nbsp;{{ search.text }} : 
                <SearchBox style="width:300px"
                    :placeholder="search.placeholder" 
                    v-model="dataGrid.search"
                    @search="onSearch()">
                    <Addon>
                        <span v-if="dataGrid.search" class="textbox-icon icon-clear" title="Clear value" @click="dataGrid.search=null;onSearch();"></span>
                    </Addon>
                </SearchBox>
            </div>
            <div @contextmenu.prevent="$refs.menuManifest.showContextMenu($event.pageX,$event.pageY)">
                <DataGrid
                    style="min-height: 70px; max-height:350px"
                    ref="dataGrid"
                    :columnResizing="true"
                    :border="false"
                    :lazy="true"
                    :loading="dataGrid.loading"
                    :data="dataGrid.rows"
                    :pagination="true"
                    :selectionMode="dataGrid.selectionMode"
                    :total="dataGrid.total"
                    :pageSize="dataGrid.pageSize"
                    :pageNumber="dataGrid.pageNumber"
                    :pageLayout="dataGrid.pageLayout"
                    :pageList="dataGrid.pageList" 
                    @pageChange="onPageChange($event)"
                    @sortChange="onSortChange($event)"
                    @rowDblClick="onRowDblClick($event)"
                    groupField="manifest_number"
                    @selectionChange="onSelectionManifestChange($event)"
                    :rowCss="getRowManifestCss"
                    >
                    <GridColumnGroup :frozen="true" align="left" width="370">
                        <GridHeaderRow>
                            <GridColumn width="30"></GridColumn>
                            <GridColumn field="ck" :width="50" align="center">
                                <template slot="body" slot-scope="scope">
                                    <CheckBox v-model="scope.row.checked" @checkedChange="onCheckedChangeTransportOrderManifest($event)"></CheckBox>
                                </template>
                            </GridColumn>
                            <GridColumn field="do_number" title="DO Number" :sortable="true" :frozen="true" ></GridColumn>
                            <GridColumn field="reference_transport_no" title="Reference" :sortable="true" :frozen="true" ></GridColumn>
                        </GridHeaderRow>
                    </GridColumnGroup>

                    <GridColumnGroup>
                        <GridHeaderRow>
                            <GridColumn field="delivery_date" title="Delivery Date" :sortable="true" :width="90"></GridColumn>
                            <GridColumn field="trip_no" title="Trip" :sortable="true" :width="50"></GridColumn>
                            <GridColumn field="origin_name" title="Origin Name" :sortable="true" :width="250"></GridColumn>
                            <GridColumn field="origin_address" title="Origin Address" :sortable="true" :width="400"></GridColumn>
                            <GridColumn field="origin_area_id" title="Origin Area" :sortable="true" :width="90"></GridColumn>
                            <GridColumn field="dest_name" title="Destination Name" :sortable="true" :width="250"></GridColumn>
                            <GridColumn field="dest_address" title="Destination Address" :sortable="true" :width="400"></GridColumn>
                            <GridColumn field="dest_area_id" title="Destination Area" :sortable="true" :width="90"></GridColumn>
                            <GridColumn field="order_qty" title="Quantity (KG)" :sortable="true" align="right" :width="100"></GridColumn>
                            <GridColumn field="order_qty_v2" title="Volume" :sortable="true" align="right" :width="100"></GridColumn>
                            <GridColumn field="order_type_desc" title="Order Type" :sortable="true" :width="90"></GridColumn>
                            <GridColumn field="remark" title="Remark" :sortable="true" :width="400"></GridColumn>
                        </GridHeaderRow>
                    </GridColumnGroup>
                </DataGrid>
                <Menu ref="menuManifest" :menuWidth="200" @itemClick="onUnRoute($event)">
                    <MenuItem value="onRefresh"  :iconCls="iconUtil.iconRefresh" :text="btnText.refresh"></MenuItem>
                    <MenuItem value="onViewManifest"  :iconCls="iconUtil.iconEdit" text="Edit"></MenuItem>
                    <MenuItem value="onDeleteManifest"  :iconCls="iconUtil.iconDelete" text="Remove"></MenuItem>
                    <MenuItem value="removeManifest"  :iconCls="iconUtil.iconNo" text="Un Route"></MenuItem>
                </Menu>
            </div> 
        </Panel>
        <Panel :title="information.transportOrder" style="margin:5px 1px; ">
            <div class="datagrid-toolbar">
                <LinkButton :plain="true" :iconCls="iconUtil.iconRefresh" @click="onRefreshTransportOrder()">{{ btnText.refresh }}</LinkButton>
                <span class="button-sep"></span> {{ search.text }} : 
                <SearchBox style="width:300px"
                    :placeholder="search.placeholder" 
                    v-model="dataGridTransportOrder.search"
                    @search="onSearchTransportOrder()">
                    <Addon>
                        <span v-if="dataGridTransportOrder.search" class="textbox-icon icon-clear" title="Clear value" @click="dataGridTransportOrder.search=null;onSearchTransportOrder();"></span>
                    </Addon>
                </SearchBox>
                <LinkButton :plain="true" :iconCls="iconUtil.iconMultiSearch" @click="onMultiSearchTransportOrder()">{{ informationUtil.multiSearchText }}</LinkButton>
            </div>
            <div @contextmenu.prevent="$refs.m1.showContextMenu($event.pageX,$event.pageY)">
                <DataGrid
                    ref="dataGridTransportOrder"
                    :columnResizing="true"
                    :border="false"
                    :lazy="true"
                    :loading="dataGridTransportOrder.loading"
                    :data="dataGridTransportOrder.rows"
                    :pagination="true"
                    :selectionMode="dataGridTransportOrder.selectionMode"
                    :total="dataGridTransportOrder.total"
                    :pageSize="dataGridTransportOrder.pageSize"
                    :pageNumber="dataGridTransportOrder.pageNumber"
                    :pageLayout="dataGridTransportOrder.pageLayout" 
                    @pageChange="onPageChangeTransportOrder($event)"
                    @sortChange="onSortChangeTransportOrder($event)"
                    @selectionChange="onSelectionTransportOrderChange($event)"
                    >
                    
                    <GridColumnGroup :frozen="true" align="left" width="370">
                        <GridHeaderRow>
                            <GridColumn align="center" cellCss="datagrid-td-rownumber" width="30">
                                <template slot="body" slot-scope="scope">
                                    {{scope.rowIndex + 1}}
                                </template>
                            </GridColumn>
                            <GridColumn field="ck" :width="50" align="center">
                                <template slot="header">
                                    <CheckBox ref="allDataChecked" v-model="dataGridTransportOrder.allChecked" @checkedChange="onAllCheckedChangeTransportOrder($event)"></CheckBox>
                                </template>
                                <template slot="body" slot-scope="scope">
                                    <CheckBox v-model="scope.row.checked" @checkedChange="onCheckedChangeTransportOrder($event)"></CheckBox>
                                </template>
                            </GridColumn>
                            <GridColumn field="reference_id" title="Reference ID" :sortable="true" :frozen="true" ></GridColumn>
                            <GridColumn field="do_number" title="Do Number" :sortable="true" :frozen="true" ></GridColumn>
                            <GridColumn field="so_number" title="So Number" :sortable="true" :frozen="true" ></GridColumn>
                        </GridHeaderRow>
                    </GridColumnGroup>
                    <GridColumnGroup>
                        <GridHeaderRow>
                            <GridColumn field="delivery_date_desc" title="Delivery Date" :sortable="true" :width="90"></GridColumn>
                            <GridColumn field="origin_name" title="Origin Name" :sortable="true" :width="250"></GridColumn>
                            <GridColumn field="origin_address" title="Origin Address" :sortable="true" :width="400"></GridColumn>
                            <GridColumn field="origin_area_id" title="Origin Area" :sortable="true" :width="90"></GridColumn>
                            <GridColumn field="dest_name" title="Destination Name" :sortable="true" :width="250"></GridColumn>
                            <GridColumn field="dest_address" title="Destination Address" :sortable="true" :width="400"></GridColumn>
                            <GridColumn field="dest_area_id" title="Destination Area" :sortable="true" :width="90"></GridColumn>
                            <GridColumn field="client_desc" title="Client ID" :sortable="true" :width="300"></GridColumn>
                            <GridColumn field="order_qty" title="Quantity (KG)" :sortable="true" align="right" :width="100"></GridColumn>
                            <GridColumn field="order_qty_v2" title="Volume" :sortable="true" align="right" :width="100"></GridColumn>
                            <GridColumn field="order_type_desc" title="Order Type" :sortable="true" :width="100"></GridColumn>
                            <GridColumn field="remark" title="Remark" :sortable="true" :width="400"></GridColumn>
                            <GridColumn field="order_status_desc" title="Status" :sortable="true" :width="250"></GridColumn>
                        </GridHeaderRow>
                    </GridColumnGroup>
                </DataGrid>
            </div>
            
            <Menu ref="m1" :menuWidth="200" @itemClick="onRoute($event)">
                <MenuItem value="onRefresh"  :iconCls="iconUtil.iconRefresh" :text="btnText.refresh"></MenuItem>
                <MenuItem value="addManifest"  :iconCls="iconUtil.iconAdd" text="Route To Manifest"></MenuItem>
            </Menu>
        </Panel>

        <!-- Multi Search -->
        <Dialog ref="dlgSearchTransportOrder" :iconCls="iconUtil.iconMultiSearch" bodyCls="f-column" :title="informationUtil.multiSearchText" :draggable="true" :modal="true" closed :dialogStyle="{width:'500px', height:'auto'}">
            <div class="f-full" style="overflow:auto">
                <table width="100%" cellspacing="1" cellpadding="5" border="0">
                    <tbody>
                        <tr>
                            <td style="width : 20%" class="bg_subtitle">DO Number</td>
                            <td class="bg_grid2" colspan="3">
                                <TextBox inputId="do_number" name="do_number" class="f-field-text" v-model="modelMultiSearchTransportOrder.do_number">
                                    <Addon>
                                        <span v-if="modelMultiSearchTransportOrder.do_number" class="textbox-icon icon-clear" title="Clear value" @click="modelMultiSearchTransportOrder.do_number=null;"></span>
                                    </Addon>
                                </TextBox>
                            </td>
                        </tr>
                        <tr>
                            <td class="bg_subtitle">SO Number</td>
                            <td class="bg_grid2" colspan="3">
                                <TextBox inputId="so_number" name="so_number" class="f-field-text" v-model="modelMultiSearchTransportOrder.so_number">
                                    <Addon>
                                        <span v-if="modelMultiSearchTransportOrder.so_number" class="textbox-icon icon-clear" title="Clear value" @click="modelMultiSearchTransportOrder.so_number=null;"></span>
                                    </Addon>
                                </TextBox>
                            </td>
                        </tr>
                        <tr>
                            <td class="bg_subtitle">Delivery Date</td>
                            <td class="bg_grid2">
                                <DateBox inputId="delivery_date_from" name="delivery_date_from" class="f-field-text" v-model="modelMultiSearchTransportOrder.delivery_date_from" format="dd/MM/yyyy">
                                    <Addon>
                                        <span v-if="modelMultiSearchTransportOrder.delivery_date_from" class="textbox-icon icon-clear" title="Clear value" @click="modelMultiSearchTransportOrder.delivery_date_from=null;"></span>
                                    </Addon>
                                </DateBox>
                            </td>
                            <td>to</td>
                            <td class="bg_grid2">
                                <DateBox inputId="delivery_date_to" name="delivery_date_to" class="f-field-text" v-model="modelMultiSearchTransportOrder.delivery_date_to" format="dd/MM/yyyy">
                                    <Addon>
                                        <span v-if="modelMultiSearchTransportOrder.delivery_date_to" class="textbox-icon icon-clear" title="Clear value" @click="modelMultiSearchTransportOrder.delivery_date_to=null;"></span>
                                    </Addon>
                                </DateBox>
                            </td>
                        </tr>

                        <tr>
                            <td class="bg_subtitle">Origin ID</td>
                            <td class="bg_grid2" colspan="3">
                                <ComboBox  :data="customer_origins" inputId="origin_id" name="origin_id" class="f-field-text" v-model="modelMultiSearchTransportOrder.origin_id">
                                    <template slot="item" slot-scope="scope">
                                        <span style="font-weight:bold">{{scope.row.text}}</span><br/>
                                        <span style="color:#888">{{scope.row.name}}</span><br/>
                                        <span style="color:#888">{{scope.row.address}}</span><br/>
                                    </template>
                                    <Addon>
                                        <span v-if="modelMultiSearchTransportOrder.origin_id" class="textbox-icon icon-clear" title="Clear value" @click="modelMultiSearchTransportOrder.origin_id=null;"></span>
                                    </Addon>
                                </ComboBox>
                            </td>
                        </tr>

                        <tr>
                            <td class="bg_subtitle">Destination ID</td>
                            <td class="bg_grid2" colspan="3">
                                <ComboBox  :data="customer_destinations" inputId="dest_id" name="dest_id" class="f-field-text" v-model="modelMultiSearchTransportOrder.dest_id"> 
                                    <template slot="item" slot-scope="scope">
                                        <span style="font-weight:bold">{{scope.row.text}}</span><br/>
                                        <span style="color:#888">{{scope.row.name}}</span><br/>
                                        <span style="color:#888">{{scope.row.address}}</span><br/>
                                    </template>
                                    <Addon>
                                        <span v-if="modelMultiSearchTransportOrder.dest_id" class="textbox-icon icon-clear" title="Clear value" @click="modelMultiSearchTransportOrder.dest_id=null;"></span>
                                    </Addon>
                                </ComboBox>
                            </td>
                        </tr>

                        <tr>
                            <td class="bg_subtitle">Order Type </td>
                            <td class="bg_grid2" colspan="3">
                                <ComboBox  :data="orderTypes" inputId="order_type" name="order_type" class="f-field-text" v-model="modelMultiSearchTransportOrder.order_type">
                                    <Addon>
                                        <span v-if="modelMultiSearchTransportOrder.order_type" class="textbox-icon icon-clear" title="Clear value" @click="modelMultiSearchTransportOrder.order_type=null;"></span>
                                    </Addon>
                                </ComboBox>
                            </td>
                        </tr>

                        <tr>
                            <td class="bg_subtitle">
                                Client ID
                            </td>
                            <td class="bg_grid2" colspan="3"> 
                                <ComboBox  :data="clients" inputId="client_id" name="client_id" class="f-field-text" v-model="modelMultiSearchTransportOrder.client_id">
                                    <template slot="item" slot-scope="scope">
                                        <span style="font-weight:bold">{{scope.row.text}}</span><br/>
                                        <span style="color:#888">{{scope.row.address}}</span><br/>
                                    </template>
                                    <Addon>
                                        <span v-if="modelMultiSearchTransportOrder.client_id" class="textbox-icon icon-clear" title="Clear value" @click="modelMultiSearchTransportOrder.client_id=null;"></span>
                                    </Addon>
                                </ComboBox>
                            </td>
                        </tr>                 
                    </tbody>
                </table>
            </div>
            <div class="dialog-button">
                <LinkButton :iconCls="iconUtil.iconSearch" @click="onSearchMultiTransportOrder()" style="width:89px">{{ informationUtil.searchText }}</LinkButton>
                <LinkButton :iconCls="iconUtil.iconCancel" @click="onResetSearchMultiTransportOrder()" style="width:89px">{{ informationUtil.resetText }}</LinkButton>
            </div>
        </Dialog>

        <NotificationComponent ref="notification"></NotificationComponent>
        <FormManifestComponent ref="formManifest" actionData="route_planning" @onSaveData="onUpdateManifest()" @onConfirmManifest="onConfirmManifest()"></FormManifestComponent>
        <GridVehicleComponent ref="gridVehicle" actionData="route_planning" :disableBtnSave="disableBtnSave" @onSaveData="onSaveData($event)"></GridVehicleComponent>
        <GridManifestComponent ref="gridManifest" actionData="route_planning" :disableBtnSave="disableBtnSave" @onSaveData="onSetToManifest($event)"></GridManifestComponent>
    </div>
    
</template>

<script>
import NotificationComponent from '@/components/NotificationComponent.vue';
import Manifest from '@/models/Manifest';
import TransportService from '@/services/TransportService';
import ModelUtil from '@/utils/ModelUtil';
import InformationUtil from '@/utils/InformationUtil';
import AccessUtil from '@/utils/AccessUtil';
import IconUtil from '@/utils/IconUtil';
import GlobalUtil from '@/utils/GlobalUtil';

import OptionService from '@/services/OptionService';

// Component
import FormManifestComponent from '@/components/forms/FormManifestComponent.vue';
import GridVehicleComponent from '@/components/grids/GridVehicleComponent.vue';
import GridManifestComponent from '@/components/grids/GridManifestComponent.vue';
import Route from '@/models/Route';

export default {
    name: 'RoutePlanningView',
    components : {
        NotificationComponent,
        FormManifestComponent,
        GridVehicleComponent,
        GridManifestComponent
    },
    data() {
        return {
            titleView : InformationUtil.routePlanning + "s",
            titleMessage : InformationUtil.routePlanning,

            iconUtil : IconUtil,
            informationUtil : InformationUtil,

            information : {
                transportOrder : InformationUtil.transportOrder,
                vehicle : InformationUtil.vehicle,
            },

            titleForm :{
                add : InformationUtil.addText,
                edit : InformationUtil.editText
            },

            headerGroupStyle :{
                textAlign: 'left',
                fontWeight: 'bold',
                color: '#2196F3',
                background: "#fafafa" 
            },

            iconForm :{
                iconAdd : IconUtil.iconAdd,
                iconEdit : IconUtil.iconEdit
            },
            
            action : {
                view : false,
                add : false,
                edit : false,
                delete : false
            },

            filter :{
                scheduleDate : {
                    text : InformationUtil.scheduleDate,
                    value : new Date(),
                    viewInfo : false
                },
                trip : {
                    text : InformationUtil.trip,
                    value : 1,
                    viewInfo : false
                },
                clientId : {
                    text : InformationUtil.clientId,
                    value : null,
                    viewInfo : false
                },
            },

            btnText :{
                refresh : InformationUtil.refreshText,
                add : InformationUtil.addText,
                edit : InformationUtil.editText,
                delete : InformationUtil.removeText,
                save : InformationUtil.saveText,
                cancel : InformationUtil.cancelText,
                search : InformationUtil.searchText
            },

            scheduleDate : {
                text : InformationUtil.scheduleDate,
                value : new Date()
            },
            trip : {
                text : InformationUtil.trip,
                value : 1
            },
            clientId : {
                text : InformationUtil.clientId,
                value : null
            },

            search : {
                text : InformationUtil.searching,
                placeholder : InformationUtil.searchHolder
            },

            modelMultiSearchTransportOrder : {
                do_number : "",
                so_number : "",
                delivery_date_from : null,
                delivery_date_to : null,
                req_arrival_date_from : null,
                req_arrival_date_to : null,
                created_date_from : null,
                created_date_to : null,
                origin_id : "",
                dest_id : "",
                order_type : null,
                client_id : "",
                order_status : null,
            },

            dataGrid : {
                loading: false,
                rows : [],
                total: 0,
                start: 0,
                pageNumber: 1,
                pageSize: 50,
                selectionMode : 'multiple',
                pageLayout: ['list','sep','first','prev','next','last','sep','refresh','info'],
                pageList: [50,100,200,500,1000],

                // filter
                search : null,
                sortField : "",
                sortOrder : "",
                rowClicked : false
            },

            dataGridTransportOrder : {
                loading: false,
                rows : [],
                total: 0,
                start: 0,
                pageNumber: 1,
                pageSize: 10,
                selectionMode : 'multiple',
                pageLayout: ['list','sep','first','prev','next','last','sep','info'],

                // filter
                search : null,
                sortField : "",
                sortOrder : "",
                allChecked : false,
                rowClicked : false
            },

            // form
            title: '',
            icon: '',
            model: Manifest,
            disabled : false,
            disableBtnSave : false,

            // validation
            rules: {
                area_id: 'required',
                description: 'required',
                area_type: 'required',
            },
            errors: {},
            textError :{
                area_id : "",
                description : "", 
                area_type : ""
            },

            // combobox
            tripNumbers : [
                { value : 1, text : 1},
                { value : 2, text : 2},
                { value : 3, text : 3},
                { value : 4, text : 4},
                { value : 5, text : 5},
                { value : 6, text : 6},
                { value : 7, text : 7},
                { value : 8, text : 8},
            ],
            clients : [],
            orderTypes: [],
            customer_origins : [],
            customer_destinations : [],
        }
    },
    methods:{
        // filter data
        async onChangeScheduleDate(){
            this.dataGrid.pageNumber = 1;
            await this.loadData();
            this.$refs.notification.success(InformationUtil.refresh);
        },
        async onChangeTrip(){
            this.dataGrid.pageNumber = 1;
            await this.loadData();
            if(this.filter.trip.viewInfo){
                this.$refs.notification.success(InformationUtil.refresh);
            }
            this.filter.trip.viewInfo = true;
        },
        async onChangeClientId(){
            this.dataGrid.pageNumber = 1;
            await this.loadData();
            if(this.filter.clientId.viewInfo){
                this.$refs.notification.success(InformationUtil.refresh);
            }
            this.filter.clientId.viewInfo = true;
        },
        getError(name) {
            return this.errors[name] && this.errors[name].length
            ? this.errors[name][0]
            : null;
        },
        
        onSaveData(row){ // from grid vehicle
            ModelUtil.resetModel(this.model);
            this.model = ModelUtil.mapModel(this.model, row);
            this.model.trip = this.filter.trip.value;
            this.model.schedule_date = this.$moment(this.filter.scheduleDate.value).format("YYYY-MM-DD")
            this.model.co_driver_id = row.co_driver;
            this.model.vehicle_id = row.id;
            this.model.driver_id = row.driver;

            // save data
            this.disableBtnSave = true;
            TransportService.addData("/manifest", this.model)
                .then((res) => {
                    this.disableBtnSave = false;
                    if(res.code == 200){
                        this.$refs.notification.success(this.titleMessage + " " + InformationUtil.add);
                        this.loadData();
                        this.$refs.gridVehicle.onPageClose();
                    } else {
                        this.$messager.alert({
                            title: "Error",
                            icon: "error",
                            msg: res.message
                        });
                    }
                }).catch((error) => {
                    this.disableBtnSave = false;
                    this.$refs.notification.error(error);
                });

        },

        onUpdateManifest(){ // after save manifest            
            this.$refs.notification.success(this.titleMessage + " " + InformationUtil.edit);
            this.loadData();
        },

        onConfirmManifest(){ // after confirm manifest
            this.$messager.alert({
                title: "Info",
                icon: "info",
                msg: this.titleMessage + " " + InformationUtil.confirmManifest
            });
            this.loadData();
        },
        
        onAddManifest(){
            if(this.action.add){
                this.$refs.gridVehicle.onPageShow();
            }
        },
        onEditManifest(){
            ModelUtil.resetModel(this.textError);
            // method for get data to edit
            if(this.$refs.dataGrid.selectedRows.length > 0){
                if(this.action.edit){
                    this.$refs.formManifest.onPageShow(this.$refs.dataGrid.selectedRows[0].id);
                }
            }else{
                this.$refs.notification.centerTop(InformationUtil.selectRow);
            }
        },
        async onDeleteManifest(){
            if(this.$refs.dataGrid.selectedRows.length > 0){
                let data = this.$refs.dataGrid.selectedRows[0];
                if(data.manifest_status != "0"){
                    this.dataGrid.loading = false;
                    this.dataGridTransportOrder.loading = false;
                    this.$messager.alert({
                                            title: "Error",
                                            icon: "error",
                                            msg: InformationUtil.infoManifestConfirm
                                        });
                    return;
                }

                this.$messager.confirm({
                    title: 'Confirm',
                    msg: InformationUtil.confirmDelete,
                    result: (r) => {
                        if (r){
                            this.dataGrid.loading = true;
                            this.dataGridTransportOrder.loading = true;
                            TransportService.deleteData("/manifest", this.$refs.dataGrid.selectedRows[0].id)
                                .then((res) => {
                                    this.dataGrid.loading = false;
                                    this.dataGridTransportOrder.loading = false;
                                    if(res.code == 200){
                                        this.$refs.notification.success(this.titleMessage + " " + InformationUtil.add);
                                        this.loadData();
                                        this.loadDataTransportOrder();
                                    } else {
                                        this.$messager.alert({
                                            title: "Error",
                                            icon: "error",
                                            msg: res.message
                                        });
                                    }
                                }).catch((err) => {
                                    this.$messager.alert({
                                            title: "Error",
                                            icon: "error",
                                            msg: err.message
                                        });
                                });
                            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]) 
                        }
                    }
                })
            }else{
                this.$refs.notification.centerTop(InformationUtil.selectRow);
            }
        },
        
        // handle data grid manifest
        getRowManifestCss(row){
            if (row.manifest_status > 0) {
                return { backgroundColor: "rgb(255, 230, 230)",};
            }
            return null;
        },
        onRowDblClick(row){
            if(this.action.edit){
                this.$refs.formManifest.onPageShow(row.id);
            }
        },
        async onRefresh(){
            this.dataGrid.pageNumber = 1;       
            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);
            await this.loadData();
            this.$refs.notification.success(InformationUtil.refresh);
        },
        onSearch(){
            this.loadData();
        },
        onSortChange(event){
            if(event.length > 0){
                this.dataGrid.pageNumber = 1;
                this.dataGrid.sortField = event[0].field;
                this.dataGrid.sortOrder = event[0].order;
                this.loadData();
            }
        },
        onPageChange(event) {
            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);
            this.dataGrid.pageNumber = event.pageNumber;
            this.dataGrid.pageSize = event.pageSize;
            if(event.refresh) this.dataGrid.pageNumber = 1;
            this.loadData();
            if(event.refresh){
                this.$refs.notification.success(InformationUtil.refresh);
            }
        },
        async loadData(){
            this.dataGrid.loading = true;
            let param = {
                scheduleDate : this.$moment(this.filter.scheduleDate.value).format("YYYY-MM-DD"),
                trip : this.filter.trip.value,
                clientId : this.filter.clientId.value,
            }
            return TransportService.getData('/manifest', this.dataGrid, param)
                .then((response) => {
                    if(response.data !== "" && response.data !== null){
                        let data = response.data;
                        data.rows.forEach((item) => {
                            item.checked = false;
                            if(item.do_number == "" || item.do_number == null){
                                item.trip_no = "";
                                item.order_qty = "";
                                item.order_qty_v2 = "";
                            }
                        });
                        this.dataGrid.total = data.total;
                        this.dataGrid.rows = data.rows;
                    }
                    this.dataGrid.loading = false;
                });
        },

        // handle transport order grid
        onSetToManifest(row){
            let rowChecked = this.dataGridTransportOrder.rows.filter(row => row.checked);
            let param = Route; 
            param = ModelUtil.resetModel(param);
            param = ModelUtil.mapModel(param, row);
            param.manifest_ids.push(row.id);

            rowChecked.forEach((val) => {
                param.transport_orders.push(val.id);
            })

            this.disableBtnSave = true;
            TransportService.addData("/routePlanning", param)
                .then((res) => {
                    this.disableBtnSave = false;
                    this.onUnSelectedGrid();
                    if(res.code == 200){
                        this.$refs.notification.success(this.titleMessage + " " + InformationUtil.add);
                        this.loadData();
                        this.loadDataTransportOrder();
                        this.$refs.allDataChecked.checked = false;
                        this.dataGridTransportOrder.allChecked = false;
                        this.$refs.gridManifest.onPageClose();
                    } else {
                        this.$messager.alert({
                            title: "Error",
                            icon: "error",
                            msg: res.message
                        });
                    }
                }).catch((error) => {
                    this.$refs.notification.error(error);
                });

        },
        onRoute(value){
            if(value == "addManifest"){
                let rowChecked = this.dataGridTransportOrder.rows.filter(row => row.checked);
                if(rowChecked.length > 0){
                    this.$refs.gridManifest.onPageShow();
                }else{
                    this.$refs.notification.centerTop(InformationUtil.selectRow);
                }
            }

            if(value == "onRefresh"){
                this.onRefreshTransportOrder()
            }
        },
        onUnRoute(value){
            if(value == "removeManifest"){
                let rowChecked = this.dataGrid.rows.filter(row => row.checked);
                if(rowChecked.length > 0){
                    let param = Route; 
                    let manifestIsConfirm = false;
                    param = ModelUtil.resetModel(param);
                    rowChecked.forEach((val) => {
                        
                        // validation where manifest is confirm
                        if(val.manifest_status != "0"){
                            manifestIsConfirm = true;
                        }
                        param.transport_orders.push(val.reference_transport_no);
                        param.manifest_ids.push(val.id);
                        param.trip_number = val.trip_number;
                    })

                    if(manifestIsConfirm){
                        this.$messager.alert({
                                            title: "Error",
                                            icon: "error",
                                            msg: InformationUtil.infoManifestConfirm
                                        });
                        return;
                    }

                    param.manifest_ids = [...new Set(param.manifest_ids)];

                    this.dataGrid.loading = true;
                    this.dataGridTransportOrder.loading = true;
                    TransportService.post("/routePlanning/destroy", param)
                        .then((res) => {
                            this.dataGrid.loading = false;
                            this.dataGridTransportOrder.loading = false;
                            this.onUnSelectedGrid();
                            if(res.code == 200){
                                this.$refs.notification.success(this.titleMessage + " " + InformationUtil.add);
                                this.loadData();
                                this.loadDataTransportOrder();
                            } else {
                                this.$messager.alert({
                                    title: "Error",
                                    icon: "error",
                                    msg: res.message
                                });
                            }
                        }).catch((error) => {
                            this.$messager.alert({
                                            title: "Error",
                                            icon: "error",
                                            msg: error.message
                                        });
                        });
                }else{
                    this.$refs.notification.centerTop(InformationUtil.selectRow);
                }
            }

            if(value == "onRefresh"){
                this.onRefresh();
            }

            if(value == "onViewManifest"){
                if(this.action.edit){
                    ModelUtil.resetModel(this.textError);
                    // method for get data to edit
                    if(this.$refs.dataGrid.selectedRows.length > 0){
                        if(this.action.edit){
                            this.$refs.formManifest.onPageShow(this.$refs.dataGrid.selectedRows[0].id);
                        }
                    }else{
                        this.$messager.alert({
                            title: "Info",
                            icon: "info",
                            msg: InformationUtil.selectRow
                        });
                    }
                }
            }

            if(value == "onDeleteManifest"){
                this.onDeleteManifest();
            }
        },

        onUnSelectedGrid(){
            this.dataGrid.rows.map((item) => {
                this.$refs.dataGrid.unselectRow(item);
                item.checked = false;
            });
            this.dataGridTransportOrder.rows.map((item) => {
                this.$refs.dataGridTransportOrder.unselectRow(item);
                item.checked = false;
            });
        },

        // handling check box
        onAllCheckedChangeTransportOrder(checked) {
            if (this.dataGridTransportOrder.rowClicked) {
                return;
            }
            this.dataGridTransportOrder.rows = this.dataGridTransportOrder.rows.map(row => {
                return Object.assign({}, row, {
                    checked: checked
                });
            });
        },
        onCheckedChangeTransportOrder() {
            let rowSelected = this.dataGridTransportOrder.rows.filter(row => row.checked);
            if(rowSelected.length == this.dataGridTransportOrder.rows.length){
                this.$refs.allDataChecked.checked = true;
                this.dataGridTransportOrder.allChecked = true;
            }else{
                this.$refs.allDataChecked.checked = false;
                this.dataGridTransportOrder.allChecked = false;
            }
            this.dataGridTransportOrder.rowClicked = true;
            this.$nextTick(() => (this.dataGridTransportOrder.rowClicked = false));
        },
        onCheckedChangeTransportOrderManifest() {
            this.dataGrid.rowClicked = true;
            this.$nextTick(() => (this.dataGrid.rowClicked = false));
        },
        onSelectionManifestChange(row){
            this.dataGrid.rows = this.dataGrid.rows.map((item) => {
                let checked = false;
                if(item.reference_transport_no == null){
                    item.checked = false;
                    return item;
                }
                row.map((val) => {
                    if(item.reference_transport_no == val.reference_transport_no){
                        checked = true;
                    }
                })
                item.checked = checked;
                return item;
            });
        },
        onSelectionTransportOrderChange(row) {
            this.dataGridTransportOrder.rows = this.dataGridTransportOrder.rows.map((item) => {
                let checked = false;
                row.map((val) => {
                    if(item.id == val.id){
                        checked = true;
                    }
                })
                item.checked = checked;
                return item;
            });

            let rowSelected = this.dataGridTransportOrder.rows.filter(row => row.checked);
            if(rowSelected.length == this.dataGridTransportOrder.rows.length){
                this.$refs.allDataChecked.checked = true;
                this.dataGridTransportOrder.allChecked = true;
            }else{
                this.$refs.allDataChecked.checked = false;
                this.dataGridTransportOrder.allChecked = false;
            }
        },

        // data grid transport order
        onMultiSearchTransportOrder(){
            this.onCallAllOption();
            this.$refs.dlgSearchTransportOrder.open();
        },
        onSearchMultiTransportOrder(){
            this.dataGridTransportOrder.pageNumber = 1;
            this.$refs.dataGridTransportOrder.unselectRow(this.$refs.dataGridTransportOrder.selectedRows[0]);

            // set date format
            this.modelMultiSearchTransportOrder.delivery_date_from = this.modelMultiSearchTransportOrder.delivery_date_from != null ? new Date(this.$moment(this.modelMultiSearchTransportOrder.delivery_date_from).format('YYYY-MM-DD')) : null;
            this.modelMultiSearchTransportOrder.delivery_date_to = this.modelMultiSearchTransportOrder.delivery_date_to != null ? new Date(this.$moment(this.modelMultiSearchTransportOrder.delivery_date_to).format('YYYY-MM-DD')) : null;
            this.modelMultiSearchTransportOrder.req_arrival_date_from = this.modelMultiSearchTransportOrder.req_arrival_date_from != null ? new Date(this.$moment(this.modelMultiSearchTransportOrder.req_arrival_date_from).format('YYYY-MM-DD')) : null;
            this.modelMultiSearchTransportOrder.req_arrival_date_to = this.modelMultiSearchTransportOrder.req_arrival_date_to != null ? new Date(this.$moment(this.modelMultiSearchTransportOrder.req_arrival_date_to).format('YYYY-MM-DD')) : null;
            this.modelMultiSearchTransportOrder.created_date_from = this.modelMultiSearchTransportOrder.created_date_from != null ? new Date(this.$moment(this.modelMultiSearchTransportOrder.created_date_from).format('YYYY-MM-DD')) : null;
            this.modelMultiSearchTransportOrder.created_date_to = this.modelMultiSearchTransportOrder.created_date_to != null ? new Date(this.$moment(this.modelMultiSearchTransportOrder.created_date_to).format('YYYY-MM-DD')) : null;

            this.loadDataTransportOrder();
            this.$refs.dlgSearchTransportOrder.close();
        },
        onResetSearchMultiTransportOrder(){
            this.modelMultiSearchTransportOrder = ModelUtil.resetModel(this.modelMultiSearchTransportOrder);

            this.modelMultiSearchTransportOrder.delivery_date_from = null;
            this.modelMultiSearchTransportOrder.delivery_date_to = null;
            this.modelMultiSearchTransportOrder.req_arrival_date_from = null;
            this.modelMultiSearchTransportOrder.req_arrival_date_to = null;
            this.modelMultiSearchTransportOrder.created_date_from = null;
            this.modelMultiSearchTransportOrder.created_date_to = null;

            this.dataGridTransportOrder.pageNumber = 1;
            this.$refs.dataGridTransportOrder.unselectRow(this.$refs.dataGridTransportOrder.selectedRows[0]);
            this.loadDataTransportOrder();
            this.$refs.dlgSearchTransportOrder.close();
        },
        async onRefreshTransportOrder(){
            this.dataGridTransportOrder.pageNumber = 1;       
            this.$refs.dataGridTransportOrder.unselectRow(this.$refs.dataGridTransportOrder.selectedRows[0]);
            await this.loadDataTransportOrder();
            this.$refs.notification.success(InformationUtil.refresh);
        },
        onSearchTransportOrder(){
            this.loadDataTransportOrder();
        },
        onSortChangeTransportOrder(event){
            if(event.length > 0){
                this.dataGridTransportOrder.pageNumber = 1;
                this.dataGridTransportOrder.sortField = event[0].field;
                this.dataGridTransportOrder.sortOrder = event[0].order;
                this.loadDataTransportOrder();
            }
        },
        onPageChangeTransportOrder(event) {
            this.$refs.dataGridTransportOrder.unselectRow(this.$refs.dataGridTransportOrder.selectedRows[0]);
            this.dataGridTransportOrder.pageNumber = event.pageNumber;
            this.dataGridTransportOrder.pageSize = event.pageSize;
            if(event.refresh) this.dataGridTransportOrder.pageNumber = 1;
            this.loadDataTransportOrder();
            if(event.refresh){
                this.$refs.notification.success(InformationUtil.refresh);
            }
        },
        async loadDataTransportOrder(){
            this.dataGridTransportOrder.loading = true;
            let param = {
                statusData : 0, // status open
                setManifest : 1,
                multiSearch : this.modelMultiSearchTransportOrder
            }
            return TransportService.getData('/routePlanning/transportOrder', this.dataGridTransportOrder, param)
                .then((response) => {
                    if(response.data !== "" && response.data !== null){
                        let data = response.data; 
                        data.rows.map((item) => {
                            item.checked = false;
                        });
                        this.dataGridTransportOrder.total = data.total;
                        this.dataGridTransportOrder.rows = data.rows;
                    }
                    this.dataGridTransportOrder.loading = false;
                });
        },

        // combo box data
        async optionClient(event){
            let search = event == null ? "" : event.filterValue;
            return await OptionService.getOption("/client", search)
                .then((response) => {
                    this.clients = response.data;
                }).catch((error) => {
                    this.$refs.notification.error(error);
                });
        },
        async optionCustomerOrigin(event){
            let search = event == null ? "" : event.filterValue;
            return await OptionService.getOption("/customer", search)
                .then((response) => {
                    this.customer_origins = response.data;
                }).catch((error) => {
                    this.$refs.notification.error(error);
                });
        },
        async optionCustomerDestination(event){
            let search = event == null ? "" : event.filterValue;
            return await OptionService.getOption("/customer", search)
                .then((response) => {
                    this.customer_destinations = response.data;
                }).catch((error) => {
                    this.$refs.notification.error(error);
                });
        },
        async ringCode(event){
            let search = event == null ? "" : event.filterValue;
            return await OptionService.getOption("/ringCode", search)
                .then((response) => {
                    this.orderTypes = response.data;
                }).catch((error) => {
                    this.$refs.notification.error(error);
                });
        },
        onCallAllOption(){
            this.optionClient();
            this.optionCustomerOrigin();
            this.optionCustomerDestination();
            this.ringCode();
        },
    },
    async mounted(){
        this.action = AccessUtil.getAccessAction('area');
        await this.loadData();
        await this.loadDataTransportOrder();
        this.$refs.notification.success(InformationUtil.loadGrid + " " + this.dataGrid.total + " " + InformationUtil.successLoadGrid);
    },
    created(){
        GlobalUtil.removeWindow();
    }
};
</script>

<style>
    .datagrid-group-title{
        text-align: left;
        font-weight:bold;
        color:#2196F3
    }
</style>