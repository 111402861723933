<template>
    <div>
        <Panel :title="titleView"  style="margin:0px 1px; ">
            <div class="datagrid-toolbar">
                <LinkButton :plain="true" :iconCls="iconUtil.iconRefresh" @click="onRefresh()">{{ btnText.refresh }}</LinkButton>
                <LinkButton v-if="action.add" :plain="true" :iconCls="iconUtil.iconAdd" @click="onAdd()">{{ btnText.add }}</LinkButton>
                <LinkButton v-if="action.edit" :plain="true" :iconCls="iconUtil.iconEdit" @click="onEdit()">{{ btnText.edit }}</LinkButton>
                <LinkButton v-if="action.delete" :plain="true" :iconCls="iconUtil.iconDelete" @click="onDelete()">{{ btnText.delete }}</LinkButton>
                <span class="button-sep"></span> {{ search.text }} : 
                <SearchBox style="width:300px"
                    :placeholder="search.placeholder" 
                    v-model="dataGrid.search"
                    @search="onSearch()">
                    <Addon>
                        <span v-if="dataGrid.search" class="textbox-icon icon-clear" title="Clear value" @click="dataGrid.search=null;onSearch();"></span>
                    </Addon>
                </SearchBox>
            </div>
            <DataGrid
                    ref="dataGrid"
                    :columnResizing="true"
                    :border="false"
                    :lazy="true"
                    :loading="dataGrid.loading"                                    
                    :data="dataGrid.rows"
                    :pagination="true"
                    :selectionMode="dataGrid.selectionMode"
                    :total="dataGrid.total"
                    :pageSize="dataGrid.pageSize"
                    :pageNumber="dataGrid.pageNumber"
                    :pageLayout="dataGrid.pageLayout" 
                    @pageChange="onPageChange($event)"
                    @sortChange="onSortChange($event)"
                    @rowDblClick="onRowDblClick()"
                    >
                
                    <GridColumn align="center" cellCss="datagrid-td-rownumber" width="30">
                    <template slot="body" slot-scope="scope">
                        {{scope.rowIndex + 1}}
                    </template>
                </GridColumn>
                <GridColumn field="vehicle_id" title="Vehicle Id" :sortable="true"></GridColumn>
                <GridColumn field="driver_desc" title="Driver" :sortable="true"></GridColumn>
                <GridColumn field="co_driver_desc" title="Co Driver" :sortable="true"></GridColumn>
                <GridColumn field="transporter_desc" title="Transporter Id" :sortable="true"></GridColumn>
                <GridColumn field="client_desc" title="Subcon" :sortable="true"></GridColumn>
                <GridColumn field="status_desc" title="Status" :sortable="true"></GridColumn>
                <GridColumn field="type_desc" title="Type" :sortable="true"></GridColumn>
                <GridColumn field="max_volume" title="Max Volume" :sortable="true" align="right"></GridColumn>
                <GridColumn field="max_weight" title="Max Weight" :sortable="true" align="right"></GridColumn>
            </DataGrid>
        </Panel>

        <!-- Form -->
        <Dialog ref="dlg" :iconCls="icon" bodyCls="f-column" :title="title" :draggable="true" :modal="true" closed :dialogStyle="{width:'686px', height:'auto'}">
            <div class="f-full" style="overflow:auto">
                <Form ref="form" :model="model" :rules="rules" @validate="errors=$event">
                    <table width="100%" cellspacing="1" cellpadding="5" border="0">
                        <tbody>
                            <tr>
                                <td class="bg_subtitle">
                                    Vehicle ID <font color="red">*</font>
                                </td>
                                <td class="bg_grid2"> 
                                    <TextBox inputId="vehicle_id" name="vehicle_id" class="f-field-text" v-model="model.vehicle_id" :disabled="disabled"></TextBox>
                                    <div class="error">{{textError.vehicle_id}}</div>
                                </td>
                                <td class="bg_subtitle">Subcon</td>
                                <td class="bg_grid2">
                                    <ComboBox  :data="subcons" inputId="subcon" name="subcon" @filterChange="optionSubcon($event)" class="f-field-text" v-model="model.subcon">
                                        <template slot="item" slot-scope="scope">
                                            <span style="font-weight:bold">{{scope.row.text}}</span><br/>
                                            <span style="color:#888">{{scope.row.address}}</span><br/>
                                        </template>
                                    </ComboBox>
                                </td>
                            </tr>
                            <tr>
                                <td class="bg_subtitle">Driver <font color="red">*</font></td>
                                <td class="bg_grid2">
                                    <ComboBox  :data="drivers" inputId="driver" name="driver" @filterChange="optionDriver($event)" class="f-field-text" v-model="model.driver">
                                        <template slot="item" slot-scope="scope">
                                            <span style="font-weight:bold">{{scope.row.text}}</span><br/>
                                            <span style="color:#888">{{scope.row.transporter}}</span><br/>
                                        </template>
                                    </ComboBox>
                                    <div class="error">{{textError.driver}}</div>
                                </td>
                                <td class="bg_subtitle">Type <font color="red">*</font></td>
                                <td class="bg_grid2">
                                    <ComboBox  :data="vehicleTypes" inputId="type" name="type" @filterChange="optionVehicleType($event)" @selectionChange="onVehicleTypeSelect($event)" class="f-field-text" v-model="model.type">
                                        <template slot="item" slot-scope="scope">
                                            <span style="font-weight:bold">{{scope.row.text}}</span><br/>
                                            <span style="color:#888">Weight : {{scope.row.weight_cap}}  Volume : {{scope.row.volume_cap}}</span><br/>
                                        </template>
                                    </ComboBox>
                                    <div class="error">{{textError.type}}</div>
                                </td>
                            </tr>
                            <tr>
                                <td class="bg_subtitle">Co Driver <font color="red">*</font></td>
                                <td class="bg_grid2">
                                    <ComboBox  :data="coDriver" inputId="co_driver" name="co_driver" @filterChange="optionCoDriver($event)" class="f-field-text" v-model="model.co_driver">
                                        <template slot="item" slot-scope="scope">
                                            <span style="font-weight:bold">{{scope.row.text}}</span><br/>
                                            <span style="color:#888">{{scope.row.transporter}}</span><br/>
                                        </template>
                                    </ComboBox>
                                    <div class="error">{{textError.co_driver}}</div>
                                </td>
                                <td class="bg_subtitle">Max Volume <font color="red">*</font></td>
                                <td class="bg_grid2">
                                    <NumberBox  inputId="max_volume" name="max_volume" class="f-field-text text-50 text-right" v-model="model.max_volume" :spinners="false"></NumberBox > M3
                                    <div class="error">{{textError.max_volume}}</div>
                                </td>
                            </tr>
                            <tr>
                                <td class="bg_subtitle">Transporter Id <font color="red">*</font></td>
                                <td class="bg_grid2">
                                    <ComboBox  :data="transporters" inputId="transporter_id" name="transporter_id" @filterChange="optionTransporter($event)" class="f-field-text" v-model="model.transporter_id">
                                        <template slot="item" slot-scope="scope">
                                            <span style="font-weight:bold">{{scope.row.text}}</span><br/>
                                            <span style="color:#888">{{scope.row.address}}</span><br/>
                                        </template>
                                    </ComboBox>
                                    <div class="error">{{textError.transporter_id}}</div>
                                </td>
                                <td class="bg_subtitle">Max Weight <font color="red">*</font></td>
                                <td class="bg_grid2">
                                    <NumberBox  inputId="max_weight" name="max_weight" class="f-field-text text-50 text-right" v-model="model.max_weight" :spinners="false"></NumberBox> KG
                                    <div class="error">{{textError.max_weight}}</div>
                                </td>
                            </tr>
                            <tr>
                                <td class="bg_subtitle">Status <font color="red">*</font></td>
                                <td class="bg_grid2">
                                    <ComboBox  :data="statusTypes" inputId="status" name="status" class="f-field-text" v-model="model.status"></ComboBox>
                                    <div class="error">{{textError.status}}</div>
                                </td>
                                <td class="bg_subtitle">Additional Information</td>
                                <td class="bg_grid2">
                                    <TextBox inputId="additional_information" name="additional_information" class="f-field-text" v-model="model.additional_information"></TextBox>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Form>
            </div>
            <div class="dialog-button">
                <LinkButton :iconCls="iconUtil.iconSave" v-if="action.add || action.edit" @click="onSave()" :disabled="disableBtnSave" style="width:89px">{{ btnText.save }}</LinkButton>
                <LinkButton :iconCls="iconUtil.iconCancel" @click="$refs.dlg.close()" style="width:89px">{{ btnText.cancel }}</LinkButton>
            </div>
        </Dialog>

        <NotificationComponent ref="notification"></NotificationComponent>
    </div>
    
</template>

<script>
import NotificationComponent from '@/components/NotificationComponent.vue';
import Vehicle from '@/models/Vehicle';
import MasterService from '@/services/MasterService';
import ModelUtil from '@/utils/ModelUtil';
import InformationUtil from '@/utils/InformationUtil';
import OptionService from '@/services/OptionService';
import AccessUtil from '@/utils/AccessUtil';
import IconUtil from '@/utils/IconUtil';
import GlobalUtil from '@/utils/GlobalUtil';

export default {
    name: 'VehicleView',
    components : {
        NotificationComponent
    },
    data() {
        return {
            titleView : InformationUtil.vehicle + "s",
            titleMessage : InformationUtil.vehicle,
            iconUtil : IconUtil,

            titleForm :{
                add : InformationUtil.addText,
                edit : InformationUtil.editText
            },

            iconForm :{
                add : IconUtil.iconAdd,
                edit : IconUtil.iconEdit
            },

            action : {
                view : false,
                add : false,
                edit : false,
                delete : false
            },

            btnText :{
                refresh : InformationUtil.refreshText,
                add : InformationUtil.addText,
                edit : InformationUtil.editText,
                delete : InformationUtil.removeText,
                save : InformationUtil.saveText,
                cancel : InformationUtil.cancelText,
                search : InformationUtil.searchText
            },

            search : {
                text : InformationUtil.searching,
                placeholder : InformationUtil.searchHolder
            },

            dataGrid : {
                loading: false,
                rows : [],
                total: 0,
                start: 0,
                pageNumber: 1,
                pageSize: 10,
                selectionMode : 'single',
                pageLayout: ['list','sep','first','prev','next','last','sep','refresh','info'],

                // filter
                search : "",
                sortField : "",
                sortOrder : ""
            },

            // form
            title: '',
            icon: '',
            model: Vehicle,
            disabled : false,
            disableBtnSave : false,

            // validation
            rules: {
                vehicle_id: 'required',
                transporter_id: 'required',
                driver: 'required',
                co_driver: 'required',
                type: 'required',
                status: 'required',
                max_volume: 'required',
                max_weight: 'required',
            },
            errors: {},
            textError :{
                vehicle_id: "",
                transporter_id: "",
                driver: "",
                co_driver: "",
                type: "",
                status: "",
                max_volume: "",
                max_weight: "",
            },

            // combobox
            statusTypes : [
                {text: 'ON CALL', value: '1'},
                {text: 'DEDICATED', value: '2'}
            ],
            subcons : [],
            transporters : [],
            drivers : [],
            coDriver : [],
            vehicleTypes : []
        }
    },
    methods:{
        getError(name) {
            return this.errors[name] && this.errors[name].length
            ? this.errors[name][0]
            : null;
        },
        onAdd(){
            ModelUtil.resetModel(this.textError);
            // method for add data
            this.disabled = false;
            this.title = this.titleForm.add;
            this.icon = this.iconForm.add;

            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);
            this.model = ModelUtil.resetModel(this.model);

            // set default
            this.model.status = 1;
            this.model.max_volume = parseInt(this.model.max_volume);
            this.model.max_weight = parseInt(this.model.max_weight);
            this.$refs.dlg.open();
        },
        onEdit(){
            ModelUtil.resetModel(this.textError);
            // method for get data to edit
            if(this.$refs.dataGrid.selectedRows.length > 0){
                this.disabled = true;
                this.title = this.titleForm.edit;
                this.icon = this.iconForm.edit;

                // additional for combo box
                this.transporters = [{value: this.$refs.dataGrid.selectedRows[0].transporter_id, text: this.$refs.dataGrid.selectedRows[0].transporter_desc}]
                this.drivers = [{value: this.$refs.dataGrid.selectedRows[0].driver, text: this.$refs.dataGrid.selectedRows[0].driver_desc}]
                this.coDriver = [{value: this.$refs.dataGrid.selectedRows[0].co_driver, text: this.$refs.dataGrid.selectedRows[0].co_driver_desc}]
                this.vehicleTypes = [{value: this.$refs.dataGrid.selectedRows[0].type, text: this.$refs.dataGrid.selectedRows[0].type_desc, 
                    volume_cap: this.$refs.dataGrid.selectedRows[0].max_volume, weight_cap: this.$refs.dataGrid.selectedRows[0].max_weight}]
                this.subcons = [{value: this.$refs.dataGrid.selectedRows[0].client_id, text: this.$refs.dataGrid.selectedRows[0].client_desc}]

                this.model = ModelUtil.resetModel(this.model);
                this.model = ModelUtil.mapModel(this.model, this.$refs.dataGrid.selectedRows[0])
                this.model.max_volume = parseInt(this.model.max_volume);
                this.model.max_weight = parseInt(this.model.max_weight);
                this.$refs.dlg.open();
            }else{
                this.$refs.notification.centerTop(InformationUtil.selectRow);
            }
        },
        onDelete(){
            if(this.$refs.dataGrid.selectedRows.length > 0){

                this.$messager.confirm({
                    title: 'Confirm',
                    msg: InformationUtil.confirmDelete,
                    result: (r) => {
                        if (r){
                            this.dataGrid.loading = true;
                            MasterService.deleteData("/vehicle", this.$refs.dataGrid.selectedRows[0].id)
                                .then(() => {
                                    this.dataGrid.loading = false;
                                    this.$refs.notification.success(this.titleMessage + " " + InformationUtil.delete);
                                    this.loadData();
                                    this.$refs.dlg.close();
                                }).catch((err) => {
                                    this.$refs.notification.error(err.data.message);
                                });
                            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]) 
                        }
                    }
                })
                
            }else{
                this.$refs.notification.centerTop(InformationUtil.selectRow);
            }
            
        },
        onSave(){
            ModelUtil.resetModel(this.textError);
            this.$refs.form.validate((valid) => {
                if(!valid){
                    this.disableBtnSave = true; 
                    // set option for combobox
                    this.model.client_id = this.model.client_id == '' ? null : this.model.client_id;

                    if(this.model.id == null){
                        // action add data
                        MasterService.addData("/vehicle", this.model)
                            .then((res) => {
                                this.disableBtnSave = false;
                                if(res.code == 200){
                                    this.$refs.notification.success(this.titleMessage + " " + InformationUtil.add);
                                    this.loadData();
                                    this.$refs.dlg.close();
                                } else {
                                    this.$messager.alert({
                                        title: "Error",
                                        icon: "error",
                                        msg: res.message
                                    });
                                }                               
                            }).catch((error) => {
                                this.disableBtnSave = false;
                                this.$refs.notification.error(error);
                            });

                    }else{
                        // action update data
                        MasterService.updateData("/vehicle", this.model.id, this.model)
                            .then((res) => {
                                this.disableBtnSave = false;
                                if(res.code == 200){
                                    this.$refs.notification.success(this.titleMessage + " " + InformationUtil.update);
                                    this.loadData();
                                    this.$refs.dlg.close();
                                } else {
                                    this.$messager.alert({
                                        title: "Error",
                                        icon: "error",
                                        msg: res.message
                                    });
                                }
                            }).catch((error) => {
                                this.disableBtnSave = false;
                                this.$refs.notification.error(error);
                            });
                    }
                    this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0])
                } else {
                    for(let key in valid){
                        this.textError[key] = this.getError(key);
                    }
                }
            });
            
        },
        
        // handle data grid
        onRowDblClick(){
            if(this.action.edit){
                this.onEdit();
            }
        },
        async onRefresh(){  
            this.dataGrid.pageNumber = 1;          
            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);
            await this.loadData();
            this.$refs.notification.success(InformationUtil.refresh);
        },
        onSearch(){
            this.loadData();
        },
        onSortChange(event){
            if(event.length > 0){
                this.dataGrid.pageNumber = 1;
                this.dataGrid.sortField = event[0].field;
                this.dataGrid.sortOrder = event[0].order;
                this.loadData();
            }
        },
        onPageChange(event) {
            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);
            this.dataGrid.pageNumber = event.pageNumber;
            this.dataGrid.pageSize = event.pageSize;
            if(event.refresh) this.dataGrid.pageNumber = 1;
            this.loadData();
            if(event.refresh){
                this.$refs.notification.success(InformationUtil.refresh);
            }
        },

        async loadData(){
            this.dataGrid.loading = true;
            return MasterService.getData('/vehicle', this.dataGrid)
                .then((response) => {
                    if(response.data !== "" && response.data !== null){
                        let data = response.data; 
                        this.dataGrid.total = data.total;
                        this.dataGrid.rows = data.rows;
                    }
                    
                    this.dataGrid.loading = false;
                });
        },

        // combo box data
        async optionSubcon(event){
            let search = event == null ? "" : event.filterValue;
            return await OptionService.getOption("/transporter", search)
                .then((response) => {
                    this.subcons = response.data;
                }).catch((error) => {
                    this.$refs.notification.error(error);
                });
        },
        async optionTransporter(event){
            let search = event == null ? "" : event.filterValue;
            return await OptionService.getOption("/transporter", search)
                .then((response) => {
                    this.transporters = response.data;
                }).catch((error) => {
                    this.$refs.notification.error(error);
                });
        },
        async optionDriver(event){
            let search = event == null ? "" : event.filterValue;
            return await OptionService.getOption("/driver", search)
                .then((response) => {
                    this.drivers = response.data;
                }).catch((error) => {
                    this.$refs.notification.error(error);
                });
        },
        async optionCoDriver(event){
            let search = event == null ? "" : event.filterValue;
            return await OptionService.getOption("/driver", search)
                .then((response) => {
                    this.coDriver = response.data;
                }).catch((error) => {
                    this.$refs.notification.error(error);
                });
        },
        async optionVehicleType(event){
            let search = event == null ? "" : event.filterValue;
            return await OptionService.getOption("/vehicleType", search)
                .then((response) => {
                    this.vehicleTypes = response.data;
                }).catch((error) => {
                    this.$refs.notification.error(error);
                });
        },

        onVehicleTypeSelect(event){
            this.model.max_volume = parseInt(event.volume_cap);
            this.model.max_weight = parseInt(event.weight_cap);
        },
    },
    async mounted(){
        this.action = AccessUtil.getAccessAction('vehicle');
        await this.loadData();
        this.$refs.notification.success(InformationUtil.loadGrid + " " + this.dataGrid.total + " " + InformationUtil.successLoadGrid);
    },
    created(){
        GlobalUtil.removeWindow();
    }
};
</script>