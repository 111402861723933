<template>
    <div>
        <Dialog ref="dlg" bodyCls="f-column" :iconCls="icon" :title="title" :draggable="true" :modal="true" closed :dialogStyle="{width:'768px', height:'390px'}">
            <div class="f-full" style="overflow:auto">
                <div class="datagrid-toolbar">
                    <LinkButton :plain="true" :iconCls="iconUtil.iconRefresh" @click="onRefresh()">{{ btnText.refresh }}</LinkButton>
                    <span class="button-sep"></span>&nbsp;&nbsp;{{ search.text }} : 
                    <SearchBox style="width:300px"
                        :placeholder="search.placeholder" 
                        v-model="dataGrid.search"
                        @search="onSearch()">
                        <Addon>
                            <span v-if="dataGrid.search" class="textbox-icon icon-clear" title="Clear value" @click="dataGrid.search=null;onSearch();"></span>
                        </Addon>
                    </SearchBox>
                </div>
                <DataGrid
                        ref="dataGrid"
                        :columnResizing="true"
                        :border="false"
                        :lazy="true"
                        :loading="dataGrid.loading"                                    
                        :data="dataGrid.rows"
                        :pagination="true"
                        :selectionMode="dataGrid.selectionMode"
                        :total="dataGrid.total"
                        :pageSize="dataGrid.pageSize"
                        :pageNumber="dataGrid.pageNumber"
                        :pageLayout="dataGrid.pageLayout" 
                        @pageChange="onPageChange($event)"
                        @sortChange="onSortChange($event)"
                        @rowDblClick="onRowDblClick()"
                        >
                    
                        <GridColumn align="center" cellCss="datagrid-td-rownumber" width="30">
                        <template slot="body" slot-scope="scope">
                            {{scope.rowIndex + 1}}
                        </template>
                    </GridColumn>
                    <GridColumn field="vehicle_id" title="Vehicle Id" :sortable="true"></GridColumn>
                    <GridColumn field="driver_desc" title="Driver" :sortable="true"></GridColumn>
                    <GridColumn field="co_driver_desc" title="Co Driver" :sortable="true"></GridColumn>
                    <GridColumn field="transporter_desc" title="Transporter Id" :sortable="true"></GridColumn>
                    <GridColumn field="status_desc" title="Status" :sortable="true"></GridColumn>
                    <GridColumn field="type_desc" title="Type" :sortable="true"></GridColumn>
                    <GridColumn field="max_volume" title="Max Volume" :sortable="true" align="right"></GridColumn>
                    <GridColumn field="max_weight" title="Max Weight" :sortable="true" align="right"></GridColumn>
                </DataGrid>
            </div>
            <div class="dialog-button">
                <LinkButton :iconCls="iconUtil.iconSave" v-if="action.add || action.edit" @click="onSaveData()" :disabled="$props.disableBtnSave" style="width:89px">{{ btnText.save }}</LinkButton>
                <LinkButton :iconCls="iconUtil.iconCancel" @click="$refs.dlg.close()" style="width:89px">{{ btnText.cancel }}</LinkButton>
            </div>
        </Dialog>
    </div>
</template>
<script>
    import TransportService from '@/services/TransportService';
    import InformationUtil from '@/utils/InformationUtil';
    import AccessUtil from '@/utils/AccessUtil';
    import IconUtil from '@/utils/IconUtil';
    import GlobalUtil from '@/utils/GlobalUtil';


    export default {
        name: "GridVehicleComponent",
        props : {
            actionData : {
                type : String,
                default : ''
            },
            disableBtnSave : {
                type : Boolean,
                default : false
            }
        },
        data() {
            return {
                iconUtil : IconUtil,
                icon : IconUtil.iconAdd,
                title: InformationUtil.vehicle,
                btnText :{
                    refresh : InformationUtil.refreshText,
                    add : InformationUtil.addText,
                    edit : InformationUtil.editText,
                    delete : InformationUtil.removeText,
                    save : InformationUtil.saveText,
                    cancel : InformationUtil.cancelText,
                    search : InformationUtil.searchText
                },

                search : {
                    text : InformationUtil.searching,
                    placeholder : InformationUtil.searchHolder
                },

                action : {
                    view : false,
                    add : false,
                    edit : false,
                    delete : false
                },
                dataGrid : {
                    loading: false,
                    rows : [],
                    total: 0,
                    start: 0,
                    pageNumber: 1,
                    pageSize: 10,
                    selectionMode : 'single',
                    pageLayout: ['list','sep','first','prev','next','last','sep','refresh','info'],

                    // filter
                    search : "",
                    sortField : "",
                    sortOrder : ""
                },
            }
        },
        methods: {
            onPageShow(){
                this.action = AccessUtil.getAccessAction(this.$props.actionData);
                this.loadData()

                this.$refs.dlg.open();
            },
            onPageClose(){
                this.$refs.dlg.close();
            },
            onSaveData(){
                if(this.$refs.dataGrid.selectedRows.length > 0){
                    let row = this.$refs.dataGrid.selectedRows[0];
                    this.$emit('onSaveData', row);
                }else{
                    this.$messager.alert({
                                        title: "Error",
                                        icon: "error",
                                        msg: InformationUtil.selectRow
                                    });
                }
            },
            async onRefresh(){
                this.dataGrid.pageNumber = 1;       
                this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);
                await this.loadData();
                this.$refs.notification.success(InformationUtil.refresh);
            },
            onRowDblClick(){
                if(this.action.add || this.action.edit){
                    this.onSaveData();
                }
            },
            onSearch(){
                this.loadData();
            },
            onSortChange(event){
                if(event.length > 0){
                    this.dataGrid.pageNumber = 1;
                    this.dataGrid.sortField = event[0].field;
                    this.dataGrid.sortOrder = event[0].order;
                    this.loadData();
                }
            },
            onPageChange(event) {
                this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);
                this.dataGrid.pageNumber = event.pageNumber;
                this.dataGrid.pageSize = event.pageSize;
                if(event.refresh) this.dataGrid.pageNumber = 1;
                this.loadData();
            },
            async loadData(){
                this.dataGrid.loading = true;
                return TransportService.getData('/routePlanning/vehicle', this.dataGrid)
                    .then((response) => {
                        if(response.data !== "" && response.data !== null){
                            let data = response.data; 
                            this.dataGrid.total = data.total;
                            this.dataGrid.rows = data.rows;
                        }
                        this.dataGrid.loading = false;
                    });
            },
        },
        created(){
            GlobalUtil.removeWindow();
        }
    }
</script>