export default {
    id: null,
    area_id: "",
    description: "",
    area_type: "",
    area_group: "",
    additional_information: "",

    // handling error
    attributeDescription : {
        area_id: "Area ID",
        description: "Description",
        area_type: "Area Type",
        additional_information: "Additional Information"
    },
}