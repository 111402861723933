<template>
    <div>
        <Panel  :title="titleView"  style="margin:0px 1px; ">
            <Form ref="form" :model="model" style="width : 50%; ">
                <fieldset class="c-fieldset">
                    <legend class="c-legend"><b>Filter</b></legend>
                    <table width="100%" cellspacing="1" cellpadding="5" border="0">
                        <tbody>
                            <tr>
                                <td width="15%">Schedule Date</td>
                                <td width="40%">
                                    <DateBox inputId="start" name="start" class="f-field-text" v-model="model.start" format="dd/MM/yyyy"></DateBox>
                                </td>
                                <td width="5%" align="center">to</td>
                                <td width="40%">
                                    <DateBox inputId="end" name="end" class="f-field-text" v-model="model.end" format="dd/MM/yyyy"></DateBox>
                                </td>
                                <td width="5%">
                                    <LinkButton :iconCls="icon.iconSearch" @click="loadData()" style="width:80px">{{ btnText.search }}</LinkButton>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </fieldset>
            </Form>
        </Panel>
        <Panel style="margin:2px 1px; ">
            <div class="datagrid-toolbar">
                <LinkButton v-if="viewGrid"  :plain="true" :iconCls="icon.iconExport" @click="onExport()">{{ btnText.export }}</LinkButton>
            </div>
            <DataGrid
                    ref="dataGrid"
                    :columnResizing="true"
                    :border="false"
                    :loading="dataGrid.loading"                                    
                    :data="dataGrid.rows"
                    :pageSize="dataGrid.pageSize"
                    :pagination="true"
                    :total="dataGrid.total"
                    >                
                <GridColumn align="center" cellCss="datagrid-td-rownumber" width="30">
                    <template slot="body" slot-scope="scope">
                        {{scope.rowIndex + 1}}
                    </template>
                </GridColumn>
                <GridColumn field="schedule_date" title="Schedule Date"></GridColumn>
                <GridColumn field="manifest_id" title="Shipment Number"></GridColumn>
                <GridColumn field="do_number" title="DO Number"></GridColumn>
                <GridColumn field="trip" title="Trip"></GridColumn>
                <GridColumn field="ring_name" title="RING"></GridColumn>
                <GridColumn field="point_id" title="Point ID"></GridColumn>
                <GridColumn field="point_name" title="Name"></GridColumn>
                <GridColumn field="point_area" title="Area"></GridColumn>
                <GridColumn field="vehicle_id" title="Vehicle ID"></GridColumn>
                <GridColumn field="driver" title="Driver Name"></GridColumn>
                <GridColumn field="codriver" title="Co Driver Name"></GridColumn>
                <GridColumn field="vehicle_type" title="Vehicle Type"></GridColumn>
                <GridColumn field="tm_state" title="Monitoring State"></GridColumn>
                <GridColumn field="arrival_ata" title="Arrival"></GridColumn>
                <GridColumn field="loading_start" title="Start Loading/Unloading"></GridColumn>
                <GridColumn field="loading_finish" title="Finish Loading/Unloading"></GridColumn>
                <GridColumn field="departure_ata" title="Departure"></GridColumn>
                <GridColumn field="km_start" title="KM Start"></GridColumn>
                <GridColumn field="km_finish" title="KM Finish"></GridColumn>
                <GridColumn field="" title="Leadtime"></GridColumn>
                <GridColumn field="km_mileage" title="KM Mileage"></GridColumn>
                <GridColumn field="order_qty" title="Quantity"></GridColumn>
                <GridColumn field="" title="CBM"></GridColumn>
                <GridColumn field="load_kg" title="Load KG"></GridColumn>
                <GridColumn field="load_m3" title="Tonnage"></GridColumn>
                <GridColumn field="utilization" title="Utilization"></GridColumn>
                <GridColumn field="order_case" title="Order Case"></GridColumn>
                <GridColumn field="approved_by" title="Approved By"></GridColumn>
                <GridColumn field="notes" title="Notes"></GridColumn>

            </DataGrid>
        </Panel>
    </div>
</template>
<script>
    import GlobalUtil from '@/utils/GlobalUtil';
    import InformationUtil from '@/utils/InformationUtil';
    import IconUtil from '@/utils/IconUtil';
    import ReportService from '@/services/ReportService';

    export default {
        name: 'DailyMonitoringCostView',
        data () {
            return {
                titleView : "Daily Monitoring Report",
                icon : IconUtil,
                viewGrid : false,
                model : {
                    start : null,
                    end : null,
                },
                btnText :{
                    print : InformationUtil.printText,
                    search : InformationUtil.searchText,
                    export : InformationUtil.exportText,
                },
                dataGrid : {
                    loading: false,
                    rows : [],
                    total: 0,
                    pageSize: 10,
                    start : 0,
                    pageLayout: ['list','sep','first','prev','next','last','sep','refresh','info'],
                },
            }
        },
        methods: {
            async loadData(){
                this.dataGrid.loading = true;
                this.viewGrid = false;

                this.model.start = new Date(this.$moment(this.model.start).format('YYYY-MM-DD'));
                this.model.end = new Date(this.$moment(this.model.end).format('YYYY-MM-DD'));

                let param = {
                    start_date : this.model.start,
                    end_date : this.model.end,
                }

                return ReportService.getData('/dailyMonitoring', this.dataGrid, param)
                    .then((response) => {
                        if(response.data !== "" && response.data !== null){
                            let data = response.data; 
                            this.dataGrid.total = data.total;
                            this.dataGrid.rows = data.rows;
                        }
                        
                        this.dataGrid.loading = false;
                        this.viewGrid = this.dataGrid.rows.length > 0 ? true : false;
                    });
            },
            onExport(){

                this.model.start = new Date(this.$moment(this.model.start).format('YYYY-MM-DD'));
                this.model.end = new Date(this.$moment(this.model.end).format('YYYY-MM-DD'));

                let param = {
                    start_date : this.model.start,
                    end_date : this.model.end,
                }
                ReportService.downloadFile("/dailyMonitoring/export", param)
                            .then((res) => {
                                if(res.type === 'application/vnd.ms-excel' && res != null){
                                    let blob = new Blob([res], { type: "application/vnd.ms-excel" })
                                    let objectUrl = window.URL.createObjectURL(blob)
                                    const elem = window.document.createElement('a')
                                    elem.href = objectUrl;
                                    elem.download = "Report Daily Monitoring_"+ this.$moment(this.model.start).format('YYYY-MM-DD') + "_"+ this.$moment(this.model.end).format('YYYY-MM-DD') +".xls"
                                    document.body.appendChild(elem)
                                    elem.click()
                                    document.body.removeChild(elem)
                                    window.URL.revokeObjectURL(blob);
                                    window.URL.revokeObjectURL(objectUrl);
                                } else {
                                    this.$messager.alert({
                                        title: "Error",
                                        icon: "error",
                                        msg: res.message
                                    });
                                }
                            }).catch((error) => {
                                this.$refs.notification.error(error);
                            });
            }
        },
        created(){
            this.model.start = new Date();
            this.model.end = new Date();
            GlobalUtil.removeWindow();
        }
    }
    </script>