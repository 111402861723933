export default {
    id: null,
    vehicle_id: "",
    driver: "",
    co_driver: "",
    transporter_id: "",
    status: "",
    type: "",
    max_volume: 0,
    max_weight: 0,
    client_id: "",
    subcon: "",
    additional_information: ""
}