class ModelUtil{
    /**
     * function for reset model
     * @param {*} model 
     * @returns 
     */
    resetModel(model){
        for (var key in model) {
            if (model[key] !== undefined && model[key] !== null && model[key] !== '' && model[key] !== 'null' && key !== 'attributeDescription') {
                if (typeof model[key] === 'object') {
                    if (model[key] instanceof Array) {
                        model[key] = [];
                    } else {
                        model[key] = {};
                    }
                } else {
                    model[key] = null;
                }
            }            
        }
        return model;
    }

    /**
     * function for mapping model from data
     * @param {*} model 
     * @param {*} data 
     * @returns 
     */
    mapModel(model, data){
        for (var key in model) {
            if (data[key] !== undefined && data[key] !== null && data[key] !== '' && data[key] !== 'null' && key !== 'attributeDescription') {
                model[key] = data[key];                
            }
        }
        return model;
    }


    /**
     * function for mapping model from data
     * @param {*} model 
     * @param {*} data 
     * @returns 
     */
     mapModelByType(model, data){
        for (var key in model) {
            if (data[key] !== undefined && data[key] !== null && data[key] !== '' && data[key] !== 'null' && key !== 'attributeDescription') {
                if (typeof model[key] === 'number' && model[key] !== null) {
                    model[key] = parseInt(data[key]);
                } else if (typeof model[key] === 'string' && model[key] !== null) {
                    model[key] = String(data[key]);
                } else {
                    model[key] = data[key];
                }
                
            }
        }
        return model;
    }


    resetModelByType(model){
        for (var key in model) {
            if (model[key] !== undefined && model[key] !== null && model[key] !== '' && model[key] !== 'null' && key !== 'attributeDescription') {
                if (typeof model[key] === 'object') {
                    if (model[key] instanceof Array) {
                        model[key] = [];
                    } else {
                        model[key] = {};
                    }
                } else if (typeof model[key] === 'number' && model[key] !== null) {
                    model[key] = 0;
                } else if (typeof model[key] === 'string' && model[key] !== null) {
                    model[key] = "";
                } else {
                    model[key] = null;
                }
            }
        }
        return model;
    }
}

export default new ModelUtil();